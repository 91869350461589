// General
import "./navbar-desktop-view.scss";
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
// Services
import {
  useLazyGetProfileQuery,
  useLazyGetProfileCheckQuery,
  useLazyGetUserAioQuery,
  useLazyGetBadgeCountQuery,
  useLazyGetStreamerCenterQuery,
  useLazyGetVirtualCurrencyCoinBalanceQuery,
} from "../../../../../services/data.service";
// Static Data
import routeConst from "../../../../../const/routeConst";
import userConst from "../../../../../const/userConst";
import utilityConst from "../../../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateDrawerOpen } from "../../../../../redux/store/appStore";
import { updateInterestTab } from "../../../../../redux/store/interestStore";
import { updateUserProfileDialog } from "../../../../../redux/store/dialogStore";
import {
  // User View
  updateId,
  updateIdInt,
  updateUsername,
  updateAge,
  updateLocation,
  updateMembershipType,
  updateVerifiedProfile,
  updateVerifiedCaller,
  updateLevellingBadge,
  updateLivestreamingAchievementBadge,
  updateProfilePhoto,
  updatePublicPhotos,
  updatePrivatePhotos,
  updateTorTags,
  updateProfileAchievementBadge,
  updateAboutMe,
  updateLookingFor,
  updateOccupation,
  updateLifestyle,
  updateEducation,
  updateRelationship,
  updateSmoking,
  updateDrinking,
  updateHeight,
  updateBodyType,
  updateEthnicity,
  updateEyeColor,
  updateHairColor,
  updateChildren,
  // User Utility
  updateShouldCallApi,
  updatePrivatePhotoRequested,
  updatePrivatePhotoApproval,
  updateRequestedMyPrivatePhoto,
  updateApprovedMyPrivatePhoto,
  updateIsBlocked,
  updateIsSilentInteraction,
  updateIsFavorited,
  updateIsCallEnabled,
  updateIsSugarbaby,
  updateRole,
} from "../../../../../redux/store/userCardStore";
// Material UI
import { Toolbar, IconButton, styled } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../../utility/custom-hooks/useCustomNavigate-hook";
import useCompactNumbers from "../../../../utility/custom-hooks/useCompactNumbers-hook";
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import CustomAvatar from "../../custom-avatar/custom-avatar";

const NavbarDesktopView = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    getProfileCheck,
    {
      data: getProfileCheckData,
      error: getProfileCheckErrorData,
      isFetching: getProfileCheckFetching,
      isLoading: getProfileCheckLoading,
      isSuccess: getProfileCheckSuccess,
      isError: getProfileCheckError,
    },
  ] = useLazyGetProfileCheckQuery();
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();
  const [
    getBadgeCount,
    {
      data: getBadgeCountData,
      error: getBadgeCountErrorData,
      isFetching: getBadgeCountFetching,
      isLoading: getBadgeCountLoading,
      isSuccess: getBadgeCountSuccess,
      isError: getBadgeCountError,
    },
  ] = useLazyGetBadgeCountQuery();
  const [
    getStreamerCenter,
    {
      data: getStreamerCenterData,
      error: getStreamerCenterErrorData,
      isFetching: getStreamerCenterFetching,
      isLoading: getStreamerCenterLoading,
      isSuccess: getStreamerCenterSuccess,
      isError: getStreamerCenterError,
    },
  ] = useLazyGetStreamerCenterQuery();
  const [
    getCoins,
    {
      data: getCoinsData,
      error: getCoinsErrorData,
      isFetching: getCoinsFetching,
      isLoading: getCoinsLoading,
      isSuccess: getCoinsSuccess,
      isError: getCoinsError,
    },
  ] = useLazyGetVirtualCurrencyCoinBalanceQuery();

  // General variables
  const [showNavbar, setShowNavbar] = useState(true);

  // Constant variables
  const drawerWidth = utilityConst.drawerWidth;

  // Redux variables
  const isLoggedIn = useSelector((state) => state.public.isLoggedIn);
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const releaseChannel = useSelector((state) => state.public.releaseChannel);
  const showVideoCall = useSelector((state) => state.app.showVideoCall);
  const drawerOpen = useSelector((state) => state.app.drawerOpen);
  const currentPage = useSelector((state) => state.navigation.currentPage);
  const dispatch = useDispatch();

  // MUI variables
  const [drawerState, setDrawerState] = useState({
    left: false,
  });
  const [anchorElSettings, setAnchorElSettings] = useState(null);
  const openSettingsMenu = Boolean(anchorElSettings);

  // Router variables
  const location = useLocation();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getCompactNumbers = useCompactNumbers();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!isLoggedIn) return;

    // getProfile(null, true);
    // getProfileCheck(null, true);
    // getUserAio(null, true);
    // getBadgeCount();
    // getStreamerCenter(null, false);
    // getCoins(null, false);
  }, [isLoggedIn]);

  // Lifecycle | Check for update | Hide navbar based on route
  useEffect(() => {
    if (
      location.pathname === routeConst.livestream.simulateLivestream.path ||
      location.pathname === routeConst.livestream.kingOfTheMonth.path ||
      location.pathname === routeConst.livestream.giftAnimation.path
    ) {
      setShowNavbar(true);
    } else {
      setShowNavbar(false);
    }
  }, [location]);

  // Event Handlers | Button
  const handleDrawerOpen = () => {
    dispatch(updateDrawerOpen(true));
  };

  // Utility Style
  const CustomAppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  if (showNavbar) {
    return (
      <div id="navbar-navbar-desktop-view-subcomponent">
        <div className="space-around">
          <Toolbar className="app-bar-toolbar">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(drawerOpen && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>

            <div className="app-bar-content-container">
              {/* <div className="app-bar-breadcrumbs">
                <span className="ancestor">Livestream</span> / <span className="current">King of the Month</span>
              </div> */}
              <div className="current-page-label">{currentPage}</div>
            </div>
          </Toolbar>
        </div>
      </div>
    );
  }
};

export default NavbarDesktopView;
