// General
import "./user-mini-profile-dialog.scss";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  removeUserById,
  updateUserMiniProfileData,
} from "../../../../redux/store/userStore";
import { removeApiTokenByIndex } from "../../../../redux/store/publicStore";
import { updateUserMiniProfileDialog } from "../../../../redux/store/dialogStore";
// Material UI
import { Dialog } from "@mui/material";
// Components
import CustomAvatar from "../../elements/custom-avatar/custom-avatar";

const UserMiniProfileDialog = () => {
  // Redux variables
  const userMiniProfileDialog = useSelector(
    (state) => state.dialog.userMiniProfileDialog
  );
  const userMiniProfileData = useSelector(
    (state) => state.user.userMiniProfileData
  );
  const dispatch = useDispatch();

  // Event Handlers | Button
  const onRemoveUser = () => {
    dispatch(removeUserById(userMiniProfileData?.id_int));
    dispatch(removeApiTokenByIndex(userMiniProfileData?.index));

    dispatch(updateUserMiniProfileData(null));

    onCloseDialog();
  };

  // Event Handlers | Dialog
  const onCloseDialog = () => {
    dispatch(updateUserMiniProfileDialog(false));
  };

  return (
    <Dialog
      className="custom-radius10-dialog"
      open={userMiniProfileDialog}
      onClose={onCloseDialog}
    >
      <div id="user-mini-profile-dialog">
        <div className="padding-container">
          <CustomAvatar
            className="user-profile-photo"
            src={userMiniProfileData?.profile_photo?.original_photo}
          />

          <div className="user-data-container">
            <div className="user-data-label-container">
              <div className="user-data-label">Username: </div>
              <div className="user-data-label">Role: </div>
              <div className="user-data-label">ID: </div>
              <div className="user-data-label">ID Int: </div>
            </div>

            <div className="user-data-output-container">
              <div className="user-data-output">
                {userMiniProfileData?.username}
              </div>
              <div className="user-data-output">
                {userMiniProfileData?.role}
              </div>
              <div className="user-data-output">{userMiniProfileData?.id}</div>
              <div className="user-data-output">
                {userMiniProfileData?.id_int}
              </div>
            </div>
          </div>

          <div className="remove-user-button" onClick={onRemoveUser}>
            Remove User
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default UserMiniProfileDialog;
