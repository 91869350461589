// General
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  profileBorderEnabled: true,
  profileBorderImage: null,
};

export const profileBorderSlice = createSlice({
  name: "profileBorder",
  initialState,
  reducers: {
    updateProfileBorderEnabled: (state, action) => {
      state.profileBorderEnabled = action.payload;
    },
    updateProfileBorderImage: (state, action) => {
      state.profileBorderImage = action.payload;
    },

    // Utility Functions
    resetProfileBorderStore: () => {
      return { ...initialState };
    },
  },
});

export const {
  updateProfileBorderEnabled,
  updateProfileBorderImage,

  // Utility Functions
  resetProfileBorderStore,
} = profileBorderSlice.actions;

export default profileBorderSlice.reducer;
