// General
import { useEffect } from "react";
// Services
import {
  useLazyGetProfilePwaQuery,
  useLazyGetProfileQuery,
  useLazyGetUserAioQuery,
  useLazyGetAioQuery,
} from "../../../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateApiConfigHeaders,
  updateVpnBlockInfo,
} from "../../../../redux/store/publicStore";
import {
  addUser,
  updateMembershipType,
} from "../../../../redux/store/userStore";
import {
  updateLivestreamingContestId,
  updateLivestreamingContestPkId,
  updatePrivateCallContestId,
} from "../../../../redux/store/leaderboardStore";
import { updateOwnPrivateCallEnable } from "../../../../redux/store/inboxStore";
import {
  updateNotificationCount,
  updateNotificationCountLoaded,
  updateShowNotificationBadge,
} from "../../../../redux/store/notificationStore";
import { updateVpnBlockDialog } from "../../../../redux/store/dialogStore";
import { updateErrorToast } from "../../../../redux/store/toastStore";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../custom-hooks/useCustomNavigate-hook";

const ApiMounted = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    getProfilePwa,
    {
      data: getProfilePwaData,
      error: getProfilePwaErrorData,
      isFetching: getProfilePwaFetching,
      isLoading: getProfilePwaLoading,
      isSuccess: getProfilePwaSuccess,
      isError: getProfilePwaError,
    },
  ] = useLazyGetProfilePwaQuery();
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();
  const [
    getAio,
    {
      data: getAioData,
      error: getAioErrorData,
      isFetching: getAioFetching,
      isLoading: getAioLoading,
      isSuccess: getAioSuccess,
      isError: getAioError,
    },
  ] = useLazyGetAioQuery();

  // Redux variables
  const isLoggedIn = useSelector((state) => state.public.isLoggedIn);
  const apiTokens = useSelector((state) => state.public.apiTokens);
  const usersList = useSelector((state) => state.user.usersList);
  const dispatch = useDispatch();

  // i18next variables
  const { i18n } = useTranslation();

  // Utility variables
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Check for update | isLoggedIn
  useEffect(() => {
    (async () => {
      if (!isLoggedIn) return;
      for (let i = 0; i < apiTokens?.length; i++) {
        dispatch(updateApiConfigHeaders(apiTokens[i]));
        await delay(2000);
        getProfile("api-mounted", false);
      }
    })();
  }, [isLoggedIn]);

  // Lifecycle | Check for update | AIO API Response
  useEffect(() => {
    if (getAioFetching || getAioLoading) {
    } else if (getAioSuccess) {
      if (getAioData?.status === 0) {
        // Close VPN Block Dialog
        dispatch(updateVpnBlockDialog(false));
      } else if (
        getAioData?.success === "false" &&
        getAioData?.code === "403"
      ) {
        // Open VPN Block Dialog
        dispatch(updateVpnBlockInfo(getAioData?.message?.pwa));
        dispatch(updateVpnBlockDialog(true));
      }
    } else if (getAioError) {
      if (getAioErrorData?.status === 401) {
        // onNavigate(routeConst.logout.path);
      }
    }
  }, [getAioFetching, getAioLoading, getAioSuccess, getAioError]);

  // Lifecycle | Check for update | Profile PWA API Response
  useEffect(() => {
    if (getProfilePwaFetching || getProfilePwaLoading) {
    } else if (getProfilePwaSuccess) {
      if (getProfilePwaData?.status === 0) {
        dispatch(
          updateMembershipType(
            getProfilePwaData?.payload?.message?.profile?.membershiptype
          )
        );
      }
    } else if (getProfilePwaError) {
    }
  }, [
    getProfilePwaFetching,
    getProfilePwaLoading,
    getProfilePwaSuccess,
    getProfilePwaError,
  ]);

  // Lifecycle | Check for update | Profile API Response
  useEffect(() => {
    if (getProfileFetching || getProfileLoading) {
    } else if (getProfileSuccess) {
      if (getProfileData?.status === 0) {
        const user = getProfileData?.data;
        const userExists = usersList?.find(
          (item) => item?.id_int === user?.id_int
        );
        if (!userExists) {
          dispatch(addUser(user));
        }
      }
    } else if (getProfileError) {
      if (getProfileErrorData?.status === 401) {
        // onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getProfileFetching,
    getProfileLoading,
    getProfileSuccess,
    getProfileError,
  ]);

  // Lifecycle | Check for update | User AIO API Response
  useEffect(() => {
    if (getUserAioFetching || getUserAioLoading) {
    } else if (getUserAioSuccess) {
      if (getUserAioData?.status === 1) {
        if (getUserAioData?.data?.modules?.levelling_enabled) {
          // REMEMBER: Remove after TW is available for premium
          if (!i18n.language.toLowerCase().includes("zh-tw")) {
            // getLevellingTasks();
          }
        }

        dispatch(
          updateOwnPrivateCallEnable(
            getUserAioData?.data?.private_call_settings
              ?.system_private_calls_enabled &&
              getUserAioData?.data?.private_call_settings?.enable_calls
          )
        );
      } else {
      }
    } else if (getUserAioError) {
      if (getUserAioErrorData?.status === 401) {
        // onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getUserAioFetching,
    getUserAioLoading,
    getUserAioSuccess,
    getUserAioError,
  ]);

  return <div id="app-mounted-shadow-component"></div>;
};

export default ApiMounted;
