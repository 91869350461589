// General
import "./gifts-tab.scss";
import { useEffect } from "react";
// Services
import { useLazyGetProductGiftsQuery } from "../../../../../services/data.service";
// Static Data
import routeConst from "../../../../../const/routeConst";
// Custom Hooks
import useCustomNavigate from "../../../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import Spinner from "../../../elements/spinner/spinner";
import Gifts from "../../../elements/gifts/gifts";

const GiftsTab = (props) => {
  const { state, page } = props;

  // API variables
  const [
    getProductGifts,
    {
      data: getProductGiftsData,
      error: getProductGiftsErrorData,
      isFetching: getProductGiftsFetching,
      isLoading: getProductGiftsLoading,
      isSuccess: getProductGiftsSuccess,
      isError: getProductGiftsError,
    },
  ] = useLazyGetProductGiftsQuery();

  // Render variables
  let giftsListView;

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    // getProductGifts(null, true);
  }, []);

  // Render Functions | Loading
  if (getProductGiftsFetching || getProductGiftsLoading) {
    giftsListView = <Spinner />;
  } else if (getProductGiftsError) {
    if (getProductGiftsErrorData?.status === 401) {
      // onNavigate(routeConst.logout.path);
    }
  }

  // Render Functions | Success
  if (getProductGiftsData?.data?.length > 0) {
    giftsListView = getProductGiftsData?.data?.map((gift, index) => {
      if (gift?.categories.includes("gifts")) {
        return (
          <Gifts
            state={page}
            productHashId={gift?.product_hash_id}
            giftImage={gift?.graphics?.png}
            giftAmount={gift?.amount.toLocaleString()}
            isEventBased={gift?.is_event_based}
            key={index}
          />
        );
      }
    });
  }

  return (
    <div
      id="product-purchase-dialog-gifts-tab-subcomponent"
      className={state === "dialog" ? "screen-height" : "fix-height"}
    >
      {giftsListView}
    </div>
  );
};

export default GiftsTab;
