// General
import "./notification-list.scss";
// i18next
import { useTranslation } from "react-i18next";
// Moment
import moment from "moment";
// Components
import ViewedYourProfileElement from "./viewed-your-profile-element/viewed-your-profile-element";
import FavoritedYouElement from "./favorited-you-element/favorited-you-element";
import PrivatePhotoRequestElement from "./private-photo-request-element/private-photo-request-element";
import PrivatePhotoApproveElement from "./private-photo-approve-element/private-photo-approve-element";
import TextMessageElement from "./text-message-element/text-message-element";
import LiveElement from "./live-element/live-element";

const NotificationList = (props) => {
  const {
    notification,
    // Utility
    profileBorder,
  } = props;

  // i18next variables
  const { t } = useTranslation();

  // Utility Functions
  const onFormattedDate = (date) => {
    const currentDate = moment();
    const notificationDate = moment(date);

    const diffInHours = currentDate.diff(notificationDate, "hours");
    const diffInDays = currentDate.diff(notificationDate, "days");
    const diffInWeeks = currentDate.diff(notificationDate, "weeks");
    const diffInMonths = currentDate.diff(notificationDate, "months");

    if (diffInHours === 0) {
      return `${t("notification.n_h", { n: diffInHours + 1 })}`;
    } else if (diffInHours <= 24) {
      return `${t("notification.n_h", { n: diffInHours })}`;
    } else if (diffInDays === 0) {
      return `${t("notification.n_d", { n: diffInDays + 1 })}`;
    } else if (diffInDays <= 6) {
      return `${t("notification.n_d", { n: diffInDays })}`;
    } else if (diffInWeeks <= 3) {
      return `${t("notification.n_d", { n: diffInWeeks })}`;
    } else {
      return `${t("notification.n_d", { n: diffInMonths })}`;
    }
  };

  return (
    <div id="notification-notification-list-subcomponent">
      <ViewedYourProfileElement
        notificationId={notification?.notification_id}
        id={notification?.user?.id}
        idInt={notification?.user?.id_int}
        profilePhoto={notification?.user?.profile_photo?.original_photo}
        publicPhotos={notification?.user?.public_photos?.map((photo) => {
          return {
            photoUrl: photo?.original_photo,
          };
        })}
        privatePhotos={notification?.user?.private_photos?.map((photo) => {
          return {
            photoUrl: photo?.original_photo,
            thumbnailData: photo?.thumbnail_data,
          };
        })}
        username={notification?.user?.username}
        isVerifiedProfile={notification?.user?.is_verified_profile}
        membershipType={notification?.user?.membership_type}
        isLock={false}
        timestamp={onFormattedDate(notification?.timestamp)}
        isSeen={true}
        // Utility
        profileBorder={profileBorder}
      />

      <FavoritedYouElement
        notificationId={notification?.notification_id}
        id={notification?.user?.id}
        idInt={notification?.user?.id_int}
        profilePhoto={notification?.user?.profile_photo?.original_photo}
        publicPhotos={notification?.user?.public_photos?.map((photo) => {
          return {
            photoUrl: photo?.original_photo,
          };
        })}
        privatePhotos={notification?.user?.private_photos?.map((photo) => {
          return {
            photoUrl: photo?.original_photo,
            thumbnailData: photo?.thumbnail_data,
          };
        })}
        username={notification?.user?.username}
        isVerifiedProfile={notification?.user?.is_verified_profile}
        membershipType={notification?.user?.membership_type}
        isLock={false}
        timestamp={onFormattedDate(notification?.timestamp)}
        isSeen={true}
        // Utility
        profileBorder={profileBorder}
      />

      <PrivatePhotoRequestElement
        notificationId={notification?.notification_id}
        id={notification?.user?.id}
        idInt={notification?.user?.id_int}
        profilePhoto={notification?.user?.profile_photo?.original_photo}
        username={notification?.user?.username}
        isVerifiedProfile={notification?.user?.is_verified_profile}
        membershipType={notification?.user?.membership_type}
        isLock={false}
        timestamp={onFormattedDate(notification?.timestamp)}
        isSeen={true}
        // Utility
        profileBorder={profileBorder}
      />

      <PrivatePhotoApproveElement
        notificationId={notification?.notification_id}
        id={notification?.user?.id}
        idInt={notification?.user?.id_int}
        profilePhoto={notification?.user?.profile_photo?.original_photo}
        publicPhotos={notification?.user?.public_photos?.map((photo) => {
          return {
            photoUrl: photo?.original_photo,
          };
        })}
        privatePhotos={notification?.user?.private_photos?.map((photo) => {
          return {
            photoUrl: photo?.original_photo,
            thumbnailData: photo?.thumbnail_data,
          };
        })}
        username={notification?.user?.username}
        isVerifiedProfile={notification?.user?.is_verified_profile}
        membershipType={notification?.user?.membership_type}
        isLock={false}
        timestamp={onFormattedDate(notification?.timestamp)}
        isSeen={true}
        // Utility
        profileBorder={profileBorder}
      />

      <TextMessageElement
        notificationId={notification?.notification_id}
        id={notification?.user?.id}
        idInt={notification?.user?.id_int}
        conversationId={notification?.conversation_id}
        profilePhoto={notification?.user?.profile_photo?.original_photo}
        username={notification?.user?.username}
        isVerifiedProfile={notification?.user?.is_verified_profile}
        membershipType={notification?.user?.membership_type}
        isLock={false}
        timestamp={onFormattedDate(notification?.timestamp)}
        isSeen={false}
        // Utility
        profileBorder={profileBorder}
      />

      <LiveElement
        notificationId={notification?.notification_id}
        id={notification?.user?.id}
        idInt={notification?.user?.id_int}
        channelId={notification?.channel_id}
        channelStatus={notification?.channel_status}
        profilePhoto={notification?.user?.profile_photo?.original_photo}
        publicPhotos={notification?.user?.public_photos?.map((photo) => {
          return {
            photoUrl: photo?.original_photo,
          };
        })}
        privatePhotos={notification?.user?.private_photos?.map((photo) => {
          return {
            photoUrl: photo?.original_photo,
            thumbnailData: photo?.thumbnail_data,
          };
        })}
        username={notification?.user?.username}
        isVerifiedProfile={notification?.user?.is_verified_profile}
        membershipType={notification?.user?.membership_type}
        isLock={false}
        timestamp={onFormattedDate(notification?.timestamp)}
        isSeen={true}
        // Utility
        profileBorder={profileBorder}
      />
    </div>
  );
};

export default NotificationList;
