// General
import "./inbox-page-profile-border.scss";
// Redux
import { useSelector } from "react-redux";
// Moment
import moment from "moment";
// Components
import MessagesList from "./messages-list/messages-list";

const InboxPageProfileBorder = () => {
  // Redux variables
  const profileBorderImage = useSelector(
    (state) => state.profileBorder.profileBorderImage
  );

  return (
    <div className="inbox-page-profile-border">
      <div className="inbox-label">Inbox Page</div>
      <div className="mobile-desktop-view-label">Mobile/Desktop View</div>

      <div className="mobile-view">
        <MessagesList // User Data
          userId={""}
          userIdInt={""}
          username={"King Daddy"}
          age={21}
          location={""}
          membershipType={"premium"}
          verifiedProfile={null}
          verifiedCaller={null}
          levellingBadge={null}
          profilePhoto={null}
          publicPhotos={null}
          privatePhotos={null}
          torTags={null}
          aboutMe={""}
          lookingFor={null}
          occupation={""}
          lifestyle={null}
          education={""}
          relationship={""}
          smoking={null}
          drinking={null}
          height={null}
          bodyType={null}
          ethnicity={null}
          eyeColor={null}
          hairColor={null}
          children={null}
          // User Utility
          shouldCallApi={true}
          privatePhotoAbleToRequest={null}
          privatePhotoRequested={null}
          privatePhotoApproval={null}
          requestedMyPrivatePhoto={null}
          approvedMyPrivatePhoto={null}
          isOnline={false}
          isBlocked={false}
          isSilentInteraction={false}
          isFavorited={false}
          isCallEnabled={false}
          isSugarbaby={null}
          role={"sb"}
          // Conversation Data
          conversationId={null}
          conversationIdInt={null}
          livestreamingAchievementBadge={null}
          privateCallSettings={null}
          lastMessageContent={"Hello there"}
          lastMessageTimestamp={moment()}
          lastMessageMine={null}
          lastMessageSeen={null}
          callStatus={null}
          callDuration={null}
          ownMembershipType={"premium"}
          // Utility
          profileBorder={profileBorderImage}
        />
      </div>

      <div className="inbox-page-settings-container">
        <div>Settings</div>
      </div>
    </div>
  );
};

export default InboxPageProfileBorder;
