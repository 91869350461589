// General
import "./gift-animation-overlay.scss";
import { useState, useEffect, useRef } from "react";
// Services
import { useLazyGetProductGiftsQuery } from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateGiftAssets } from "../../../../redux/store/livestreamingStore";
// react-device-detect
import { isSafari } from "react-device-detect";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";

const GiftAnimationOverlay = () => {
  // API variables
  const [
    getProductGifts,
    {
      data: getProductGiftsData,
      error: getProductGiftsErrorData,
      isFetching: getProductGiftsFetching,
      isLoading: getProductGiftsLoading,
      isSuccess: getProductGiftsSuccess,
      isError: getProductGiftsError,
    },
  ] = useLazyGetProductGiftsQuery();

  // General variables
  const [currentVideo, setCurrentVideo] = useState(0);
  const [videoPlaylist, setVideoPlaylist] = useState([]);
  const extension = isSafari ? "mov" : "webm";
  const videoRef = useRef(null);
  const isMounted = useRef(false);

  // Redux variables
  const livestreamGiftAnimation = useSelector(
    (state) => state.livestreaming.livestreamGiftAnimation
  );
  const privateCallGiftAnimation = useSelector(
    (state) => state.privateCall.privateCallGiftAnimation
  );
  const dispatch = useDispatch();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    getProductGifts(null, true);
  }, []);

  // Lifecycle | Check for update | Get Products API Response
  useEffect(() => {
    if (getProductGiftsFetching || getProductGiftsLoading) {
    } else if (getProductGiftsSuccess) {
      if (getProductGiftsData?.status === 0) {
        const products = getProductGiftsData?.data ?? [];

        caches.open("gifts-assets").then((cache) => {
          products
            .filter((product) => product.graphics?.[extension])
            .forEach((product) => {
              cache.add(product.graphics[extension]);
            });
        });

        dispatch(updateGiftAssets(getProductGiftsData?.data));
      }
    } else if (getProductGiftsError) {
      if (getProductGiftsErrorData?.status === 401) {
        // onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getProductGiftsFetching,
    getProductGiftsLoading,
    getProductGiftsSuccess,
    getProductGiftsError,
  ]);

  // Lifecycle | Check for update | livestreamGiftAnimation
  useEffect(() => {
    if (isMounted.current) {
      if (livestreamGiftAnimation?.type === "gifting") {
        fetchVideo(livestreamGiftAnimation?.data?.gift_graphics[extension])
          .then((response) => response.blob())
          .then((blob) => {
            const url = URL.createObjectURL(blob);
            setVideoPlaylist((prev) => [...prev, url]);
          });
      }
    } else {
      isMounted.current = true;
    }
  }, [livestreamGiftAnimation]);

  // Lifecycle | Check for update | privateCallGiftAnimation
  useEffect(() => {
    if (isMounted.current) {
      if (privateCallGiftAnimation?.type === "gifting") {
        fetchVideo(privateCallGiftAnimation?.data?.gift_graphics[extension])
          .then((response) => response.blob())
          .then((blob) => {
            const url = URL.createObjectURL(blob);
            setVideoPlaylist((prev) => [...prev, url]);
          });
      }
    } else {
      isMounted.current = true;
    }
  }, [privateCallGiftAnimation]);

  // Lifecycle | Check for update | videoPlaylist & currentVideo
  useEffect(() => {
    if (videoRef.current && videoPlaylist?.length) {
      videoRef.current.play();
    }
  }, [videoPlaylist, currentVideo]);

  // Utility Functions
  const fetchVideo = async (videoFile) => {
    const request = new Request(videoFile);

    try {
      const response = await caches.match(request);
      if (response) {
        // If the video file is in the cache, serve it
        return response;
      }

      // If the video file is not in the cache, fetch it and add it to the cache
      const fetchResponse = await fetch(request);
      const clonedResponse = fetchResponse.clone();

      caches
        .open("gifts-assets")
        .then((cache) => cache.put(request, clonedResponse));

      return fetchResponse;
    } catch (err) {
      console.error("Error fetching video:", err);
    }
  };
  const onVideoEnd = () => {
    if (currentVideo === videoPlaylist?.length - 1) {
      // Clear playlist
      setVideoPlaylist([]);
      setCurrentVideo(0);
    } else {
      // Move to the next video in the playlist
      setCurrentVideo(currentVideo + 1);
    }
  };

  if (videoPlaylist?.length > 0) {
    return (
      <div id="gift-animation-overlay-subcomponent">
        <video
          id="gift-animation-video-player"
          ref={videoRef}
          src={videoPlaylist[currentVideo]}
          onEnded={onVideoEnd}
          playsInline
          muted={true}
        ></video>
      </div>
    );
  }
};

export default GiftAnimationOverlay;
