// General
import "./app-routing.scss";
import { useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
// Static Data
import routeConst from "../../const/routeConst";
// Components
import SideNavbar from "../shared/elements/navbar/side-navbar/side-navbar";

const AppRouting = () => {
  // General variables
  const [showSideNavbar, setShowSideNavbar] = useState(false);

  // Router variables
  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname === routeConst.livestream.simulateLivestream.path ||
      location.pathname === routeConst.livestream.kingOfTheMonth.path ||
      location.pathname === routeConst.livestream.kingOfTheMonth.path
    ) {
      setShowSideNavbar(false);
    } else {
      setShowSideNavbar(true);
    }
  }, [location]);

  return (
    <div id="body-container" className={showSideNavbar ? "flex" : ""}>
      <SideNavbar type="static" />
      <Outlet />
    </div>
  );
};

export default AppRouting;
