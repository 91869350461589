// General
import "./interest-user-list.scss";
// Static Data
import userConst from "../../../../../const/userConst";
// Material UI
import StarIcon from "@mui/icons-material/Star";
// Custom Hooks
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
import useGetReadableDate from "../../../../utility/custom-hooks/useGetReadableDate-hook";
// Components
import CustomAvatar from "../../../../shared/elements/custom-avatar/custom-avatar";

const InterestUserList = (props) => {
  const {
    isBlur,
    username,
    profilePhoto,
    role,
    livestreamingAchievementBadge,
    verifiedProfile,
    verifiedCaller,
    membershipType,
    timestamp,
    location,
    // Utility
    profileBorder,
  } = props;

  // Custom Hooks Functions
  const getIcon = IconManager();
  const getReadableDate = useGetReadableDate();

  return (
    <div id="user-list-shared-subcomponent">
      <div className="padding-container">
        <div className="user-list">
          <div className="user-image-container">
            <CustomAvatar
              className={`user-image ${isBlur ? "blur-image" : ""}`}
              src={profilePhoto}
              role={role}
              profileBorder={profileBorder}
            />

            {isBlur && (
              <div className="lock-image-container">
                {getIcon("lockWhiteIcon", "lock-image")}
              </div>
            )}
          </div>

          <div className="user-description-container">
            <div className="user-top-container">
              {livestreamingAchievementBadge?.map((badge, index) => (
                <img
                  className="livestreaming-achievement-badge"
                  src={badge?.url}
                  alt={badge?.type}
                  key={index}
                />
              ))}

              <div className="user-name">{username}</div>
              <div className="misc-badge-container">
                {verifiedProfile && (
                  <div className="verified-profile-badge-container">
                    {getIcon("verifiedProfileBadge", "verified-profile-badge")}
                  </div>
                )}

                {membershipType !== userConst.membershipType.free && (
                  <StarIcon className="star-icon" />
                )}

                {verifiedCaller && (
                  <div
                    className="verified-caller-badge-container"
                    // onClick={onOpenVerifiedCallerDialog}
                  >
                    {getIcon("verifiedCallerBadge", "verified-caller-badge")}
                  </div>
                )}
              </div>
            </div>

            <div className="user-bottom-container">
              <div className="user-location">{location}</div>
            </div>
          </div>

          <div className="user-last-updated-container">
            <div className="last-updated">{getReadableDate(timestamp)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterestUserList;
