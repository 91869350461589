// General
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
// Services
import { sessionService } from "../../../../services/session.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  // General Functions
  updateIsLoggedIn,
  updateApiTokens,
  // Utility Functions
} from "../../../../redux/store/publicStore";
// Custom Hooks
import useCustomNavigate from "../../custom-hooks/useCustomNavigate-hook";

const CheckLoginMounted = () => {
  // Redux variables
  const isLoggedIn = useSelector((state) => state.public.isLoggedIn);
  const dispatch = useDispatch();

  // Router variables
  const location = useLocation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    // Check if user is logged in
    if (!sessionService.getApiTokens()) {
      onNavigate(routeConst.landing.path);
    } else {
      dispatch(updateApiTokens(sessionService.getApiTokens()));
      dispatch(updateIsLoggedIn(true));
    }
  }, []);

  return <div id="check-login-mounted-shadow-component"></div>;
};

export default CheckLoginMounted;
