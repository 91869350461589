// General
import "./profile-overview-profile-border.scss";
// Redux
import { useSelector } from "react-redux";
// Components
import ProfileInfo from "./profile-info/profile-info";

const ProfileOverviewProfileBorder = () => {
  // Redux variables
  const profileBorderImage = useSelector(
    (state) => state.profileBorder.profileBorderImage
  );

  return (
    <div className="profile-overview-profile-border">
      <div className="profile-overview-label">Profile Overview</div>
      <div className="mobile-desktop-view-label">Mobile View</div>

      <div className="mobile-view">
        <ProfileInfo
          profilePhoto={null}
          username={"King Daddy"}
          membershipType={"premium"}
          levellingBadge={null}
          role={"sd"}
          // Utility
          profileBorder={profileBorderImage}
        />
      </div>

      <div className="profile-overview-settings-container">
        <div>Settings</div>
      </div>
    </div>
  );
};

export default ProfileOverviewProfileBorder;
