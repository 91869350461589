// General
import "./pk-container.scss";
import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
// Services
import { useLazyGetLivestreamingPkQuery } from "../../../../../../services/data.service";
// Static Data
import routeConst from "../../../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  // PK Functions
  updateContestant1,
  updateContestant2,
  updateCurrentRound,
  updatePkWinner,
  setPkPreStartTimer,
  updatePkPreStartTimer,
  setPkTimer,
  updatePkTimer,
  updatePkPreStartInterval,
  updatePkInterval,
  updateCurrentRoundPkEndDate,
  resetPkInfo,
} from "../../../../../../redux/store/livestreamingStore";
// Moment
import moment from "moment";
// Custom Hooks
import useCustomNavigate from "../../../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../../../utility/manager/icon-manager/icon-manager";
// Components
import CustomAvatar from "../../../../../shared/elements/custom-avatar/custom-avatar";

const PkContainer = () => {
  // API variables
  const [
    getLivestreamingPk,
    {
      data: getLivestreamingPkData,
      error: getLivestreamingPkErrorData,
      isFetching: getLivestreamingPkFetching,
      isLoading: getLivestreamingPkLoading,
      isSuccess: getLivestreamingPkSuccess,
      isError: getLivestreamingPkError,
    },
  ] = useLazyGetLivestreamingPkQuery();

  // General variables
  const isMounted = useRef(false);

  // Redux variables
  const contestant1 = useSelector((state) => state.livestreaming.contestant1);
  const contestant2 = useSelector((state) => state.livestreaming.contestant2);
  const currentRound = useSelector((state) => state.livestreaming.currentRound);
  const pkWinner = useSelector((state) => state.livestreaming.pkWinner);
  const pkPreStartTimer = useSelector(
    (state) => state.livestreaming.pkPreStartTimer
  );
  const pkTimer = useSelector((state) => state.livestreaming.pkTimer);
  const pkPreStartInterval = useSelector(
    (state) => state.livestreaming.pkPreStartInterval
  );
  const pkInterval = useSelector((state) => state.livestreaming.pkInterval);
  const currentRoundPkEndDate = useSelector(
    (state) => state.livestreaming.currentRoundPkEndDate
  );
  const pkIntervalPassthrough = useSelector(
    (state) => state.livestreaming.pkIntervalPassthrough
  );
  const dispatch = useDispatch();

  // Router variables
  const { id } = useParams();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    // getLivestreamingPk();
  }, []);

  // Lifecycle | Unmounted
  useEffect(() => {
    return () => {
      dispatch(resetPkInfo());
    };
  }, []);

  // Lifecycle | Check for update | Livestreaming PK API Response
  useEffect(() => {
    if (getLivestreamingPkFetching || getLivestreamingPkLoading) {
    } else if (getLivestreamingPkSuccess) {
      if (getLivestreamingPkData?.status >= 0) {
        dispatch(updateContestant1(getLivestreamingPkData?.data?.users[0]));
        dispatch(updateContestant2(getLivestreamingPkData?.data?.users[1]));
        dispatch(
          updateCurrentRound(getLivestreamingPkData?.data?.current_round)
        );
        dispatch(updatePkWinner(getLivestreamingPkData?.data?.final_winner));

        setPkTiming(
          getLivestreamingPkData?.data?.start_at_epoch,
          getLivestreamingPkData?.data?.end_at_epoch
        );
      }
    } else if (getLivestreamingPkError) {
      if (getLivestreamingPkErrorData?.status === 401) {
        // onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getLivestreamingPkFetching,
    getLivestreamingPkLoading,
    getLivestreamingPkSuccess,
    getLivestreamingPkError,
  ]);

  // Lifecycle | Check for update | pkIntervalPassthrough
  useEffect(() => {
    if (isMounted.current) {
      if (
        !pkIntervalPassthrough?.startEpoch ||
        !pkIntervalPassthrough?.endEpoch
      )
        return;

      setPkTiming(
        pkIntervalPassthrough?.startEpoch,
        pkIntervalPassthrough?.endEpoch
      );
    } else {
      isMounted.current = true;
    }
  }, [pkIntervalPassthrough]);

  // Lifecycle | Check for update | pkPreStartTimer
  useEffect(() => {
    if (!pkPreStartInterval && pkPreStartTimer > 0) {
      dispatch(
        updatePkPreStartInterval(
          setInterval(() => {
            dispatch(updatePkPreStartTimer());
          }, 1000)
        )
      );
    }
  }, [pkPreStartTimer]);

  // Lifecycle | Check for update | pkTimer
  useEffect(() => {
    if (!pkInterval && pkTimer > 0) {
      dispatch(
        updatePkInterval(
          setInterval(() => {
            const now = moment();
            const tempPkTimer = moment(currentRoundPkEndDate).diff(
              now,
              "seconds"
            );
            dispatch(updatePkTimer(tempPkTimer));
          }, 1000)
        )
      );
    }
  }, [pkTimer]);

  // Event Handlers | Button
  const onNavigateToPkContestantStream = () => {
    return; // Need more testing, Might have issue

    onNavigate(routeConst.live.path);

    setTimeout(() => {
      onNavigate(
        `${routeConst.live.liveId.clearPath}${
          contestant1?.channel_id === id
            ? contestant2?.channel_id
            : contestant1?.channel_id
        }`
      );
    }, 2000);
  };

  // Utility Functions
  const setPkTiming = (startEpoch, endEpoch) => {
    const now = moment();
    const start = moment.unix(startEpoch);
    const end = moment.unix(endEpoch);

    const tempPkTimer = now.isBefore(end) ? end.diff(now, "seconds") : 0;
    dispatch(setPkPreStartTimer(now.isBefore(start) ? 5 : 0));

    if (pkTimer <= 0) {
      dispatch(updateCurrentRoundPkEndDate(end.toISOString()));
      dispatch(setPkTimer(tempPkTimer));
    }
  };
  const getFormattedTime = (seconds) => {
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds / 60) % 60);
    let secs = Math.floor(seconds % 60);

    let formattedHours = hours < 10 ? "0" + hours : hours;
    let formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    let formattedSeconds = secs < 10 ? "0" + secs : secs;

    if (hours > 0) {
      return formattedHours + ":" + formattedMinutes + ":" + formattedSeconds;
    } else {
      return formattedMinutes + ":" + formattedSeconds;
    }
  };

  if (contestant1 && contestant2) {
    return (
      <div
        id="ongoing-events-pk-container-subcomponent"
        className={`${
          !pkWinner && pkTimer <= 0 ? "pulsing-scale-animation" : ""
        }`}
        onClick={onNavigateToPkContestantStream}
      >
        <div className={`${pkWinner ? "winner-card-border" : ""}`}>
          <div className="pk-event-container">
            <div className="pk-contestant-container">
              <div className="pk-contestant-1">
                <CustomAvatar
                  className="pk-contestant-img"
                  src={contestant1?.user_photo}
                />
              </div>

              <div className="pk-logo-container">
                {getIcon("pkIcon", "pk-logo")}
              </div>

              <div className="pk-contestant-2">
                <CustomAvatar
                  className="pk-contestant-img"
                  src={contestant2?.user_photo}
                />
              </div>
            </div>

            <div className="score-container">
              <div className="contestant-one-score">
                {contestant1?.win_count}
              </div>
              <div className="contestant-two-score">
                {contestant2?.win_count}
              </div>
            </div>

            {!pkWinner && (
              <div className="pk-round" v-if="!final_winner.user_id">
                ROUND {currentRound}
              </div>
            )}

            {pkPreStartTimer > 0 && (
              <div className="pre-start-timer">{pkPreStartTimer}</div>
            )}

            {!pkWinner && pkPreStartTimer <= 0 && pkTimer > 0 && (
              <div
                className={`pk-timer ${
                  pkTimer <= 60 ? "pulsing-animation" : ""
                }`}
              >
                Time Left :
                <span
                  className={`timer-green ${
                    pkTimer <= 60 ? "timer-red" : "timer-green"
                  }`}
                >
                  {" "}
                  {getFormattedTime(pkTimer)}
                </span>
              </div>
            )}

            {!pkWinner && pkTimer <= 0 && (
              <div className="pk-ended-label">Ended</div>
            )}

            {pkWinner && (
              <div className="pk-winner">
                <div className="pk-winner-label">PK WINNER:</div>
                <div className="winner-label">
                  {pkWinner === contestant1?.user_id
                    ? contestant1?.username
                    : contestant2?.username}
                </div>
              </div>
            )}

            <div className="pk-contestant">
              <div className="medal-logo-container">
                {Array.from(
                  { length: contestant1?.win_count || 0 },
                  (_, index) => (
                    <div
                      className="individual-medal-logo-container"
                      key={index}
                    >
                      {getIcon("pkMedalIcon", "medal-logo")}
                    </div>
                  )
                )}
              </div>

              <div
                className={`contestant-name ${
                  pkWinner === contestant1?.user_id ? "winner" : ""
                }`}
              >
                {contestant1?.username}
              </div>
              <div className="colon"> : </div>
              <div
                className={`contestant-diamond ${
                  pkWinner === contestant1?.user_id ? "winner" : ""
                }`}
              >
                {contestant1?.recorded_diamonds}
              </div>

              <div className="diamond-logo-container">
                {getIcon("sbDiamondIcon", "diamond-logo")}
              </div>
            </div>

            <div className="pk-contestant">
              <div className="medal-logo-container">
                {Array.from(
                  { length: contestant2?.win_count || 0 },
                  (_, index) => (
                    <div
                      className="individual-medal-logo-container"
                      key={index}
                    >
                      {getIcon("pkMedalIcon", "medal-logo")}
                    </div>
                  )
                )}
              </div>

              <div
                className={`contestant-name ${
                  pkWinner === contestant2?.user_id ? "winner" : ""
                }`}
              >
                {contestant2?.username}
              </div>
              <div className="colon"> : </div>
              <div
                className={`contestant-diamond ${
                  pkWinner === contestant2?.user_id ? "winner" : ""
                }`}
              >
                {contestant2?.recorded_diamonds}
              </div>

              <div className="diamond-logo-container">
                {getIcon("sbDiamondIcon", "diamond-logo")}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default PkContainer;
