// General
import "./profile-border.scss";
// Components
import GlobalSettings from "./global-settings/global-settings";
import InboxPageProfileBorder from "./inbox-page-profile-border/inbox-page-profile-border";
import ConversationPageProfileBorder from "./conversation-page-profile-border/conversation-page-profile-border";
import LivestreamMobileTopbarProfileBorder from "./livestream-mobile-topbar-profile-border/livestream-mobile-topbar-profile-border";
import LivestreamProfileViewUserInfoProfileBorder from "./livestream-profile-view-user-info-profile-border/livestream-profile-view-user-info-profile-border";
import LivestreamProfileViewViewerInfoProfileBorder from "./livestream-profile-view-viewer-info-profile-border/livestream-profile-view-viewer-info-profile-border";
import LivestreamLiveLeaderboardProfileBorder from "./livestream-live-leaderboard-profile-border/livestream-live-leaderboard-profile-border";
import LivestreamChatSectionProfileBorder from "./livestream-chat-section-profile-border/livestream-chat-section-profile-border";
import InterestUserListProfileBorder from "./interest-user-list-profile-border/interest-user-list-profile-border";
import NotificationListProfileBorder from "./notification-list-profile-border/notification-list-profile-border";
import ProfileOverviewProfileBorder from "./profile-overview-profile-border/profile-overview-profile-border";

const ProfileBorder = () => {
  return (
    <div id="profile-border-page">
      <div className="max-width-container">
        <div className="profile-badge-padding-container">
          <div className="profile-border-title">Profile Photo Border</div>

          <GlobalSettings />

          <div className="profile-border-content">
            <InboxPageProfileBorder />

            <ConversationPageProfileBorder />

            <LivestreamMobileTopbarProfileBorder />

            <LivestreamProfileViewUserInfoProfileBorder />

            <LivestreamProfileViewViewerInfoProfileBorder />

            <LivestreamLiveLeaderboardProfileBorder />

            <LivestreamChatSectionProfileBorder />

            <InterestUserListProfileBorder />

            <NotificationListProfileBorder />

            <ProfileOverviewProfileBorder />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileBorder;
