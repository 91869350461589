// General
import "./livestream-mobile-topbar.scss";
// Static Data
import userConst from "../../../../../const/userConst";
// Material UI
import CheckIcon from "@mui/icons-material/Check";
import AddIcon from "@mui/icons-material/Add";
// Custom Hooks
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import CustomAvatar from "../../../../shared/elements/custom-avatar/custom-avatar";
import Spinner from "../../../../shared/elements/spinner/spinner";

const LivestreamMobileTopbar = (props) => {
  const {
    livestreamerUsername,
    livestreamerProfilePhoto,
    livestreamerViewers,
    livestreamerDiamonds,
    livestreamerIsFollowing,
    livestreamerLevellingBadge,

    // Utility
    profileBorder,
  } = props;

  const livestreamerAchievements = null;

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Event Handlers | Button
  const onFollowLivestreamer = () => {};

  // Utility Functions
  const onGetDiamonds = (diamonds) => {
    if (diamonds >= 1000000000) {
      return `${(diamonds / 1000000000).toFixed(0)}B`;
    } else if (diamonds >= 1000000) {
      return `${(diamonds / 1000000).toFixed(0)}M`;
    } else if (diamonds >= 1000) {
      return `${(diamonds / 1000).toFixed(0)}K`;
    } else {
      return diamonds;
    }
  };

  return (
    <div className="user-content-container">
      <div className="profile-picture-container">
        <CustomAvatar
          className="profile-picture"
          src={livestreamerProfilePhoto}
          role={userConst.userRole.sugarbaby}
          profileBorder={profileBorder}
          // onClick={onViewProfile}
        />

        {livestreamerLevellingBadge && (
          <div className="levelling-badge-container">
            <img
              className="levelling-badge"
              src={livestreamerLevellingBadge}
              alt="levelling-badge"
            />
          </div>
        )}
      </div>

      <div className="user-details-container">
        <div className="username-container">
          {livestreamerAchievements?.chat_badges_set?.length > 0 && (
            <div className="achievement-container">
              {livestreamerAchievements?.chat_badges_set?.map(
                (badge, index) => (
                  <img
                    className="livestreamer-badge"
                    src={badge?.url}
                    key={index}
                  />
                )
              )}
            </div>
          )}
          <div className="username">{livestreamerUsername || "-"}</div>
        </div>

        <div className="livestream-stats-container">
          <div className="viewer-count-container">
            <div className="viewer-count-icon-container">
              {getIcon("liveViewerIcon", "viewer-count-icon")}
            </div>

            <div className="viewer-count">{livestreamerViewers || 0}</div>
          </div>

          <div className="diamond-count-container">
            <div className="diamond-icon-container">
              {getIcon("sbDiamondIcon", "diamond-icon")}
            </div>

            <div className="diamond-count">
              {onGetDiamonds(livestreamerDiamonds) || 0}
            </div>
          </div>
        </div>
      </div>

      <div className="livestreamer-follow-button-container">
        <div className="follow-button" onClick={onFollowLivestreamer}>
          {false ? (
            <Spinner size={20} isPadding={false} />
          ) : livestreamerIsFollowing ? (
            <CheckIcon />
          ) : (
            <AddIcon />
          )}
        </div>
      </div>
    </div>
  );
};

export default LivestreamMobileTopbar;
