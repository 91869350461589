// General
import "./add-user-dialog.scss";
import { useState, useEffect } from "react";
// Services
import {
  useSigninMutation,
  useLazyGetProfileQuery,
} from "../../../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateEmail,
  updatePassword,
} from "../../../../redux/store/signinStore";
import {
  addApiTokens,
  updateApiConfigHeaders,
  updateApiToken,
  updateIsLoggedIn,
} from "../../../../redux/store/publicStore";
import { addUser } from "../../../../redux/store/userStore";
import { updateAddUserDialog } from "../../../../redux/store/dialogStore";
import { updateErrorToast } from "../../../../redux/store/toastStore";
// Material UI
import { Dialog, TextField, InputAdornment, IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// i18next
import { useTranslation } from "react-i18next";
// Components
import Spinner from "../../elements/spinner/spinner";

const AddUserDialog = () => {
  // API variables
  const [
    signin,
    {
      data: signinData,
      error: signinErrorData,
      isLoading: signinLoading,
      isSuccess: signinSuccess,
      isError: signinError,
    },
  ] = useSigninMutation();
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();

  // General variables
  const [showPassword, setShowPassword] = useState(false);

  // Redux variables
  const addUserDialog = useSelector((state) => state.dialog.addUserDialog);
  const email = useSelector((state) => state.signin.email);
  const password = useSelector((state) => state.signin.password);
  const usersList = useSelector((state) => state.user.usersList);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Check for update | Signin API Response
  useEffect(() => {
    if (signinLoading) {
    } else if (signinSuccess) {
      switch (signinData?.status) {
        case 0:
          if (signinData?.data?.token) {
            dispatch(addApiTokens(signinData?.data?.token));
            dispatch(updateApiConfigHeaders(signinData?.data?.token));
            dispatch(updateIsLoggedIn(true));

            setTimeout(() => {
              getProfile(null, false);
            }, 1000);
          } else {
            const toastObj = {
              message: "Token not available",
              autoClose: 3000,
            };
            dispatch(updateErrorToast(toastObj));
          }
          break;
        default:
          const defaultToastObj = {
            message: t("common.something_went_wrong"),
            autoClose: 3000,
          };
          dispatch(updateErrorToast(defaultToastObj));
          break;
      }
    } else if (signinError) {
      switch (signinErrorData?.status) {
        case -1:
          // Wrong credentials
          break;
        case 401:
          // Wrong credentials
          break;
        case 403:
          // VPN detected
          break;
        default:
          break;
      }
      const toastObj = {
        message: signinErrorData?.data?.message,
        autoClose: 3000,
      };
      dispatch(updateErrorToast(toastObj));
    }
  }, [signinLoading, signinSuccess, signinError]);

  // Lifecycle | Check for update | Get Profile API Response
  useEffect(() => {
    if (getProfileFetching || getProfileLoading) {
    } else if (getProfileSuccess) {
      switch (getProfileData?.status) {
        case 0:
        case 1:
          const user = getProfileData?.data;
          const userExists = usersList?.find(
            (item) => item?.id_int === user?.id_int
          );
          if (!userExists) {
            dispatch(addUser(user));
          }

          dispatch(updateEmail(""));
          dispatch(updatePassword(""));

          onCloseDialog();
          break;
        default:
      }
    } else if (getProfileError) {
    }
  }, [
    getProfileFetching,
    getProfileLoading,
    getProfileSuccess,
    getProfileError,
  ]);

  // Event Handlers | Button
  const onShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const onLogin = () => {
    const user = usersList?.find((item) => item?.email === email);
    if (!user) {
      const obj = {
        action: "login",
        auth_provider: "email_password",
        email,
        password,
        utm_data: "{}",
      };
      signin(obj);
    } else {
      const toastObj = {
        message: "Existing user found",
        autoClose: 3000,
      };
      dispatch(updateErrorToast(toastObj));
    }
  };

  // Event Handlers | MUI Text Field
  const onEmailChange = (event) => {
    dispatch(updateEmail(event.target.value?.trim()?.toLowerCase()));
  };
  const onPasswordChange = (event) => {
    dispatch(updatePassword(event.target.value));
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateAddUserDialog(false));
  };

  return (
    <Dialog
      className="custom-radius10-dialog"
      open={addUserDialog}
      onClose={onCloseDialog}
    >
      <div id="add-user-dialog">
        <div className="padding-container">
          <div className="email-form-field-container">
            <TextField
              className="email-form-field custom-text-field"
              required
              value={email}
              onChange={onEmailChange}
              label={"Email Address"}
              placeholder={"Email Address"}
              variant="standard"
            />
          </div>

          <div className="password-form-field-container">
            <TextField
              className="password-form-field custom-text-field"
              sx={{
                "& .MuiInput-underline:after": { borderBottomColor: "#710D0D" },
                "& label.Mui-focused": { color: "rgba(0, 0, 0, 0.6)" },
              }}
              required
              value={password}
              onChange={onPasswordChange}
              label={"Password"}
              type={showPassword ? "text" : "password"}
              placeholder={"Password"}
              variant="standard"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={onShowPassword}>
                      {showPassword ? (
                        <VisibilityIcon className="show-password" />
                      ) : (
                        <VisibilityOffIcon className="hide-password" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div className="login-button" onClick={onLogin}>
            {signinLoading || getProfileFetching || getProfileLoading ? (
              <Spinner isPadding={false} size={23} color={"white-spinner"} />
            ) : (
              "Login"
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default AddUserDialog;
