// General
import { createBrowserRouter } from "react-router-dom";
// Static Data
import routeConst from "./const/routeConst";
// Components
import App from "./App";
// Login
import Login from "./components/login/login";
// Dashboard
import Dashboard from "./components/dashbaord/dashboard";
// User
import ProfileBorder from "./components/user/profile-border/profile-border";
import UserCard from "./components/user/user-card/user-card";
import UserCardV2 from "./components/user/user-card-v2/user-card-v2";
import CoAnchorCard from "./components/user/co-anchor-card/co-anchor-card";
// Livestream
import SimulateLivestream from "./components/livestream/simulate-livestream/simulate-livestream";
import KingOfTheMonth from "./components/livestream/king-of-the-month/king-of-the-month";
import GiftAnimation from "./components/livestream/gift-animation/gift-animation";
// Banner
import BannerTester from "./components/banner/banner-tester/banner-tester";
// Coins
import CoinsPackageDesigner from "./components/coins/coins-package-designer/coins-package-designer";
// Error 404 Page
import Error404Page from "./components/404/404";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      // General
      { path: routeConst.landing.path, element: <Dashboard /> },

      // Login
      { path: routeConst.login.path, element: <Login /> },

      // Dashboard
      { path: routeConst.dashboard.path, element: <Dashboard /> },

      // User
      // Profile Border
      {
        path: routeConst.user.profilePhotoBorder.path,
        element: <ProfileBorder />,
      },
      {
        path: routeConst.user.userCard.path,
        element: <UserCard />,
      },
      {
        path: routeConst.user.userCardV2.path,
        element: <UserCardV2 />,
      },
      {
        path: routeConst.user.coAnchorCard.path,
        element: <CoAnchorCard />,
      },

      // Livestream
      {
        path: routeConst.livestream.simulateLivestream.path,
        element: <SimulateLivestream />,
      },
      {
        path: routeConst.livestream.kingOfTheMonth.path,
        element: <SimulateLivestream />,
      },
      {
        path: routeConst.livestream.giftAnimation.path,
        element: <SimulateLivestream />,
      },

      // Banner
      { path: routeConst.banner.bannerTester.path, element: <BannerTester /> },

      // Coins
      {
        path: routeConst.coins.coinPackageDesigner.path,
        element: <CoinsPackageDesigner />,
      },

      // Error Page
      { path: "*", element: <Error404Page /> },
    ],
    errorElement: <Error404Page />,
  },
]);

export default router;
