// General
import "./simulate-livestream.scss";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// Services
import {
  useLazyGetLivestreamingChannelQuery,
  usePostLivestreamingEnterChannelMutation,
  usePostLivestreamingEnteredChannelMutation,
  usePostLivestreamingLeaveChannelMutation,
  useLazyGetLivestreamingViewersKeepAliveQuery,
} from "../../../services/data.service";
// Static Data
import routeConst from "../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  // General Functions
  updateChannelId,
  updateLivestreamerUserObj,
  updateLivestreamerId,
  updateLivestreamerIdInt,
  updateLivestreamerProfilePhoto,
  updateLivestreamerAchievements,
  updateLivestreamerUsername,
  updateLivestreamerLevellingBadge,
  updateLivestreamerFollowers,
  updateLivestreamerViewers,
  updateLivestreamerDiamonds,
  updateLivestreamerIsFollowing,
  resetChannelId,
  resetLivestreamerInfo,

  // Video Player Functions
  updateLivestreamLoading,

  // Chat Functions
  clearLivestreamChatMessages,

  // Gift Animation Functions
  resetLivestreamGiftAnimation,

  // Live Viewers Functions
  resetLiveViewers,

  // Top Fans Functions
  resetTopFansDaily,
  resetTopFansWeekly,
  resetTopFansMonthly,
  resetTopFansOverall,

  // Livestream Switching Counter Functions
  updateLivestreamSwitchingCounter,
  clearLivestreamSwitchingCounter,
  toggleVideoPlayerMute,
} from "../../../redux/store/livestreamingStore";
import { updateCardDetail } from "../../../redux/store/paymentStore";
import {
  updateLiveViewPusherSubscribe,
  updateLiveViewPusherUnsubscribe,
} from "../../../redux/store/pusherStore";
import {
  updateCoinsDiscountDialog,
  updateLivestreamKickedDialog,
  updateLivestreamReportDialog,
} from "../../../redux/store/dialogStore";
// React Swipeable Views
import SwipeableViews from "react-swipeable-views/lib/SwipeableViews";
// Material UI
import { useTheme, useMediaQuery } from "@mui/material";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../utility/manager/icon-manager/icon-manager";
// Components
import VideoPlayer from "./video-player/video-player";
import GiftAnimationOverlay from "../../shared/elements/gift-animation-overlay/gift-animation-overlay";
import SlideInOutAlertOverlay from "./slide-in-out-alert-overlay/slide-in-out-alert-overlay";
import EmojiOverlay from "../../shared/elements/emoji-overlay/emoji-overlay";
import InteractableOverlay from "./interactable-overlay/interactable-overlay";
import LivestreamLoadingOverlay from "./loading-overlay/loading-overlay";
import LivestreamBufferingOverlay from "./buffering-overlay/buffering-overlay";
import PlayOverlay from "./play-overlay/play-overlay";
import DesktopLeftSection from "./desktop-left-section/desktop-left-section";
import TabletRightSection from "./tablet-right-section/tablet-right-section";
import FloatingBanners from "./interactable-overlay/ongoing-events/floating-banners/floating-banners";
import PinChat from "./interactable-overlay/pin-chat/pin-chat";
import Topbar from "./interactable-overlay/topbar/topbar";
import OngoingEvents from "./interactable-overlay/ongoing-events/ongoing-events";
import GiftsSection from "../../shared/elements/gifts-section/gifts-section";
import LivestreamKickedDialog from "../../shared/dialog-content/livestream-kicked-dialog/livestream-kicked-dialog";
import LivestreamReportDialog from "../../shared/dialog-content/livestream-report-dialog/livestream-report-dialog";

const SimulateLivestream = () => {
  // API variables
  const [
    getLivestreamingChannel,
    {
      data: getLivestreamingChannelData,
      error: getLivestreamingChannelErrorData,
      isFetching: getLivestreamingChannelFetching,
      isLoading: getLivestreamingChannelLoading,
      isSuccess: getLivestreamingChannelSuccess,
      isError: getLivestreamingChannelError,
    },
  ] = useLazyGetLivestreamingChannelQuery();
  const [
    postLivestreamingEnterChannel,
    {
      data: postLivestreamingEnterChannelData,
      error: postLivestreamingEnterChannelErrorData,
      isLoading: postLivestreamingEnterChannelLoading,
      isSuccess: postLivestreamingEnterChannelSuccess,
      isError: postLivestreamingEnterChannelError,
    },
  ] = usePostLivestreamingEnterChannelMutation();
  const [
    postLivestreamingEnteredChannel,
    {
      data: postLivestreamingEnteredChannelData,
      error: postLivestreamingEnteredChannelErrorData,
      isLoading: postLivestreamingEnteredChannelLoading,
      isSuccess: postLivestreamingEnteredChannelSuccess,
      isError: postLivestreamingEnteredChannelError,
    },
  ] = usePostLivestreamingEnteredChannelMutation();
  const [
    postLivestreamingLeaveChannel,
    {
      data: postLivestreamingLeaveChannelData,
      error: postLivestreamingLeaveChannelErrorData,
      isLoading: postLivestreamingLeaveChannelLoading,
      isSuccess: postLivestreamingLeaveChannelSuccess,
      isError: postLivestreamingLeaveChannelError,
    },
  ] = usePostLivestreamingLeaveChannelMutation();
  const [
    getLivestreamingViewersKeepAlive,
    {
      data: getLivestreamingViewersKeepAliveData,
      error: getLivestreamingViewersKeepAliveErrorData,
      isFetching: getLivestreamingViewersKeepAliveFetching,
      isLoading: getLivestreamingViewersKeepAliveLoading,
      isSuccess: getLivestreamingViewersKeepAliveSuccess,
      isError: getLivestreamingViewersKeepAliveError,
    },
  ] = useLazyGetLivestreamingViewersKeepAliveQuery();

  // Redux variables
  const disableSwipe = useSelector((state) => state.livestreaming.disableSwipe);
  const livestreamSwitchingCounter = useSelector(
    (state) => state.livestreaming.livestreamSwitchingCounter
  );
  const videoPlayerMute = useSelector(
    (state) => state.livestreaming.videoPlayerMute
  );
  const dispatch = useDispatch();

  // MUI variables
  const theme = useTheme();
  const [value, setValue] = useState(1);
  const isDesktop = useMediaQuery("(min-width: 1305px)");
  const isTablet = useMediaQuery("(min-width: 900px)");
  const isMobile = useMediaQuery("(max-width: 900px)");

  // Router variables
  const { id } = useParams();

  // Utility variables
  let keepAliveInterval;
  let viewerKeepAliveBody = {
    channel_id: id,
  };
  let viewerEnterLeaveBody = {
    channel_id: id,
  };

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted & Unmounted
  useEffect(() => {
    // Clear livestream chat incase Unmounted did not clear it
    dispatch(clearLivestreamChatMessages());

    // dispatch(updateLivestreamLoading(true));
    dispatch(updateLivestreamKickedDialog(false));
    // dispatch(updateChannelId(id));
    // dispatch(updateLiveViewPusherSubscribe({}));
    // postLivestreamingEnterChannel(viewerEnterLeaveBody);
    // getLivestreamingChannel(id, true);

    // keepAliveInterval = setInterval(() => {
    //   getLivestreamingViewersKeepAlive(viewerKeepAliveBody);
    // }, 30000);

    return () => {
      // const obj = {
      //   channel_id: id,
      // };
      // postLivestreamingLeaveChannel(obj);
      dispatch(resetChannelId());
      dispatch(resetLivestreamerInfo());
      dispatch(resetLiveViewers());
      dispatch(resetTopFansDaily());
      dispatch(resetTopFansWeekly());
      dispatch(resetTopFansMonthly());
      dispatch(resetTopFansOverall());
      dispatch(clearLivestreamChatMessages());
      dispatch(resetLivestreamGiftAnimation());
      dispatch(updateLivestreamSwitchingCounter());
      // dispatch(updateLiveViewPusherUnsubscribe({}));

      // clearInterval(keepAliveInterval);

      if (livestreamSwitchingCounter > 3) {
        dispatch(updateCoinsDiscountDialog(true));
        dispatch(clearLivestreamSwitchingCounter());
      }
    };
  }, []);

  // Lifecycle | Check for update | getLivestreamingChannels API Response
  useEffect(() => {
    if (getLivestreamingChannelFetching || getLivestreamingChannelLoading) {
    } else if (getLivestreamingChannelSuccess) {
      if (getLivestreamingChannelData?.status === 1) {
        dispatch(
          updateLivestreamerUserObj(getLivestreamingChannelData?.data?.user)
        );
        dispatch(
          updateLivestreamerId(getLivestreamingChannelData?.data?.user?.id)
        );
        dispatch(
          updateLivestreamerIdInt(
            getLivestreamingChannelData?.data?.user?.id_int
          )
        );
        dispatch(
          updateLivestreamerProfilePhoto(
            getLivestreamingChannelData?.data?.user?.profile_photo
              ?.original_photo
          )
        );
        dispatch(
          updateLivestreamerAchievements(
            getLivestreamingChannelData?.data?.user?.live_streaming_achievements
          )
        );
        dispatch(
          updateLivestreamerUsername(
            getLivestreamingChannelData?.data?.user?.username
          )
        );
        dispatch(
          updateLivestreamerLevellingBadge(
            getLivestreamingChannelData?.data?.user?.levelling_profile?.badge
          )
        );
        dispatch(
          updateLivestreamerFollowers(
            getLivestreamingChannelData?.data?.user?.live_streaming_meta
              ?.follower_count
          )
        );
        dispatch(
          updateLivestreamerViewers(
            getLivestreamingChannelData?.data?.live_stream?.viewer_count
          )
        );
        dispatch(
          updateLivestreamerDiamonds(
            getLivestreamingChannelData?.data?.user?.live_streaming_meta
              ?.lifetime_diamonds_earned
          )
        );
        dispatch(
          updateLivestreamerIsFollowing(
            getLivestreamingChannelData?.data?.user?.live_streaming_meta
              ?.is_following
          )
        );

        if (
          getLivestreamingChannelData?.data?.live_stream?.live_status ===
          "ended"
        ) {
          onNavigate(routeConst.live.ended.path);
        }
      }
    } else if (getLivestreamingChannelError) {
      if (getLivestreamingChannelErrorData?.status === 401) {
        // onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getLivestreamingChannelFetching,
    getLivestreamingChannelLoading,
    getLivestreamingChannelSuccess,
    getLivestreamingChannelError,
  ]);

  // Event Handlers | MUI Tabs
  const onTabChangeBySwipe = (index) => {
    // Swipe changes here
    // So far nothing needed
  };

  return (
    <div id="livestream-page">
      {/* Left Section if view is Desktop */}
      <DesktopLeftSection />

      {/* Video Player */}
      <div className="video-player-container">
        <VideoPlayer />

        <LivestreamBufferingOverlay />

        {/* Gift & Emoji Overlay for Tablet & Desktop */}
        {!isMobile && (
          <div className="tablet-desktop-view-container">
            {/* <GiftAnimationOverlay /> */}
            <EmojiOverlay />

            <div className="livestream-desktop-video-overlay-container">
              <Topbar showProfile={false} showBack={false} />
              <OngoingEvents />
            </div>

            <div className="quick-gift-container">
              <PinChat />
              <GiftsSection state={"livestream"} />
            </div>
          </div>
        )}
      </div>

      <LivestreamLoadingOverlay />

      <PlayOverlay />

      {/* Right Section if view is Tablet or Desktop */}
      <TabletRightSection />

      {/* Mobile View */}
      {isMobile && (
        <div className="mobile-view">
          <SwipeableViews
            className="livestream-swipeable-container"
            containerStyle={{
              transition: "transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s",
            }}
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={onTabChangeBySwipe}
            disabled={disableSwipe}
          >
            <div
              className="stripped-down-container"
              index={0}
              dir={theme.direction}
            >
              <div className="stripped-down-padding-container">
                <Topbar
                  showProfile={false}
                  showVolume={false}
                  showReport={false}
                />
              </div>

              <div className="gifts-section-container">
                <GiftsSection state={"livestream"} />
              </div>
            </div>

            <div
              className="interactable-container"
              index={1}
              dir={theme.direction}
            >
              <GiftAnimationOverlay />
              <SlideInOutAlertOverlay />
              <EmojiOverlay />

              <InteractableOverlay />
            </div>

            {/* TBA | Empty Overlay */}
            {/* <div className="blank-container" index={2} dir={theme.direction}>
              <div className="back-button-container" onClick={onBack}>
                <img className="back-button" src={LiveBack} />
              </div>
            </div> */}
          </SwipeableViews>
        </div>
      )}

      <LivestreamKickedDialog />
      <LivestreamReportDialog />
    </div>
  );
};

export default SimulateLivestream;
