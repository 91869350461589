// General
import "./navbar-mobile-view.scss";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
// Services
import {
  useLazyGetUserAioQuery,
  useLazyGetProfileQuery,
} from "../../../../../services/data.service";
// Static Data
import routeConst from "../../../../../const/routeConst";
import profileConst from "../../../../../const/profileConst";
import userConst from "../../../../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateEditProfilePassthrough,
  updateSaveVideoCallSettingsPassthrough,
} from "../../../../../redux/store/userStore";
import {
  updateUserProfileDialog,
  updateVerifiedCallerDescriptionDialog,
} from "../../../../../redux/store/dialogStore";
import { updateVideoCallPassthrough } from "../../../../../redux/store/privateCallStore";
import { updateSearchScrollToTopPassthrough } from "../../../../../redux/store/searchStore";
import {
  updateInboxScrollToTopPassthrough,
  updateConversationUserProfilePassthrough,
} from "../../../../../redux/store/inboxStore";
import { updateVideoCallScrollToTopPassthrough } from "../../../../../redux/store/privateCallStore";
import { updateLivestreamScrollToTopPassthrough } from "../../../../../redux/store/livestreamingStore";
import { updateInterestScrollToTopPassthrough } from "../../../../../redux/store/interestStore";
import { updateGameListPassthrough } from "../../../../../redux/store/gameStore";
import { updateLeaderboardTab } from "../../../../../redux/store/leaderboardStore";
import {
  updateDiamondsInfoDialog,
  updateMyWalletInfoDialog,
  updateSearchFilterDialog,
} from "../../../../../redux/store/dialogStore";
// Material UI
import {
  Divider,
  IconButton,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  useMediaQuery,
  Avatar,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import CustomAvatar from "../../custom-avatar/custom-avatar";

const NavbarMobileView = () => {
  // API variables
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();

  // General variables
  let [showNavbar, setShowNavbar] = useState(true);

  // Render variables
  const [mobileLeftButton, setMobileLeftButton] = useState(null);
  const [mobileMiddleSection, setMobileMiddleSection] = useState(null);
  const [mobileRightButton, setMobileRightButton] = useState(null);

  // Redux variables
  const isLoggedIn = useSelector((state) => state.public.isLoggedIn);
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const isFiltered = useSelector((state) => state.searchFilter.isFiltered); // Search Page
  const conversationUser = useSelector((state) => state.inbox.conversationUser); // Inbox Chat Page
  const ownPrivateCallEnable = useSelector(
    (state) => state.inbox.ownPrivateCallEnable
  ); // Inbox Chat Page
  const otherUserPrivateCallEnable = useSelector(
    (state) => state.inbox.otherUserPrivateCallEnable
  ); // Inbox Chat Page
  const leaderboardTitle = useSelector(
    (state) => state.leaderboard.leaderboardTitle
  ); // Leaderboard Page
  const calleeUsername = useSelector(
    (state) => state.privateCall.calleeUsername
  ); // Video Call Page
  const username = useSelector((state) => state.userCard.username); // User Card Dialog
  const navigationHistory = useSelector(
    (state) => state.navigation.navigationHistory
  ); // Navigation History
  const editProfileTab = useSelector((state) => state.user.editProfileTab); // Edit Profile Page
  const selectedGameUrl = useSelector((state) => state.game.selectedGameUrl); // Casino Games Page
  const dispatch = useDispatch();

  // MUI variables
  const [drawerState, setDrawerState] = useState({
    left: false,
  });
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t, i18n } = useTranslation();

  // Router variables
  const location = useLocation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!isLoggedIn) return;

    // getUserAio(null, true);
    // getProfile(null, true);
  }, [isLoggedIn]);

  // Lifecycle | Check for update | Change navbar items based on route
  // isFiltered check for Search page only
  // leaderboardTitle used in leaderboard list page only
  // selectedGameUrl used in casino games page only
  useEffect(() => {
    if (isLoggedIn) {
    } else {
      setMobileLeftButton(<div></div>);
      setMobileMiddleSection(<div></div>);
      setMobileRightButton(<div></div>);
    }
  }, [
    isLoggedIn,
    location,
    getUserAioFetching,
    getUserAioSuccess,
    isFiltered,
    conversationUser,
    leaderboardTitle,
    editProfileTab,
    selectedGameUrl,
  ]);

  // Lifecycle | Check for update | Hide navbar based on route & screen width
  useEffect(() => {
    setShowNavbar(true);
    if (
      location.pathname === routeConst.landing.path ||
      location.pathname.includes(routeConst.livestream.simulateLivestream.path)
    ) {
      if (isMobile) {
        setShowNavbar(false);
      } else {
        setShowNavbar(true);
      }
    } else {
      setShowNavbar(true);
    }
  }, [location, isMobile]);

  // Event Handlers | Button
  const onSaveVideoCallSettings = () => {
    dispatch(updateSaveVideoCallSettingsPassthrough({}));
  };
  const onEditProfile = () => {
    dispatch(updateEditProfilePassthrough({}));
  };
  const onOpenUserProfileFromConversation = () => {
    dispatch(updateConversationUserProfilePassthrough({}));
  };
  const onVideoCall = () => {
    dispatch(updateVideoCallPassthrough({}));
  };
  const onOpenVerifiedCallerDialog = () => {
    dispatch(updateVerifiedCallerDescriptionDialog(true));
  };
  const onBackToGameSelect = () => {
    dispatch(updateGameListPassthrough());
  };
  const onRedirect = (currentPage, route) => {
    mobileScrollToTop(currentPage);

    // For wallet page, there is a unique condition where it might redirect to Xenopay, and the navigation history contains website from xenopay and iPay88.
    // Because of this, we will have to redirect user to fixed location.
    if (currentPage === routeConst.profile.wallet.path) {
      if (route === -1) {
        if (isMobile) {
          onNavigate(routeConst.profile.overview.path);
        } else {
          onNavigate(routeConst.search.path);
        }
        return;
      }
    }

    if (route === -1 && navigationHistory?.length > 0) {
      onNavigate(route);
    } else {
      onNavigate(routeConst.search.path);
    }
  };
  const onRedirectToCasinoLeaderboard = () => {
    dispatch(updateLeaderboardTab(2));

    onNavigate(routeConst.leaderboard.path);
  };

  // Event Handlers | MUI Menu
  const toggleDrawer = (anchor, open) => (event) => {
    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const onLandingMenu = (index) => {
    switch (index) {
      case 0:
        onNavigate(routeConst.sugarDating.path);
        break;
      case 2:
        onNavigate(routeConst.sugarBaby.path);
        break;
      case 3:
        onNavigate(routeConst.sugarDaddy.path);
        break;
      case 4:
        break;
    }
  };

  // Event Handlers | MUI Dialogs
  const onOpenDialog = (state) => {
    switch (state) {
      case "search-filter-dialog":
        dispatch(updateSearchFilterDialog(true));
        break;
      case "my-wallet-dialog":
        dispatch(updateMyWalletInfoDialog(true));
        break;
      case "diamonds-info-dialog":
        dispatch(updateDiamondsInfoDialog(true));
        break;
      default:
        break;
    }
  };

  // Utility Functions
  const mobileScrollToTop = (currentPage) => {
    // Used in mobile page, to scroll to top when clicking on same navigation page
    switch (currentPage) {
      case routeConst.search.path:
        dispatch(updateSearchScrollToTopPassthrough({}));
        break;
      case routeConst.inbox.path:
        dispatch(updateInboxScrollToTopPassthrough({}));
        break;
      case routeConst.videoCall.path:
        dispatch(updateVideoCallScrollToTopPassthrough({}));
        break;
      case routeConst.live.path:
        dispatch(updateLivestreamScrollToTopPassthrough({}));
        break;
      case routeConst.interest.path:
        dispatch(updateInterestScrollToTopPassthrough({}));
        break;
      case routeConst.profile.overview.path:
        break;
      default:
        break;
    }
  };

  // Render Functions | MUI Drawer
  const list = (anchor) => (
    <Box
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {[
          t("landing.drawer.how_it_works"),
          t("landing.drawer.what_it_means_to_be_a"),
          t("landing.drawer.sugar_baby"),
          t("landing.drawer.sugar_daddy"),
          t("landing.drawer.events"),
        ].map((text, index) => (
          <div key={index}>
            <ListItem key={index} disablePadding>
              <ListItemButton onClick={() => onLandingMenu(index)}>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
            <Divider />
          </div>
        ))}
      </List>
    </Box>
  );

  // Render Functions
  if (showNavbar) {
    return (
      <div id="navbar-navbar-mobile-view-subcomponent">
        <div className="space-around">
          <div className="mobile-view">
            <div className="left-container">{mobileLeftButton}</div>
            <div className="middle-container">{mobileMiddleSection}</div>
            <div className="right-container">{mobileRightButton}</div>

            <Drawer
              anchor="left"
              open={drawerState["left"]}
              onClose={toggleDrawer("left", false)}
            >
              {list("left")}
            </Drawer>
          </div>
        </div>
      </div>
    );
  }
};

export default NavbarMobileView;
