// General
import "./global-dialog-mounted.scss";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
// Services
import { sessionService } from "../../../../services/session.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
// react-device-detect
import { deviceDetect, isIOS, isAndroid } from "react-device-detect";
// Components
import AddUserDialog from "../../../shared/dialog-content/add-user-dialog/add-user-dialog";
import UserMiniProfileDialog from "../../../shared/dialog-content/user-mini-profile-dialog/user-mini-profile-dialog";

const GlobalDialogMounted = () => {
  // Render variables
  const [dialogView, setDialogView] = useState(null);

  // Redux variables
  const isLoggedIn = useSelector((state) => state.public.isLoggedIn);
  const dispatch = useDispatch();

  // Router variables
  const location = useLocation();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!isLoggedIn) return;

    if (isIOS || isAndroid) {
      // Check if the app is installed as PWA
      if (!window.matchMedia("(display-mode: standalone)").matches) {
      }
    }
  }, [isLoggedIn]);

  return (
    <div id="global-dialog-mounted">
      <AddUserDialog />
      <UserMiniProfileDialog />
    </div>
  );
};

export default GlobalDialogMounted;
