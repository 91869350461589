const userCardConst = {
  cardType: {
    default: "default",
    user: "user",
    gaming: "gaming",
    livestreaming: "live_streaming",
    coAnchor: "co_anchor",
    privateCallStandby: "private_calls_standby",
  },
  userProfileStatus: {
    normal: "normal",
    live: "live",
    inPrivateBusy: "in-private-busy",
    inPrivateAvailable: "in-private-available",
    videoCall: "video-call",
  },
  routeTo: { profileView: "profile-view", videoCall: "video-call" },
  cardStatus: {
    default: "default",
    live: "live",
    videoCall: "video-call",
  },
};

export default userCardConst;
