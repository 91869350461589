import IcCart from "../assets/icon/static/shared/ic-cart.svg";
import IcMusic from "../assets/icon/static/shared/ic-music.svg";

const utilityConst = {
  drawerWidth: 280,



  authMethod: {
    emailPassword: "email_password",
    phonePassword: "phone_password",
    facebook: "facebook",
    facebookSandbox: "facebook_sandbox",
  },
  releaseChannel: {
    stable: "stable",
    beta: "beta",
    nightly: "nightly",
  },
  language: {
    system: "system",
    en: "en",
    zhTw: "zh-tw",
    jaJp: "ja-jp",
  },
  sugarbookTheme: {
    default: "default",
    christmas: "christmas",
    anniversary: "anniversary",
  },
  mobileNavbarTheme: {
    default: "default",
    octopus: "octopus",
  },
  iconTheme: {
    default: "default",
    material: "material",
  },
  darkTheme: {
    oled: "oled",
    grey: "grey",
  },
  screenOrientation: {
    portrait: "portrait",
    landscape: "landscape",
  },
  fillingDetails: {
    signup: { name: "Signup", type: "signup" },
    missingDetails: { name: "Missing Details", type: "missing-details" },
  },
  supportContactList: [
    {
      value: "settings.support_form.profile_approval",
      label: "Profile Approval",
    },
    { value: "settings.support_form.payment", label: "Payment" },
    { value: "settings.support_form.messaging", label: "Messaging" },
    {
      value: "settings.support_form.searchingfiltering",
      label: "Searching/Filtering",
    },
    {
      value: "settings.support_form.suggestionfeedback",
      label: "Suggestion/Feedback",
    },
    { value: "settings.support_form.website_issue", label: "Website Issue" },
  ],
  walletInfoList: [
    {
      icon: IcCart,
      title: "profile.wallet_info_1_title",
      description: "profile.wallet_info_1_desc",
    },
    {
      icon: IcMusic,
      title: "profile.wallet_info_2_title",
      description: "profile.wallet_info_2_desc",
    },
  ],
  editProfileMode: {
    mobile: "mobile",
    desktop: "desktop",
  },
  sugarbookVerifiedStatus: {
    approved: "approved",
    rejected: "rejected",
    duplicate: "duplicate",
  },
};

export default utilityConst;
