// General
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cardBorderEnabled: true,
  cardBorderImage: null,

  cardBorderGradient: {
    one: "#ffa927",
    two: "#ff409c",
    three: "#9534b4",
  },
};

export const cardBorderSlice = createSlice({
  name: "cardBorder",
  initialState,
  reducers: {
    updateCardBorderEnabled: (state, action) => {
      state.cardBorderEnabled = action.payload;
    },
    updateCardBorderImage: (state, action) => {
      state.cardBorderImage = action.payload;
    },

    updateCardBorderGradientOne: (state, action) => {
      state.cardBorderGradient.one = action.payload;
    },
    updateCardBorderGradientTwo: (state, action) => {
      state.cardBorderGradient.two = action.payload;
    },
    updateCardBorderGradientThree: (state, action) => {
      state.cardBorderGradient.three = action.payload;
    },

    // Utility Functions
    resetCardBorderStore: () => {
      return { ...initialState };
    },
  },
});

export const {
  updateCardBorderEnabled,
  updateCardBorderImage,

  updateCardBorderGradientOne,
  updateCardBorderGradientTwo,
  updateCardBorderGradientThree,

  // Utility Functions
  resetCardBorderStore,
} = cardBorderSlice.actions;

export default cardBorderSlice.reducer;
