// General
import "./live-viewer.scss";
import { useEffect } from "react";
// Services
import {
  useLazyGetProfileQuery,
  usePostFollowUserMutation,
  usePostUnfollowUserMutation,
} from "../../../../../../../services/data.service";
// Static Data
import routeConst from "../../../../../../../const/routeConst";
// Redux
import { useDispatch } from "react-redux";
import {
  updateLiveViewerByIndex,
  updateTopFansDailyByIndex,
} from "../../../../../../../redux/store/livestreamingStore";
import {
  // User View
  updateId,
  updateIdInt,
  updateUsername,
  updateAge,
  updateLocation,
  updateMembershipType,
  updateVerifiedProfile,
  updateVerifiedCaller,
  updateLevellingBadge,
  updateLivestreamingAchievementBadge,
  updateProfilePhoto,
  updatePublicPhotos,
  updatePrivatePhotos,
  updateTorTags,
  updateProfileAchievementBadge,
  updateAboutMe,
  updateLookingFor,
  updateOccupation,
  updateLifestyle,
  updateEducation,
  updateRelationship,
  updateSmoking,
  updateDrinking,
  updateHeight,
  updateBodyType,
  updateEthnicity,
  updateEyeColor,
  updateHairColor,
  updateChildren,
  // User Utility
  updateShouldCallApi,
  updatePrivatePhotoRequested,
  updatePrivatePhotoApproval,
  updateRequestedMyPrivatePhoto,
  updateApprovedMyPrivatePhoto,
  updateIsBlocked,
  updateIsSilentInteraction,
  updateIsFavorited,
  updateIsCallEnabled,
  updateIsSugarbaby,
  updateRole,
} from "../../../../../../../redux/store/userCardStore";
import { updateUserProfileDialog } from "../../../../../../../redux/store/dialogStore";
import { updateErrorToast } from "../../../../../../../redux/store/toastStore";
// Material UI
import { CircularProgress } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../../../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import CustomAvatar from "../../../../../elements/custom-avatar/custom-avatar";

const LiveViewer = (props) => {
  const {
    index,
    // User View
    id,
    idInt,
    username,
    age,
    location,
    membershipType,
    verifiedProfile,
    verifiedCaller,
    levellingBadge,
    livestreamingAchievementBadge,
    profilePhoto,
    publicPhotos,
    privatePhotos,
    torTags,
    profileAchievementBadge,
    aboutMe,
    lookingFor,
    occupation,
    lifestyle,
    education,
    relationship,
    smoking,
    drinking,
    height,
    bodyType,
    ethnicity,
    eyeColor,
    hairColor,
    children,
    isFollowing,
    // User Utility
    shouldCallApi,
    privatePhotoRequested,
    privatePhotoApproval,
    requestedMyPrivatePhoto,
    approvedMyPrivatePhoto,
    isBlocked,
    isSilentInteraction,
    isFavorited,
    isCallEnabled,
    isSugarbaby,
    role,
  } = props;

  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    postFollowUser,
    {
      data: postFollowUserData,
      error: postFollowUserErrorData,
      isLoading: postFollowUserLoading,
      isSuccess: postFollowUserSuccess,
      isError: postFollowUserError,
    },
  ] = usePostFollowUserMutation();
  const [
    postUnfollowUser,
    {
      data: postUnfollowUserData,
      error: postUnfollowUserErrorData,
      isLoading: postUnfollowUserLoading,
      isSuccess: postUnfollowUserSuccess,
      isError: postUnfollowUserError,
    },
  ] = usePostUnfollowUserMutation();

  // Redux variables
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
  }, []);

  // Lifecycle | Check for update | Follow user API Response
  useEffect(() => {
    if (postFollowUserLoading) {
    } else if (postFollowUserSuccess) {
      switch (postFollowUserData?.status) {
        case 1:
          const obj = {
            id,
            isFollowing: true,
            index: index,
          };
          dispatch(updateLiveViewerByIndex(obj));
          dispatch(updateTopFansDailyByIndex(obj));
          break;
        case -1:
          // Already following user
          const toastObj = {
            message: postFollowUserData?.message,
            autoClose: 3000,
          };
          dispatch(updateErrorToast(toastObj));
          break;
        default:
          break;
      }
    } else if (postFollowUserError) {
      if (postFollowUserErrorData?.status === 401) {
        // onNavigate(routeConst.logout.path);
      }
    }
  }, [postFollowUserLoading, postFollowUserSuccess, postFollowUserError]);

  // Lifecycle | Check for update | Unfollow user API Response
  useEffect(() => {
    if (postUnfollowUserLoading) {
    } else if (postUnfollowUserSuccess) {
      switch (postUnfollowUserData?.status) {
        case 1:
          const obj = {
            id,
            isFollowing: false,
            index: index,
          };
          dispatch(updateLiveViewerByIndex(obj));
          dispatch(updateTopFansDailyByIndex(obj));
          break;
        case -1:
          // Already unfollowing user
          const toastObj = {
            message: postUnfollowUserData?.message,
            autoClose: 3000,
          };
          dispatch(updateErrorToast(toastObj));
          break;
        default:
          break;
      }
      if (postUnfollowUserData?.status === 1) {
        const obj = {
          isFollowing: false,
          index: index,
        };
        dispatch(updateLiveViewerByIndex(obj));
      }
    } else if (postUnfollowUserError) {
      if (postUnfollowUserErrorData?.status === 401) {
        // onNavigate(routeConst.logout.path);
      }
    }
  }, [postUnfollowUserLoading, postUnfollowUserSuccess, postUnfollowUserError]);

  // Event Handlers | Button
  const onViewProfile = () => {
    // User View
    dispatch(updateId(id));
    dispatch(updateIdInt(idInt));
    dispatch(updateUsername(username));
    dispatch(updateAge(age));
    dispatch(updateLocation(location));
    dispatch(updateMembershipType(membershipType));
    dispatch(updateVerifiedProfile(verifiedProfile));
    dispatch(updateVerifiedCaller(verifiedCaller));
    dispatch(updateLevellingBadge(levellingBadge));
    dispatch(
      updateLivestreamingAchievementBadge(livestreamingAchievementBadge)
    );
    dispatch(updateProfilePhoto(profilePhoto));
    dispatch(updatePublicPhotos(publicPhotos));
    dispatch(updatePrivatePhotos(privatePhotos));
    dispatch(updateTorTags(torTags));
    dispatch(updateProfileAchievementBadge(profileAchievementBadge));
    dispatch(updateAboutMe(aboutMe));
    dispatch(updateLookingFor(lookingFor));
    dispatch(updateOccupation(occupation));
    dispatch(updateLifestyle(lifestyle));
    dispatch(updateEducation(education));
    dispatch(updateRelationship(relationship));
    dispatch(updateSmoking(smoking));
    dispatch(updateDrinking(drinking));
    dispatch(updateHeight(height));
    dispatch(updateBodyType(bodyType));
    dispatch(updateEthnicity(ethnicity));
    dispatch(updateEyeColor(eyeColor));
    dispatch(updateHairColor(hairColor));
    dispatch(updateChildren(children));
    // User Utility
    dispatch(updateShouldCallApi(shouldCallApi));
    dispatch(updatePrivatePhotoRequested(privatePhotoRequested));
    dispatch(updatePrivatePhotoApproval(privatePhotoApproval));
    dispatch(updateRequestedMyPrivatePhoto(requestedMyPrivatePhoto));
    dispatch(updateApprovedMyPrivatePhoto(approvedMyPrivatePhoto));
    dispatch(updateIsBlocked(isBlocked ? true : false));
    dispatch(updateIsSilentInteraction(isSilentInteraction));
    dispatch(updateIsFavorited(isFavorited));
    dispatch(updateIsCallEnabled(isCallEnabled));
    dispatch(updateIsSugarbaby(isSugarbaby));
    dispatch(updateRole(role));

    dispatch(updateUserProfileDialog(true));
  };
  const onFollowUser = () => {
    const obj = {
      user_id: id,
    };

    postFollowUser(obj);
  };
  const onUnfollowUser = () => {
    const obj = {
      user_id: id,
    };

    postUnfollowUser(obj);
  };

  // Render Functions
  const renderFollowButton = () => {
    if (getProfileData?.data?.id !== id) {
      if (isFollowing) {
        return (
          <div className="unfollow-button" onClick={onUnfollowUser}>
            {postUnfollowUserLoading ? (
              <CircularProgress className="button-spinner-white" size={12} />
            ) : (
              t("common.unfollow")
            )}
          </div>
        );
      } else {
        return (
          <div className="follow-button" onClick={onFollowUser}>
            {postFollowUserLoading ? (
              <CircularProgress className="button-spinner-red" size={12} />
            ) : (
              t("common.follow")
            )}
          </div>
        );
      }
    }
  };

  return (
    <div id="live-tab-live-viewer-subcomponent">
      <CustomAvatar
        className="profile-photo"
        src={profilePhoto}
        role={role}
        onClick={onViewProfile}
      />

      <div className="user-description-container">
        {livestreamingAchievementBadge?.length > 0 && (
          <div className="livestreaming-achievement-badge-container">
            {livestreamingAchievementBadge?.map((badge, index) => (
              <img
                className="livestreaming-achievement-badge"
                src={badge?.url}
                alt={badge?.type}
                key={index}
              />
            ))}
          </div>
        )}

        <div className="username">{username}</div>

        {levellingBadge && (
          <div className="levelling-badge-container">
            <img className="levelling-badge" src={levellingBadge} />
          </div>
        )}
      </div>

      <span className="spacer"></span>

      {renderFollowButton()}
    </div>
  );
};

export default LiveViewer;
