// General
import "./livestream-live-viewer.scss";
// Material UI
import { CircularProgress } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Components
import CustomAvatar from "../../../../shared/elements/custom-avatar/custom-avatar";

const LivestreamLiveViewer = (props) => {
  const {
    profilePhoto,
    role,
    livestreamingAchievementBadge,
    username,
    levellingBadge,
    // Utility
    profileBorder,
  } = props;

  // i18next variables
  const { t } = useTranslation();

  // Event Handlers | Button
  const onUnfollowUser = () => {};

  return (
    <div id="live-tab-live-viewer-subcomponent">
      <CustomAvatar
        className="profile-photo"
        src={profilePhoto}
        role={role}
        profileBorder={profileBorder}
        // onClick={onViewProfile}
      />

      <div className="user-description-container">
        {livestreamingAchievementBadge?.length > 0 && (
          <div className="livestreaming-achievement-badge-container">
            {livestreamingAchievementBadge?.map((badge, index) => (
              <img
                className="livestreaming-achievement-badge"
                src={badge?.url}
                alt={badge?.type}
                key={index}
              />
            ))}
          </div>
        )}

        <div className="username">{username}</div>

        {levellingBadge && (
          <div className="levelling-badge-container">
            <img className="levelling-badge" src={levellingBadge} />
          </div>
        )}
      </div>

      <span className="spacer"></span>

      <div className="unfollow-button" onClick={onUnfollowUser}>
        {false ? (
          <CircularProgress className="button-spinner-white" size={12} />
        ) : (
          t("common.unfollow")
        )}
      </div>
    </div>
  );
};

export default LivestreamLiveViewer;
