// General
import "./index.scss";
import React from "react";
import ReactDOM from "react-dom/client";
// Environment
import environment from "./environment/environment";
import router from "./router";
// Static Data
import { APP_IDENTIFIER } from "./const/apiConst";
// React Router DOM
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
  ScrollRestoration,
  RouterProvider,
} from "react-router-dom";
// Service Worker
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
// Redux
import { Provider } from "react-redux";
import store from "./redux/store";
// Material UI
import { ThemeProvider, createTheme } from "@mui/material/styles";
// i18next
import "./i18n"; // import i18n (needs to be bundled ;))
// Swiper
import { register } from "swiper/element/bundle";
// Web Vitals
import reportWebVitals from "./reportWebVitals";

// Material UI | Global Theme
const theme = createTheme({
  typography: {
    fontFamily: "Lato",
  },
});

// Initialize Swiper
register();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <RouterProvider router={router}>
        <ScrollRestoration
          getKey={(location, matches) => {
            // default behavior
            return location.pathname;
          }}
        />
      </RouterProvider>
    </ThemeProvider>
  </Provider>
  // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
