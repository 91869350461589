// General
import "./chat-element.scss";
// Static Data
import livestreamingConst from "../../../../../const/livestreamingConst";
// Components
import CustomAvatar from "../../custom-avatar/custom-avatar";

const ChatElement = (props) => {
  const {
    livestreamingAchievementBadge,
    profilePhoto,
    username,
    levellingBadge,
    message,

    // Style
    usernameFont = livestreamingConst.config.chatElement.font,
    usernameColor = livestreamingConst.config.chatElement.desktopFontColor,
    chatFont = livestreamingConst.config.chatElement.font,
    chatColor = livestreamingConst.config.chatElement.desktopFontColor,
    backgroundColor = livestreamingConst.config.chatElement.backgroundColor,

    // Testing Style
    border = "none",
    borderRadius = "0px",
    textStroke = "unset",
    textShadow = "none",

    // Utility
    profileBorder,
  } = props;

  return (
    <div
      id="chat-section-chat-element-subcomponent"
      style={{
        border: border,
        borderRadius: borderRadius,
        WebkitTextStroke: textStroke,
        textShadow: textShadow,
        background: backgroundColor,
      }}
    >
      <div className="profile-picture-container">
        <CustomAvatar
          className="profile-picture"
          src={profilePhoto}
          profileBorder={profileBorder}
        />
      </div>

      <div className="chat-info-container">
        <div className="user-info-container">
          {livestreamingAchievementBadge?.length > 0 && (
            <div className="livestreaming-achievement-badge-container">
              {livestreamingAchievementBadge?.map((badge, index) => (
                <img
                  className="livestreaming-achievement-badge"
                  src={badge?.url}
                  alt={badge?.type}
                  key={index}
                />
              ))}
            </div>
          )}

          <div
            className="username"
            style={{ font: usernameFont, color: usernameColor }}
          >
            {username || "-"}
          </div>

          {levellingBadge && (
            <div className="levelling-badge-container">
              <img
                className="levelling-badge"
                src={levellingBadge}
                alt="levelling-badge"
              />
            </div>
          )}
        </div>

        <div className="chat" style={{ font: chatFont, color: chatColor }}>
          {message}
        </div>
      </div>
    </div>
  );
};

export default ChatElement;
