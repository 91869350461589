// General
import "./tablet-right-section.scss";
// Material UI
import { Divider, useMediaQuery } from "@mui/material";
// Redux
import { useSelector } from "react-redux";
// Components
import UserInfo from "../../../shared/dialog-content/livestream-profile-view-dialog/user-info/user-info";
import ViewerInfo from "../../../shared/dialog-content/livestream-profile-view-dialog/viewer-info/viewer-info";
import ChatSection from "../../../shared/elements/chat-section/chat-section";
import ChatInput from "../interactable-overlay/chat-input/chat-input";
import CasinoGames from "../../../games/casino-games/casino-games";

const TabletRightSection = () => {
  // Redux variables
  const contestant1 = useSelector((state) => state.livestreaming.contestant1);
  const contestant2 = useSelector((state) => state.livestreaming.contestant2);
  const isGameOpen = useSelector((state) => state.game.isGameOpen);

  // MUI variables
  const isDesktop = useMediaQuery("(min-width: 1305px)");
  const isTablet = useMediaQuery("(min-width: 900px)");
  const isMobile = useMediaQuery("(max-width: 900px)");

  if (!isMobile) {
    return (
      <div
        id="livestream-tablet-right-section-subcomponent"
        className={isTablet && !isDesktop ? "half-width" : "third-width"}
      >
        {/* PK & Livestream Info */}
        <div
          className={`top-container ${
            !isDesktop ? "top-container-half-height" : "top-container-margin"
          }`}
        >
          {isTablet && !isDesktop && (
            <div className={`lobby-container`}>
              <div className="lobby-label">Lobby</div>

              <Divider />

              <UserInfo />

              <Divider />

              <ViewerInfo state={"standalone"} />
            </div>
          )}
        </div>

        {/* Chat */}
        <div
          className={`chat-container ${
            isDesktop && contestant1 && contestant2 ? "minus-pk" : ""
          } ${isDesktop && isGameOpen ? "minus-game" : ""} ${
            isDesktop && isGameOpen && contestant1 && contestant2
              ? "minus-pk-and-game"
              : ""
          }`}
        >
          <div className="interaction-label">Interaction</div>

          <Divider />

          <ChatSection state={"livestream"} />

          <ChatInput />
        </div>

        {false && isDesktop && (
          <div
            className={`game-outlet-container ${
              isGameOpen ? "" : "hide-game-container"
            }`}
          >
            <div className="game-iframe-container">
              <CasinoGames
                showHeader={true}
                headerType={"desktop"}
                showRightArrow={true}
                isDialog={true}
                applyRadius={true}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
};

export default TabletRightSection;
