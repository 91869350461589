// General
import "./conversation-editor-test-element.scss";
import { useState } from "react";
// Static Data
import livestreamingConst from "../../../../../const/livestreamingConst";
// Material UI
import {
  Switch,
  FormControlLabel,
  TextField,
  MenuItem,
  Slider,
  InputAdornment,
  useMediaQuery,
} from "@mui/material";
// Components
import ChatElement from "../../../elements/chat-section/chat-element/chat-element";
import GiftElement from "../../../elements/chat-section/gift-element/gift-element";
import JoiningElement from "../../../elements/chat-section/joining-element/joining-element";
import FollowingElement from "../../../elements/chat-section/following-element/following-element";
import EmojiElement from "../../../elements/chat-section/emoji-element/emoji-element";

const ConversationEditorTestElement = () => {
  // General variables
  const [username, setUsername] = useState("KingDaddy");
  const [livestreamerUsername, setLivestreamerUsername] = useState("BabyA");
  const [conversation, setConversation] = useState("Hello there");
  const [fontSize, setFontSize] = useState("");
  const [fontWeight, setFontWeight] = useState("");
  const [border, setBorder] = useState(false);
  const [borderWidth, setBorderWidth] = useState(1);
  const [borderStyle, setBorderStyle] = useState("solid");
  const [borderColor, setBorderColor] = useState("#000000");
  const [borderRadius, setBorderRadius] = useState(0);
  const [textStroke, setTextStroke] = useState(false);
  const [textStrokeWidth, setTextStrokeWidth] = useState(1);
  const [textStrokeColor, setTextStrokeColor] = useState("#000000");
  const [textShadow, setTextShadow] = useState(false);
  const [textShadowShiftRight, setTextShadowShiftRight] = useState(2);
  const [textShadowShiftDown, setTextShadowShiftDown] = useState(2);
  const [textShadowBlur, setTextShadowBlur] = useState(2);
  const [textShadowOpacity, setTextShadowOpacity] = useState(1);
  const [textShadowColor, setTextShadowColor] = useState("#000000");
  const [textColor, setTextColor] = useState("#000000");
  const [backgroundColor, setBackgroundColor] = useState("none");

  // Constant variables
  const fontSizeData = [
    "",
    0.5,
    0.6,
    0.7,
    0.8,
    0.9,
    1.0,
    1.1,
    1.2,
    1.3,
    1.4,
    1.5,
    1.6,
    1.7,
    1.8,
    1.9,
    2.0,
  ];
  const fontWeightData = [
    "",
    "normal",
    "bold",
    "bolder",
    "lighter",
    "100",
    "200",
    "300",
    "400",
    "500",
    "600",
    "700",
    "800",
    "900",
  ];
  const borderWidthData = ["1", "2", "3", "4", "5"];
  const borderStyleData = [
    "solid",
    "dashed",
    "dotted",
    "double",
    "groove",
    "ridge",
    "inset",
    "outset",
  ];
  const borderRadiusData = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
  ];
  const textStrokeWidthData = ["1", "2", "3", "4", "5"];

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 900px)");

  // Event Handlers | Button
  const onResetConversation = () => {
    setUsername("KingDaddy");
    setLivestreamerUsername("BabyA");
    setConversation("Hello there");
    setFontSize("");
    setFontWeight("");
    setBorder(false);
    setBorderWidth(1);
    setBorderStyle("solid");
    setBorderColor("#000000");
    setBorderRadius(0);
    setTextStroke(false);
    setTextStrokeWidth(1);
    setTextStrokeColor("#000000");
    setTextShadow(false);
    setTextShadowShiftRight(2);
    setTextShadowShiftDown(2);
    setTextShadowBlur(2);
    setTextShadowOpacity(1);
    setTextShadowColor("#000000");
    setTextColor("#000000");
    setBackgroundColor("none");
  };

  // Event Handlers | Text Field
  const onInputChange = (event, type) => {
    switch (type) {
      case "username":
        setUsername(event.target.value);
        break;
      case "livestreamer-username":
        setLivestreamerUsername(event.target.value);
        break;
      case "conversation":
        setConversation(event.target.value);
        break;
      case "font-size":
        setFontSize(event.target.value);
        break;
      case "font-weight":
        setFontWeight(event.target.value);
        break;
      case "border":
        setBorder(event.target.checked);
        break;
      case "border-style":
        setBorderStyle(event.target.value);
        break;
      case "border-width":
        setBorderWidth(event.target.value);
        break;
      case "border-color":
        setBorderColor(event.target.value);
        break;
      case "border-radius":
        setBorderRadius(event.target.value);
        break;
      case "text-stroke":
        setTextStroke(event.target.checked);
        break;
      case "text-stroke-width":
        setTextStrokeWidth(event.target.value);
        break;
      case "text-stroke-color":
        setTextStrokeColor(event.target.value);
        break;
      case "text-shadow":
        setTextShadow(event.target.checked);
        break;
      case "text-shadow-shift-right":
        setTextShadowShiftRight(event.target.value);
        break;
      case "text-shadow-shift-down":
        setTextShadowShiftDown(event.target.value);
        break;
      case "text-shadow-blur":
        setTextShadowBlur(event.target.value);
        break;
      case "text-shadow-opacity":
        setTextShadowOpacity(event.target.value);
        break;
      case "text-shadow-color":
        setTextShadowColor(event.target.value);
        break;
      case "text-color":
        setTextColor(event.target.value);
        break;
      case "background-color":
        setBackgroundColor(event.target.value);
        break;
      default:
        break;
    }
  };

  return (
    <div id="conversation-editor-test-element">
      <div className="conversation-editor-preview-container">
        <div className="preview-label">Preview</div>

        <div className="conversation-editor-preview">
          <div className="chatting-preview-container">
            <div className="sub-preview-label">Chatting</div>

            <ChatElement
              state={"livestream"}
              // livestreamingAchievementBadge={message?.chat_badges_set}
              // profilePhoto={message?.user_photo}
              username={username}
              levellingBadge={
                "https://image.sgrbk.com/assets/levelling/badges/v1/level_27.png"
              }
              message={conversation}
              // Style
              usernameFont={
                fontSize && fontWeight
                  ? `${fontWeight} ${fontSize}em Lato`
                  : livestreamingConst.config.chatElement.font
              }
              usernameColor={
                textColor === "#000000"
                  ? !isMobile
                    ? livestreamingConst.config.chatElement.desktopFontColor
                    : livestreamingConst.config.chatElement.mobileFontColor
                  : textColor
              }
              chatFont={
                fontSize && fontWeight
                  ? `${fontWeight} ${fontSize}em Lato`
                  : livestreamingConst.config.chatElement.font
              }
              chatColor={
                textColor === "#000000"
                  ? !isMobile
                    ? livestreamingConst.config.chatElement.desktopFontColor
                    : livestreamingConst.config.chatElement.mobileFontColor
                  : textColor
              }
              backgroundColor={
                backgroundColor === "none"
                  ? livestreamingConst.config.chatElement.backgroundColor
                  : backgroundColor
              }
              // Testing Style
              border={
                border
                  ? `${borderWidth}px ${borderStyle} ${borderColor}`
                  : "none"
              }
              borderRadius={borderRadius}
              textStroke={
                textStroke ? `${textStrokeWidth}px ${textStrokeColor}` : "unset"
              }
              textShadow={
                textShadow
                  ? `${textShadowShiftRight}px ${textShadowShiftDown}px ${textShadowBlur}px ${textShadowColor}`
                  : "none"
              }
            />
          </div>

          <div className="gifting-preview-container">
            <div className="sub-preview-label">Gifting</div>

            <GiftElement
              state={"livestream"}
              // livestreamingAchievementBadge={message?.chat_badges_set}
              // profilePhoto={message?.user_photo}
              username={username}
              levellingBadge={
                "https://image.sgrbk.com/assets/levelling/badges/v1/level_27.png"
              }
              giftName={"Money Rain"}
              giftImage={
                "https://image.sgrbk.com/artwork/livestream/gifts/png/gift16-v1.0.png"
              }
              // Style
              backgroundColor={backgroundColor}
              font={
                fontSize && fontWeight
                  ? `${fontWeight} ${fontSize}em Lato`
                  : livestreamingConst.config.giftElement.font
              }
              textColor={
                textColor === "#000000"
                  ? !isMobile
                    ? livestreamingConst.config.giftElement.desktopFontColor
                    : livestreamingConst.config.giftElement.mobileFontColor
                  : textColor
              }
              // Testing Style
              border={
                border
                  ? `${borderWidth}px ${borderStyle} ${borderColor}`
                  : "none"
              }
              borderRadius={borderRadius}
              textStroke={
                textStroke ? `${textStrokeWidth}px ${textStrokeColor}` : "unset"
              }
              textShadow={
                textShadow
                  ? `${textShadowShiftRight}px ${textShadowShiftDown}px ${textShadowBlur}px ${textShadowColor}`
                  : "none"
              }
            />
          </div>

          <div className="joining-preview-container">
            <div className="sub-preview-label">Joining</div>

            <JoiningElement
              state={"livestream"}
              // livestreamingAchievementBadge={message?.chat_badges_set}
              // profilePhoto={message?.user_photo}
              username={username}
              levellingBadge={
                "https://image.sgrbk.com/assets/levelling/badges/v1/level_27.png"
              }
              // Style
              font={
                fontSize && fontWeight
                  ? `${fontWeight} ${fontSize}em Lato`
                  : livestreamingConst.config.joiningElement.font
              }
              textColor={
                textColor === "#000000"
                  ? !isMobile
                    ? livestreamingConst.config.joiningElement.desktopFontColor
                    : livestreamingConst.config.joiningElement.mobileFontColor
                  : textColor
              }
              backgroundColor={
                backgroundColor === "none"
                  ? livestreamingConst.config.joiningElement.backgroundColor
                  : backgroundColor
              }
              // Testing Style
              border={
                border
                  ? `${borderWidth}px ${borderStyle} ${borderColor}`
                  : "none"
              }
              borderRadius={borderRadius}
              textStroke={
                textStroke ? `${textStrokeWidth}px ${textStrokeColor}` : "unset"
              }
              textShadow={
                textShadow
                  ? `${textShadowShiftRight}px ${textShadowShiftDown}px ${textShadowBlur}px ${textShadowColor}`
                  : "none"
              }
            />
          </div>

          <div className="following-preview-container">
            <div className="sub-preview-label">Following</div>

            <FollowingElement
              // livestreamingAchievementBadge={message?.chat_badges_set}
              // profilePhoto={message?.user_photo}
              username={username}
              levellingBadge={
                "https://image.sgrbk.com/assets/levelling/badges/v1/level_27.png"
              }
              livestreamerUsername={livestreamerUsername}
              // Style
              font={
                fontSize && fontWeight
                  ? `${fontWeight} ${fontSize}em Lato`
                  : livestreamingConst.config.followingElement.font
              }
              textColor={
                textColor === "#000000"
                  ? !isMobile
                    ? livestreamingConst.config.followingElement
                        .desktopFontColor
                    : livestreamingConst.config.followingElement.mobileFontColor
                  : textColor
              }
              backgroundColor={
                backgroundColor === "none"
                  ? livestreamingConst.config.followingElement.backgroundColor
                  : backgroundColor
              }
              // Testing Style
              border={
                border
                  ? `${borderWidth}px ${borderStyle} ${borderColor}`
                  : "none"
              }
              borderRadius={borderRadius}
              textStroke={
                textStroke ? `${textStrokeWidth}px ${textStrokeColor}` : "unset"
              }
              textShadow={
                textShadow
                  ? `${textShadowShiftRight}px ${textShadowShiftDown}px ${textShadowBlur}px ${textShadowColor}`
                  : "none"
              }
            />
          </div>

          <div className="emoji-preview-container">
            <div className="sub-preview-label">Emoji</div>

            <EmojiElement
              // livestreamingAchievementBadge={message?.chat_badges_set}
              // profilePhoto={message?.user_photo}
              username={username}
              levellingBadge={
                "https://image.sgrbk.com/assets/levelling/badges/v1/level_27.png"
              }
              emoji={"😘"}
              // Style
              font={
                fontSize && fontWeight
                  ? `${fontWeight} ${fontSize}em Lato`
                  : livestreamingConst.config.emojiElement.font
              }
              textColor={
                textColor === "#000000"
                  ? !isMobile
                    ? livestreamingConst.config.emojiElement.desktopFontColor
                    : livestreamingConst.config.emojiElement.mobileFontColor
                  : textColor
              }
              backgroundColor={
                backgroundColor === "none"
                  ? livestreamingConst.config.emojiElement.backgroundColor
                  : backgroundColor
              }
              // Testing Style
              border={
                border
                  ? `${borderWidth}px ${borderStyle} ${borderColor}`
                  : "none"
              }
              borderRadius={borderRadius}
              textStroke={
                textStroke ? `${textStrokeWidth}px ${textStrokeColor}` : "unset"
              }
              textShadow={
                textShadow
                  ? `${textShadowShiftRight}px ${textShadowShiftDown}px ${textShadowBlur}px ${textShadowColor}`
                  : "none"
              }
            />
          </div>
        </div>
      </div>

      <div className="form-selection-container">
        <div className="conversation-form-field-container">
          <TextField
            className="conversation-form-field"
            value={conversation}
            onChange={(event) => onInputChange(event, "conversation")}
            label="Conversation"
            variant="outlined"
          />
        </div>

        {/* King of the Month */}
        {false && (
          <FormControlLabel
            control={<Switch defaultChecked />}
            label="King of the Month"
          />
        )}

        {/* Username */}
        <div className="username-form-field-container">
          <TextField
            className="username-form-field"
            value={username}
            onChange={(event) => onInputChange(event, "username")}
            label="Username"
            variant="outlined"
          />
        </div>

        {/* Livestreamer Username */}
        <div className="livestreamer-username-form-field-container">
          <TextField
            className="livestreamer-username-form-field"
            value={livestreamerUsername}
            onChange={(event) => onInputChange(event, "livestreamer-username")}
            label="Livestreamer Username"
            variant="outlined"
          />
        </div>

        {/* Font Size */}
        <div className="font-size-form-field-container">
          <TextField
            className="font-size-form-field"
            select
            value={fontSize}
            onChange={(event) => onInputChange(event, "font-size")}
            label="Font Size"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">em</InputAdornment>
              ),
            }}
          >
            {fontSizeData?.map((fontSize, index) => (
              <MenuItem value={fontSize} key={index}>
                {fontSize}
              </MenuItem>
            ))}
          </TextField>
        </div>

        {/* Font Weight */}
        <div className="font-weight-form-field-container">
          <TextField
            className="font-weight-form-field"
            select
            label="Font Weight"
            value={fontWeight}
            onChange={(event) => onInputChange(event, "font-weight")}
            variant="outlined"
          >
            {fontWeightData?.map((fontWeight, index) => (
              <MenuItem value={fontWeight} key={index}>
                {fontWeight}
              </MenuItem>
            ))}
          </TextField>
        </div>

        {/* Border */}
        <FormControlLabel
          control={
            <Switch
              checked={border}
              onChange={(event) => onInputChange(event, "border")}
            />
          }
          label="Border"
        />

        {/* Border Settings */}
        {border && (
          <div className="border-settings-container">
            <div className="border-style-form-field-container">
              <TextField
                className="border-style-form-field"
                select
                label="Style"
                value={borderStyle}
                onChange={(event) => onInputChange(event, "border-style")}
                variant="outlined"
              >
                {borderStyleData?.map((width, index) => (
                  <MenuItem value={width} key={index}>
                    {width}
                  </MenuItem>
                ))}
              </TextField>
            </div>

            <div className="border-width-form-field-container">
              <TextField
                className="border-width-form-field"
                select
                label="Width"
                value={borderWidth}
                onChange={(event) => onInputChange(event, "border-width")}
                variant="outlined"
              >
                {borderWidthData?.map((width, index) => (
                  <MenuItem value={width} key={index}>
                    {width}
                  </MenuItem>
                ))}
              </TextField>
            </div>

            <div className="border-color-form-field-container">
              <TextField
                className="border-color-form-field"
                value={borderColor}
                onChange={(event) => onInputChange(event, "border-color")}
                label="Color"
                variant="outlined"
              />
            </div>
          </div>
        )}

        {/* Border Radius */}
        <div className="border-radius-form-field-container">
          <TextField
            className="border-radius-form-field"
            select
            label="Border Radius"
            value={borderRadius}
            onChange={(event) => onInputChange(event, "border-radius")}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">px</InputAdornment>
              ),
            }}
          >
            {borderRadiusData?.map((radius, index) => (
              <MenuItem value={radius} key={index}>
                {radius}
              </MenuItem>
            ))}
          </TextField>
        </div>

        {/* Text Stroke */}
        <FormControlLabel
          control={
            <Switch
              checked={textStroke}
              onChange={(event) => onInputChange(event, "text-stroke")}
            />
          }
          label="Text Stroke"
        />

        {/* Text Stroke Settings */}
        {textStroke && (
          <div className="text-stroke-settings-container">
            <div className="text-stroke-width-form-field-container">
              <TextField
                className="text-stroke-width-form-field"
                select
                label="Width"
                value={textStrokeWidth}
                onChange={(event) => onInputChange(event, "text-stroke-width")}
                variant="outlined"
              >
                {textStrokeWidthData?.map((width, index) => (
                  <MenuItem value={width} key={index}>
                    {width}
                  </MenuItem>
                ))}
              </TextField>
            </div>

            <div className="text-stroke-color-form-field-container">
              <TextField
                className="text-stroke-color-form-field"
                value={textStrokeColor}
                onChange={(event) => onInputChange(event, "text-stroke-color")}
                label="Color"
                variant="outlined"
              />
            </div>
          </div>
        )}

        {/* Text Shadow */}
        <FormControlLabel
          control={
            <Switch
              checked={textShadow}
              onChange={(event) => onInputChange(event, "text-shadow")}
            />
          }
          label="Text Shadow"
        />

        {/* Text Shadow Settings */}
        {textShadow && (
          <div className="text-shadow-settings-container">
            <div className="shift-right-container">
              <div className="">Shift Right</div>

              <Slider
                value={textShadowShiftRight}
                onChange={(event) =>
                  onInputChange(event, "text-shadow-shift-right")
                }
                valueLabelDisplay="on"
                step={1}
                marks={false}
                min={-40}
                max={40}
              />
            </div>

            <div className="shift-down-container">
              <div className="">Shift Down</div>

              <Slider
                value={textShadowShiftDown}
                onChange={(event) =>
                  onInputChange(event, "text-shadow-shift-down")
                }
                valueLabelDisplay="on"
                step={1}
                marks={false}
                min={-40}
                max={40}
              />
            </div>

            <div className="blur-container">
              <div className="">Blur</div>

              <Slider
                value={textShadowBlur}
                onChange={(event) => onInputChange(event, "text-shadow-blur")}
                valueLabelDisplay="on"
                step={1}
                marks={false}
                min={0}
                max={20}
              />
            </div>

            <div className="opacity-container">
              <div className="">Opacity</div>

              <Slider
                value={textShadowOpacity}
                onChange={(event) =>
                  onInputChange(event, "text-shadow-opacity")
                }
                valueLabelDisplay="on"
                step={0.01}
                marks={false}
                min={0}
                max={1}
              />
            </div>

            <div className="text-shadow-color-form-field-container">
              <TextField
                className="text-shadow-color-form-field"
                value={textShadowColor}
                onChange={(event) => onInputChange(event, "text-shadow-color")}
                label="Color"
                variant="outlined"
              />
            </div>
          </div>
        )}

        {/* Text Color */}
        <div className="text-color-form-field-container">
          <TextField
            className="text-color-form-field"
            value={textColor}
            onChange={(event) => onInputChange(event, "text-color")}
            label="Color"
            variant="outlined"
          />
        </div>

        {/* Background Color */}
        <div className="text-color-form-field-container">
          <TextField
            className="text-color-form-field"
            value={backgroundColor}
            onChange={(event) => onInputChange(event, "background-color")}
            label="Background Color"
            variant="outlined"
          />
        </div>
      </div>

      <div
        className="simulate-conversation-button"
        onClick={onResetConversation}
      >
        Reset
      </div>
    </div>
  );
};

export default ConversationEditorTestElement;
