// General
import "./messaging-chat.scss";
// Static Data
import userConst from "../../../../../const/userConst";
// Material UI
import PersonIcon from "@mui/icons-material/Person";
// Custom Hooks
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import CustomAvatar from "../../../../shared/elements/custom-avatar/custom-avatar";

const MessagingChat = (props) => {
  const { username, profileBorder } = props;

  const conversationUser = null;

  // Custom Hooks Functions
  const getIcon = IconManager();

  return (
    <div className="middle-label">
      <CustomAvatar
        src={conversationUser?.profilePhoto}
        profileBorder={profileBorder}
        // onClick={onOpenUserProfileFromConversation}
      />

      {conversationUser?.livestreamingAchievementBadge?.length > 0 && (
        <div className="livestreaming-achievement-badge-container">
          {conversationUser?.livestreamingAchievementBadge?.map(
            (badge, index) => (
              <img
                className="livestreaming-achievement-badge"
                src={badge?.url}
                alt={badge?.type}
                key={index}
              />
            )
          )}
        </div>
      )}

      <div className="username">{username}</div>

      {conversationUser?.membershipType ===
        userConst.membershipType.premium && (
        <div className="premium-mini-badge-container">
          {getIcon("premiumMiniBadge", "premium-mini-badge")}
        </div>
      )}

      {conversationUser?.verifiedProfile && (
        <div className="verified-profile-container">
          {getIcon("verifiedProfileBadge", "verified-profile")}
        </div>
      )}

      {conversationUser?.membershipType === userConst.membershipType.admin && (
        <div className="admin-badge-container">
          <PersonIcon className="person-icon" />
          <div className="admin-label">Admin</div>
        </div>
      )}

      {conversationUser?.verifiedCaller && (
        <div
          className="verified-caller-container"
          // onClick={onOpenVerifiedCallerDialog}
        >
          {getIcon("verifiedCallerBadge", "verified-caller")}
        </div>
      )}
    </div>
  );
};

export default MessagingChat;
