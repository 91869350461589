// General
import "./user-info.scss";
// Static Data
import userConst from "../../../../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
// Material UI
import { CircularProgress, Divider } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Components
import CustomAvatar from "../../../elements/custom-avatar/custom-avatar";

const UserInfo = (props) => {
  const { profileBorder } = props;

  // Redux variables
  const livestreamerUserObj = useSelector(
    (state) => state.livestreaming.livestreamerUserObj
  );
  const livestreamerId = useSelector(
    (state) => state.livestreaming.livestreamerId
  );
  const livestreamerIdInt = useSelector(
    (state) => state.livestreaming.livestreamerIdInt
  );
  const livestreamerProfilePhoto = useSelector(
    (state) => state.livestreaming.livestreamerProfilePhoto
  );
  const livestreamerUsername = useSelector(
    (state) => state.livestreaming.livestreamerUsername
  );
  const livestreamerLevellingBadge = useSelector(
    (state) => state.livestreaming.livestreamerLevellingBadge
  );
  const livestreamerFollowers = useSelector(
    (state) => state.livestreaming.livestreamerFollowers
  );
  const livestreamerViewers = useSelector(
    (state) => state.livestreaming.livestreamerViewers
  );
  const livestreamerDiamonds = useSelector(
    (state) => state.livestreaming.livestreamerDiamonds
  );
  const livestreamerIsFollowing = useSelector(
    (state) => state.livestreaming.livestreamerIsFollowing
  );

  // i18next variables
  const { t } = useTranslation();

  // Event Handlers | Button
  const onFollowUser = () => {};
  const onUnfollowUser = () => {};

  // Utility Functions
  const onGetDiamonds = (diamonds) => {
    if (diamonds >= 1000) {
      return `${(diamonds / 1000).toFixed(0)}K`;
    } else if (diamonds >= 1000000) {
      return `${(diamonds / 1000000).toFixed(0)}M`;
    } else if (diamonds >= 1000000000) {
      return `${(diamonds / 1000000000).toFixed(0)}B`;
    } else {
      return diamonds;
    }
  };

  return (
    <div id="livestream-profile-view-dialog-user-info-subcomponent">
      <div className="padding-container">
        <div className="user-info-container">
          <div className="profile-picture-container">
            <CustomAvatar
              className="profile-picture"
              src={livestreamerProfilePhoto}
              role={userConst.userRole.sugarbaby}
              profileBorder={profileBorder}
              // onClick={onViewProfileCard}
            />

            {livestreamerLevellingBadge && (
              <div className="levelling-badge-container">
                <img
                  className="levelling-badge"
                  src={livestreamerLevellingBadge}
                  alt="levellling-badge"
                />
              </div>
            )}
          </div>

          <div className="user-details-container">
            <div className="top-container">
              <div className="username-container">
                {livestreamerUserObj?.live_streaming_achievements
                  ?.chat_badges_set?.length > 0 && (
                  <div className="achievement-container">
                    {livestreamerUserObj?.live_streaming_achievements?.chat_badges_set?.map(
                      (badge, index) => (
                        <img
                          className="livestreamer-badge"
                          src={badge?.url}
                          key={index}
                        />
                      )
                    )}
                  </div>
                )}
                <div className="username">{livestreamerUsername || "-"}</div>
              </div>

              {!livestreamerIsFollowing && (
                <div className="follow-button" onClick={onFollowUser}>
                  {false ? (
                    <CircularProgress
                      className="button-spinner-red"
                      size={12}
                    />
                  ) : (
                    t("common.follow")
                  )}
                </div>
              )}

              {livestreamerIsFollowing && (
                <div className="unfollow-button" onClick={onUnfollowUser}>
                  {false ? (
                    <CircularProgress
                      className="button-spinner-white"
                      size={12}
                    />
                  ) : (
                    t("common.unfollow")
                  )}
                </div>
              )}
            </div>

            <div className="user-stats-container">
              <div className="follower-container">
                <div className="followers-count">
                  {livestreamerFollowers || 0}
                </div>
                <div className="followers-label">{t("live.followers")}</div>
              </div>

              <Divider
                className="vertical-divider"
                orientation="vertical"
                flexItem
              />

              <div className="viewer-container">
                <div className="viewers-count">{livestreamerViewers || 0}</div>
                <div className="viewers0label">{t("live.viewers")}</div>
              </div>

              <Divider
                className="vertical-divider"
                orientation="vertical"
                flexItem
              />

              <div className="diamonds-container">
                <div className="diamonds-count">
                  {onGetDiamonds(livestreamerDiamonds) || 0}
                </div>
                <div className="diamonds-label">{t("live.diamonds")}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
