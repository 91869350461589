// General
import "./livestream-test-tools-dialog.scss";
import { useState } from "react";
// Static Data
import utilityConst from "../../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateLivestreamTestToolsDialog } from "../../../../redux/store/dialogStore";
// Material UI
import {
  Dialog,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// Components
import KingOfTheMonthTestElement from "./king-of-the-month-test-element/king-of-the-month-test-element";
import GiftAnimationTestElement from "./gift-animation-test-element/gift-animation-test-element";
import ConversationEditorTestElement from "./conversation-editor-test-element/conversation-editor-test-element";

const LivestreamTestToolsDialog = () => {
  // General variables
  const [expanded, setExpanded] = useState("");

  // Redux variables
  const livestreamTestToolsDialog = useSelector(
    (state) => state.dialog.livestreamTestToolsDialog
  );
  const releaseChannel = useSelector((state) => state.app.releaseChannel);
  const dispatch = useDispatch();

  // Event Handlers | MUI Accordion
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateLivestreamTestToolsDialog(false));
  };

  if (
    process.env["REACT_APP_ENVIRONMENT"] === "development" ||
    releaseChannel !== utilityConst.releaseChannel.stable
  ) {
    return (
      <Dialog
        className="custom-radius10-dialog"
        open={livestreamTestToolsDialog}
        onClose={onCloseDialog}
        keepMounted
      >
        <div id="livestream-test-tools-dialog">
          {/* King of the Month */}
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              King of the Month
            </AccordionSummary>
            <AccordionDetails>
              <KingOfTheMonthTestElement />
            </AccordionDetails>
          </Accordion>

          {/* Gift Animation */}
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              Gift Animation
            </AccordionSummary>
            <AccordionDetails>
              <GiftAnimationTestElement />
            </AccordionDetails>
          </Accordion>

          {/* Conversation Editor */}
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
            >
              Conversation Editor
            </AccordionSummary>
            <AccordionDetails>
              <ConversationEditorTestElement />
            </AccordionDetails>
          </Accordion>
        </div>
      </Dialog>
    );
  }
};

export default LivestreamTestToolsDialog;
