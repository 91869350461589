// General
import "./global-settings.scss";
import { useState } from "react";
// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  updateTopLeftSticker,
  updateCoinsAmount,
  updateExtraCoinsAmount,
  updateDiscountPercentage,
  updatePayAmount,
  updateDiscountPayAmount,
  updateBottomFloatingText,

  // Styling Functions
  updateMainBackgroundColor,
  updateMainSelectedBackgroundColor,
  updateMainBorderColor,
  updateMainSelectedBorderColor,
  updateExtraCoinsAmountBackgroundColor,
  updateExtraCoinsAmountTextColor,
  updateCoinsAmountTextColor,
  updatePayAmountTextColor,
  updateDiscountPayAmountTextColor,
  updateBottomFloatingTextBackgroundColor,
  updateBottomFloatingTextTextColor,
} from "../../../../redux/store/coinsPackageDesignerStore";
// Material UI
import { IconButton, TextField, FormControlLabel, Switch } from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";

const GlobalSettings = () => {
  // General variables
  const [imageData, setImageData] = useState(null);

  // Redux variables
  const topLeftSticker = useSelector(
    (state) => state.coinsPackageDesigner.topLeftSticker
  );
  const coinsAmount = useSelector(
    (state) => state.coinsPackageDesigner.coinsAmount
  );
  const extraCoinsAmount = useSelector(
    (state) => state.coinsPackageDesigner.extraCoinsAmount
  );
  const discountPercentage = useSelector(
    (state) => state.coinsPackageDesigner.discountPercentage
  );
  const payAmount = useSelector(
    (state) => state.coinsPackageDesigner.payAmount
  );
  const discountPayAmount = useSelector(
    (state) => state.coinsPackageDesigner.discountPayAmount
  );
  const bottomFloatingText = useSelector(
    (state) => state.coinsPackageDesigner.bottomFloatingText
  );
  const mainBackgroundColor = useSelector(
    (state) => state.coinsPackageDesigner.mainBackgroundColor
  );
  const mainSelectedBackgroundColor = useSelector(
    (state) => state.coinsPackageDesigner.mainSelectedBackgroundColor
  );
  const mainBorderColor = useSelector(
    (state) => state.coinsPackageDesigner.mainBorderColor
  );
  const mainSelectedBorderColor = useSelector(
    (state) => state.coinsPackageDesigner.mainSelectedBorderColor
  );
  const extraCoinsAmountBackgroundColor = useSelector(
    (state) => state.coinsPackageDesigner.extraCoinsAmountBackgroundColor
  );
  const extraCoinsAmountTextColor = useSelector(
    (state) => state.coinsPackageDesigner.extraCoinsAmountTextColor
  );
  const coinsAmountTextColor = useSelector(
    (state) => state.coinsPackageDesigner.coinsAmountTextColor
  );
  const payAmountTextColor = useSelector(
    (state) => state.coinsPackageDesigner.payAmountTextColor
  );
  const discountPayAmountTextColor = useSelector(
    (state) => state.coinsPackageDesigner.discountPayAmountTextColor
  );
  const bottomFloatingTextBackgroundColor = useSelector(
    (state) => state.coinsPackageDesigner.bottomFloatingTextBackgroundColor
  );
  const bottomFloatingTextTextColor = useSelector(
    (state) => state.coinsPackageDesigner.bottomFloatingTextTextColor
  );
  const dispatch = useDispatch();

  // Event Handlers | Button
  const onFilePicker = async (event) => {
    const file = event.target.files[0];

    if (file !== undefined) {
      try {
        const imageData = await readFile(file);
        setImageData((prevState) => ({
          ...prevState,
          image: imageData,
          file: file,
        }));
        dispatch(updateTopLeftSticker(imageData));
      } catch (error) {
        console.log(error);
      }
    }
  };

  // Event Handlers | MUI Text Field
  const onInputChange = (event, type) => {
    switch (type) {
      case "coinsAmount":
        dispatch(updateCoinsAmount(event.target.value));
        break;
      case "extraCoinsAmount":
        dispatch(updateExtraCoinsAmount(event.target.value));
        break;
      case "discountPercentage":
        dispatch(updateDiscountPercentage(event.target.value));
        break;
      case "payAmount":
        dispatch(updatePayAmount(event.target.value));
        break;
      case "discountPayAmount":
        dispatch(updateDiscountPayAmount(event.target.value));
        break;
      case "bottomFloatingText":
        dispatch(updateBottomFloatingText(event.target.value));
        break;
      case "mainBackgroundColor":
        dispatch(updateMainBackgroundColor(event.target.value));
        break;
      case "mainSelectedBackgroundColor":
        dispatch(updateMainSelectedBackgroundColor(event.target.value));
        break;
      case "mainBorderColor":
        dispatch(updateMainBorderColor(event.target.value));
        break;
      case "mainSelectedBorderColor":
        dispatch(updateMainSelectedBorderColor(event.target.value));
        break;
      case "extraCoinsAmountBackgroundColor":
        dispatch(updateExtraCoinsAmountBackgroundColor(event.target.value));
        break;
      case "extraCoinsAmountTextColor":
        dispatch(updateExtraCoinsAmountTextColor(event.target.value));
        break;
      case "coinsAmountTextColor":
        dispatch(updateCoinsAmountTextColor(event.target.value));
        break;
      case "payAmountTextColor":
        dispatch(updatePayAmountTextColor(event.target.value));
        break;
      case "discountPayAmountTextColor":
        dispatch(updateDiscountPayAmountTextColor(event.target.value));
        break;
      case "bottomFloatingTextBackgroundColor":
        dispatch(updateBottomFloatingTextBackgroundColor(event.target.value));
        break;
      case "bottomFloatingTextTextColor":
        dispatch(updateBottomFloatingTextTextColor(event.target.value));
        break;
      default:
        break;
    }
  };

  // Utility Functions
  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  return (
    <div id="coins-package-designer-global-settings-subcomponent">
      <div className="global-settings-label">Global Settings </div>

      <div className="coins-package-designer-settings-content">
        <div className="coins-package-designer-preview-container">
          <div className="coins-package-designer-preview-label-container">
            <IconButton
              className="profile-img-button"
              disableRipple
              component="label"
            >
              <input
                hidden
                accept="image/*"
                type="file"
                onChange={onFilePicker}
              />
              <UploadIcon />
            </IconButton>

            <div className="user-border-preview-label">Preview</div>
          </div>

          <div className="coins-package-designer-preview-image-container">
            <img
              className="coins-package-designer-preview"
              src={topLeftSticker}
            />
          </div>
        </div>

        <div className="form-fields-container">
          <div className="label-label">Label</div>
          <div className="label-form-fields-container">
            <div className="coins-amount-form-field-container">
              <TextField
                className="coins-amount-form-field"
                value={coinsAmount}
                onChange={(event) => onInputChange(event, "coinsAmount")}
                label="Coins Amount"
                variant="outlined"
              />
            </div>

            <div className="coins-amount-form-field-container">
              <TextField
                className="coins-amount-form-field"
                value={extraCoinsAmount}
                onChange={(event) => onInputChange(event, "extraCoinsAmount")}
                label="Extra Coins Amount"
                variant="outlined"
              />
            </div>

            <div className="coins-amount-form-field-container">
              <TextField
                className="coins-amount-form-field"
                value={discountPercentage}
                onChange={(event) => onInputChange(event, "discountPercentage")}
                label="Discount Percentage"
                variant="outlined"
              />
            </div>

            <div className="coins-amount-form-field-container">
              <TextField
                className="coins-amount-form-field"
                value={payAmount}
                onChange={(event) => onInputChange(event, "payAmount")}
                label="Pay Amount"
                variant="outlined"
              />
            </div>

            <div className="coins-amount-form-field-container">
              <TextField
                className="coins-amount-form-field"
                value={discountPayAmount}
                onChange={(event) => onInputChange(event, "discountPayAmount")}
                label="Discount Pay Amount"
                variant="outlined"
              />
            </div>

            <div className="coins-amount-form-field-container">
              <TextField
                className="coins-amount-form-field"
                value={bottomFloatingText}
                onChange={(event) => onInputChange(event, "bottomFloatingText")}
                label="Bottom Floating Text"
                variant="outlined"
              />
            </div>
          </div>

          <div className="styling-label">Styling</div>
          <div className="styling-form-fields-container">
            <div className="coins-amount-form-field-container">
              <TextField
                className="coins-amount-form-field"
                value={mainBackgroundColor}
                onChange={(event) =>
                  onInputChange(event, "mainBackgroundColor")
                }
                label="Main Background Color"
                variant="outlined"
              />
            </div>

            <div className="coins-amount-form-field-container">
              <TextField
                className="coins-amount-form-field"
                value={mainSelectedBackgroundColor}
                onChange={(event) =>
                  onInputChange(event, "mainSelectedBackgroundColor")
                }
                label="Main Selected Background Color"
                variant="outlined"
              />
            </div>

            <div className="coins-amount-form-field-container">
              <TextField
                className="coins-amount-form-field"
                value={mainBorderColor}
                onChange={(event) => onInputChange(event, "mainBorderColor")}
                label="Main Border Color"
                variant="outlined"
              />
            </div>

            <div className="coins-amount-form-field-container">
              <TextField
                className="coins-amount-form-field"
                value={mainSelectedBorderColor}
                onChange={(event) =>
                  onInputChange(event, "mainSelectedBorderColor")
                }
                label="Main Selected Border Color"
                variant="outlined"
              />
            </div>

            <div className="coins-amount-form-field-container">
              <TextField
                className="coins-amount-form-field"
                value={extraCoinsAmountBackgroundColor}
                onChange={(event) =>
                  onInputChange(event, "extraCoinsAmountBackgroundColor")
                }
                label="Extra Coins Amount Background Color"
                variant="outlined"
              />
            </div>

            <div className="coins-amount-form-field-container">
              <TextField
                className="coins-amount-form-field"
                value={extraCoinsAmountTextColor}
                onChange={(event) =>
                  onInputChange(event, "extraCoinsAmountTextColor")
                }
                label="Extra Coins Amount Text Color"
                variant="outlined"
              />
            </div>

            <div className="coins-amount-form-field-container">
              <TextField
                className="coins-amount-form-field"
                value={coinsAmountTextColor}
                onChange={(event) =>
                  onInputChange(event, "coinsAmountTextColor")
                }
                label="Coins Amount Text Color"
                variant="outlined"
              />
            </div>

            <div className="coins-amount-form-field-container">
              <TextField
                className="coins-amount-form-field"
                value={payAmountTextColor}
                onChange={(event) => onInputChange(event, "payAmountTextColor")}
                label="Pay Amount Text Color"
                variant="outlined"
              />
            </div>

            <div className="coins-amount-form-field-container">
              <TextField
                className="coins-amount-form-field"
                value={discountPayAmountTextColor}
                onChange={(event) =>
                  onInputChange(event, "discountPayAmountTextColor")
                }
                label="Discount Pay Amount Text Color"
                variant="outlined"
              />
            </div>

            <div className="coins-amount-form-field-container">
              <TextField
                className="coins-amount-form-field"
                value={bottomFloatingTextBackgroundColor}
                onChange={(event) =>
                  onInputChange(event, "bottomFloatingTextBackgroundColor")
                }
                label="Bottom Floating Text Background Color"
                variant="outlined"
              />
            </div>

            <div className="coins-amount-form-field-container">
              <TextField
                className="coins-amount-form-field"
                value={bottomFloatingTextTextColor}
                onChange={(event) =>
                  onInputChange(event, "bottomFloatingTextTextColor")
                }
                label="Bottom Floating Text Color"
                variant="outlined"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalSettings;
