// General
import "./king-of-the-month-element.scss";
import { useRef } from "react";
// Material UI
import { Divider } from "@mui/material";
// Components
import CustomAvatar from "../../../../shared/elements/custom-avatar/custom-avatar";

const KingOfTheMonthElement = (props) => {
  const {
    asset,
    profilePhoto,
    username,
    description,
    showCenterLine = false,
    animationDuration = 3,

    // Callback
    onGetImageDimension,
  } = props;

  // General variables
  const imageData = useRef(null);

  const handleImageLoad = () => {
    if (imageData.current && typeof onGetImageDimension === "function") {
      const callbackObj = {
        height: imageData.current.offsetHeight,
        width: imageData.current.offsetWidth,
      };
      onGetImageDimension(callbackObj);
    }
  };

  return (
    <div
      id="king-of-the-month-entrance-container"
      style={
        animationDuration > 0
          ? {
              WebkitAnimation: `slideInAndOut ${animationDuration}s forwards`,
              MozAnimation: `slideInAndOut ${animationDuration}s forwards`,
              animation: `slideInAndOut ${animationDuration}s ease-in-out`,
            }
          : {}
      }
    >
      <div className="king-of-the-month-entrance-gif-container">
        {imageData && (
          <img
            className="king-of-the-month-entrance-asset"
            ref={imageData}
            src={asset}
            onLoad={handleImageLoad}
          />
        )}
      </div>

      <div className="king-of-the-month-entrance-description-container">
        <div className="king-of-the-month-entrance-description">
          <div className="profile-photo-container">
            <CustomAvatar
              className="king-of-the-month-profile-photo"
              src={profilePhoto}
            />
          </div>

          <div className="king-of-the-month-description-container">
            <div className="king-of-the-month-top-description-container">
              <div className="king-of-the-month-username">{username || ""}</div>
              <div className="king-of-the-month-levelling-badge"></div>
            </div>

            <div className="king-of-the-month-bottom-description-container">
              {description}
            </div>
          </div>
        </div>
      </div>

      {showCenterLine && (
        <div className="king-of-the-month-entrance-center-line-container">
          <Divider className="kotm-center-line" />
        </div>
      )}
    </div>
  );
};

export default KingOfTheMonthElement;
