// Static Data
import userCardConst from "../../../../const/userCardConst";
// Moment
import moment from "moment";
// Components
import UserCard from "../../../shared/elements/user-card/user-card";
import GamingCard from "../../../shared/elements/gaming-card/gaming-card";
import CoAnchorCard from "../../../shared/elements/co-anchor-card/co-anchor-card";

const UserCardManager = (props) => {
  const {
    type,

    // Settings
    cardView,
    // User View
    id,
    idInt,
    username,
    age,
    location,
    membershipType,
    verifiedProfile,
    verifiedCaller,
    levellingBadge,
    livestreamingAchievementBadge,
    profilePhoto,
    profileDecorations,
    publicPhotos,
    privatePhotos,
    torTags,
    profileAchievementBadge,
    aboutMe,
    lookingFor,
    tagline,
    annualIncome,
    networth,
    occupation,
    lifestyle,
    education,
    relationship,
    smoking,
    drinking,
    height,
    bodyType,
    ethnicity,
    eyeColor,
    hairColor,
    children,
    withinDistanceInKm,
    // User Utility
    profileStatus,
    privatePhotoAbleToRequest,
    privatePhotoRequested,
    privatePhotoApproval,
    requestedMyPrivatePhoto,
    approvedMyPrivatePhoto,
    isOnline,
    isBlocked,
    isSilentInteraction,
    isFavorited,
    isCallEnabled,
    isSugarbaby,
    role,
    // Livestream
    livestreamData,
    channelId,
    livestreamerId,
    followerCount,
    viewerCount,
    diamondCount,
    isFollowing,
    // Card Utility
    cardStatus,

    // Co Anchor Utility
    hostProfilePhoto,
    coHostProfilePhoto,

    // Gaming View
    backgroundImage,

    // Utility
    cardBorder,
  } = props;

  // Utility Functions
  const isPkActive = (pkData) => {
    const now = moment();

    if (pkData) {
      const pkStartTime = moment(pkData[pkData?.length - 1]?.start_at);
      const pkEndTime = moment(pkData[pkData?.length - 1]?.end_at);

      if (now.isBetween(pkStartTime, pkEndTime)) {
        return true;
      }

      return false;
    }

    return false;
  };

  switch (type) {
    case userCardConst.cardType.user:
    case userCardConst.cardType.default:
      return (
        <UserCard
          cardView={cardView}
          // User View
          id={id}
          idInt={idInt}
          username={username}
          age={age}
          location={location}
          membershipType={membershipType}
          verifiedProfile={verifiedProfile}
          verifiedCaller={verifiedCaller}
          levellingBadge={levellingBadge}
          livestreamingAchievementBadge={livestreamingAchievementBadge}
          profilePhoto={profilePhoto}
          publicPhotos={publicPhotos}
          privatePhotos={privatePhotos}
          torTags={torTags}
          profileAchievementBadge={profileAchievementBadge}
          aboutMe={aboutMe}
          lookingFor={lookingFor}
          tagline={tagline}
          annualIncome={annualIncome}
          networth={networth}
          occupation={occupation}
          lifestyle={lifestyle}
          education={education}
          relationship={relationship}
          smoking={smoking}
          drinking={drinking}
          height={height}
          bodyType={bodyType}
          ethnicity={ethnicity}
          eyeColor={eyeColor}
          hairColor={hairColor}
          children={children}
          withinDistanceInKm={withinDistanceInKm}
          // User Utility
          profileStatus={profileStatus}
          privatePhotoAbleToRequest={privatePhotoAbleToRequest}
          privatePhotoRequested={privatePhotoRequested}
          privatePhotoApproval={privatePhotoApproval}
          requestedMyPrivatePhoto={requestedMyPrivatePhoto}
          approvedMyPrivatePhoto={approvedMyPrivatePhoto}
          isOnline={isOnline}
          isBlocked={isBlocked}
          isSilentInteraction={isSilentInteraction}
          isFavorited={isFavorited}
          isCallEnabled={isCallEnabled}
          isSugarbaby={isSugarbaby}
          role={role}
          // Livestream
          channelId={channelId}
          livestreamerId={livestreamerId}
          followerCount={followerCount}
          viewerCount={viewerCount}
          diamondCount={diamondCount}
          isFollowing={isFollowing}
          // Card Utility
          cardStatus={cardStatus}
          cardBorder={cardBorder}
        />
      );
    case userCardConst.cardType.coAnchor:
      return (
        <CoAnchorCard
          cardView={cardView}
          // User View
          id={id}
          idInt={idInt}
          username={username}
          age={age}
          location={location}
          membershipType={membershipType}
          verifiedProfile={verifiedProfile}
          verifiedCaller={verifiedCaller}
          levellingBadge={levellingBadge}
          livestreamingAchievementBadge={livestreamingAchievementBadge}
          profilePhoto={profilePhoto}
          profileDecorations={profileDecorations}
          publicPhotos={publicPhotos}
          privatePhotos={privatePhotos}
          torTags={torTags}
          profileAchievementBadge={profileAchievementBadge}
          aboutMe={aboutMe}
          lookingFor={lookingFor}
          tagline={tagline}
          annualIncome={annualIncome}
          networth={networth}
          occupation={occupation}
          lifestyle={lifestyle}
          education={education}
          relationship={relationship}
          smoking={smoking}
          drinking={drinking}
          height={height}
          bodyType={bodyType}
          ethnicity={ethnicity}
          eyeColor={eyeColor}
          hairColor={hairColor}
          children={children}
          withinDistanceInKm={withinDistanceInKm}
          // User Utility
          profileStatus={profileStatus}
          privatePhotoAbleToRequest={privatePhotoAbleToRequest}
          privatePhotoRequested={privatePhotoRequested}
          privatePhotoApproval={privatePhotoApproval}
          requestedMyPrivatePhoto={requestedMyPrivatePhoto}
          approvedMyPrivatePhoto={approvedMyPrivatePhoto}
          isOnline={isOnline}
          isBlocked={isBlocked}
          isSilentInteraction={isSilentInteraction}
          isFavorited={isFavorited}
          isCallEnabled={isCallEnabled}
          isSugarbaby={isSugarbaby}
          role={role}
          // Livestream
          channelId={channelId}
          livestreamerId={livestreamerId}
          followerCount={followerCount}
          viewerCount={viewerCount}
          diamondCount={diamondCount}
          isFollowing={isFollowing}
          // Card Utility
          cardStatus={cardStatus}
          // Co Anchor Utility
          hostProfilePhoto={hostProfilePhoto}
          coHostProfilePhoto={coHostProfilePhoto}
          hasPk={isPkActive(livestreamData?.co_anchor?.pk)}
        />
      );
    case userCardConst.cardType.gaming:
      return <GamingCard backgroundImage={backgroundImage} />;
    default:
      return (
        <UserCard
          cardView={cardView}
          // User View
          id={id}
          idInt={idInt}
          username={username}
          age={age}
          location={location}
          membershipType={membershipType}
          verifiedProfile={verifiedProfile}
          verifiedCaller={verifiedCaller}
          levellingBadge={levellingBadge}
          livestreamingAchievementBadge={livestreamingAchievementBadge}
          profilePhoto={profilePhoto}
          publicPhotos={publicPhotos}
          privatePhotos={privatePhotos}
          torTags={torTags}
          profileAchievementBadge={profileAchievementBadge}
          aboutMe={aboutMe}
          lookingFor={lookingFor}
          tagline={tagline}
          annualIncome={annualIncome}
          networth={networth}
          occupation={occupation}
          lifestyle={lifestyle}
          education={education}
          relationship={relationship}
          smoking={smoking}
          drinking={drinking}
          height={height}
          bodyType={bodyType}
          ethnicity={ethnicity}
          eyeColor={eyeColor}
          hairColor={hairColor}
          children={children}
          withinDistanceInKm={withinDistanceInKm}
          // User Utility
          profileStatus={profileStatus}
          privatePhotoAbleToRequest={privatePhotoAbleToRequest}
          privatePhotoRequested={privatePhotoRequested}
          privatePhotoApproval={privatePhotoApproval}
          requestedMyPrivatePhoto={requestedMyPrivatePhoto}
          approvedMyPrivatePhoto={approvedMyPrivatePhoto}
          isOnline={isOnline}
          isBlocked={isBlocked}
          isSilentInteraction={isSilentInteraction}
          isFavorited={isFavorited}
          isCallEnabled={isCallEnabled}
          isSugarbaby={isSugarbaby}
          role={role}
          // Livestream
          channelId={channelId}
          livestreamerId={livestreamerId}
          followerCount={followerCount}
          viewerCount={viewerCount}
          diamondCount={diamondCount}
          isFollowing={isFollowing}
          // Card Utility
          cardStatus={cardStatus}
          cardBorder={cardBorder}
        />
      );
  }
};

export default UserCardManager;
