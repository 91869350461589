// General
import "./leaderboard-user.scss";
import { useEffect } from "react";
// Services
import {
  useLazyGetProfileQuery,
  usePostFollowUserMutation,
  usePostUnfollowUserMutation,
} from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useDispatch } from "react-redux";
import {
  // User View
  updateId,
  updateIdInt,
  updateUsername,
  updateAge,
  updateLocation,
  updateMembershipType,
  updateVerifiedProfile,
  updateVerifiedCaller,
  updateLevellingBadge,
  updateLivestreamingAchievementBadge,
  updateProfilePhoto,
  updatePublicPhotos,
  updatePrivatePhotos,
  updateTorTags,
  updateProfileAchievementBadge,
  updateAboutMe,
  updateLookingFor,
  updateOccupation,
  updateLifestyle,
  updateEducation,
  updateRelationship,
  updateSmoking,
  updateDrinking,
  updateHeight,
  updateBodyType,
  updateEthnicity,
  updateEyeColor,
  updateHairColor,
  updateChildren,
  // User Utility
  updateShouldCallApi,
  updatePrivatePhotoRequested,
  updatePrivatePhotoApproval,
  updateRequestedMyPrivatePhoto,
  updateApprovedMyPrivatePhoto,
  updateIsBlocked,
  updateIsSilentInteraction,
  updateIsFavorited,
  updateIsCallEnabled,
  updateIsSugarbaby,
  updateRole,
} from "../../../../redux/store/userCardStore";
import {
  updateLiveViewerByIndex,
  updateTopFansDailyByIndex,
} from "../../../../redux/store/livestreamingStore";
import {
  updateUserProfileDialog,
  updateVerifiedCallerDescriptionDialog,
} from "../../../../redux/store/dialogStore";
import { updateErrorToast } from "../../../../redux/store/toastStore";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../utility/manager/icon-manager/icon-manager";
// Components
import CustomAvatar from "../custom-avatar/custom-avatar";
import Spinner from "../spinner/spinner";

const LeaderboardUser = (props) => {
  const {
    index,
    // User View
    id,
    idInt,
    username,
    age,
    location,
    membershipType,
    verifiedProfile,
    verifiedCaller,
    levellingBadge,
    livestreamingAchievementBadge,
    profilePhoto,
    publicPhotos,
    privatePhotos,
    torTags,
    profileAchievementBadge,
    aboutMe,
    lookingFor,
    occupation,
    lifestyle,
    education,
    relationship,
    smoking,
    drinking,
    height,
    bodyType,
    ethnicity,
    eyeColor,
    hairColor,
    children,
    userType,
    amount,
    isFollowing,
    // User Utility
    shouldCallApi,
    privatePhotoRequested,
    privatePhotoApproval,
    requestedMyPrivatePhoto,
    approvedMyPrivatePhoto,
    isBlocked,
    isSilentInteraction,
    isFavorited,
    isCallEnabled,
    isSugarbaby,
    role,
  } = props;

  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    postFollowUser,
    {
      data: postFollowUserData,
      error: postFollowUserErrorData,
      isLoading: postFollowUserLoading,
      isSuccess: postFollowUserSuccess,
      isError: postFollowUserError,
    },
  ] = usePostFollowUserMutation();
  const [
    postUnfollowUser,
    {
      data: postUnfollowUserData,
      error: postUnfollowUserErrorData,
      isLoading: postUnfollowUserLoading,
      isSuccess: postUnfollowUserSuccess,
      isError: postUnfollowUserError,
    },
  ] = usePostUnfollowUserMutation();

  // Redux variables
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
  }, []);

  // Lifecycle | Check for update | Follow user API Response
  useEffect(() => {
    if (postFollowUserLoading) {
    } else if (postFollowUserSuccess) {
      switch (postFollowUserData?.status) {
        case 1:
          const obj = {
            id,
            isFollowing: true,
            index: index,
          };
          dispatch(updateLiveViewerByIndex(obj));
          dispatch(updateTopFansDailyByIndex(obj));
          // dispatch(updateTopFansWeeklyByIndex(obj)); // Same function
          // dispatch(updateTopFansMonthlyByIndex(obj)); // Same function
          // dispatch(updateTopFansOverallByIndex(obj)); // Same function
          break;
        case -1:
          // Already following user
          const toastObj = {
            message: postFollowUserData?.message,
            autoClose: 3000,
          };
          dispatch(updateErrorToast(toastObj));
          break;
        default:
          break;
      }
    } else if (postFollowUserError) {
      if (postFollowUserErrorData?.status === 401) {
        // onNavigate(routeConst.logout.path);
      }
    }
  }, [postFollowUserLoading, postFollowUserSuccess, postFollowUserError]);

  // Lifecycle | Check for update | Unfollow user API Response
  useEffect(() => {
    if (postUnfollowUserLoading) {
    } else if (postUnfollowUserSuccess) {
      switch (postUnfollowUserData?.status) {
        case 1:
          const obj = {
            id,
            isFollowing: false,
            index: index,
          };
          dispatch(updateLiveViewerByIndex(obj));
          dispatch(updateTopFansDailyByIndex(obj));
          // dispatch(updateTopFansWeeklyByIndex(obj)); // Same function
          // dispatch(updateTopFansMonthlyByIndex(obj)); // Same function
          // dispatch(updateTopFansOverallByIndex(obj)); // Same function
          break;
        case -1:
          // Already unfollowing user
          const toastObj = {
            message: postUnfollowUserData?.message,
            autoClose: 3000,
          };
          dispatch(updateErrorToast(toastObj));
          break;
        default:
          break;
      }
      if (postUnfollowUserData?.status === 1) {
        const obj = {
          isFollowing: false,
          index: index,
        };
        dispatch(updateLiveViewerByIndex(obj));
      }
    } else if (postUnfollowUserError) {
      if (postUnfollowUserErrorData?.status === 401) {
        // onNavigate(routeConst.logout.path);
      }
    }
  }, [postUnfollowUserLoading, postUnfollowUserSuccess, postUnfollowUserError]);

  // Event Handlers | Button
  const onViewProfile = () => {
    // User View
    dispatch(updateId(id));
    dispatch(updateIdInt(idInt));
    dispatch(updateUsername(username));
    dispatch(updateAge(age));
    dispatch(updateLocation(location));
    dispatch(updateMembershipType(membershipType));
    dispatch(updateVerifiedProfile(verifiedProfile));
    dispatch(updateVerifiedCaller(verifiedCaller));
    dispatch(updateLevellingBadge(levellingBadge));
    dispatch(
      updateLivestreamingAchievementBadge(livestreamingAchievementBadge)
    );
    dispatch(updateProfilePhoto(profilePhoto));
    dispatch(updatePublicPhotos(publicPhotos));
    dispatch(updatePrivatePhotos(privatePhotos));
    dispatch(updateTorTags(torTags));
    dispatch(updateProfileAchievementBadge(profileAchievementBadge));
    dispatch(updateAboutMe(aboutMe));
    dispatch(updateLookingFor(lookingFor));
    dispatch(updateOccupation(occupation));
    dispatch(updateLifestyle(lifestyle));
    dispatch(updateEducation(education));
    dispatch(updateRelationship(relationship));
    dispatch(updateSmoking(smoking));
    dispatch(updateDrinking(drinking));
    dispatch(updateHeight(height));
    dispatch(updateBodyType(bodyType));
    dispatch(updateEthnicity(ethnicity));
    dispatch(updateEyeColor(eyeColor));
    dispatch(updateHairColor(hairColor));
    dispatch(updateChildren(children));
    // User Utility
    dispatch(updateShouldCallApi(shouldCallApi));
    dispatch(updatePrivatePhotoRequested(privatePhotoRequested));
    dispatch(updatePrivatePhotoApproval(privatePhotoApproval));
    dispatch(updateRequestedMyPrivatePhoto(requestedMyPrivatePhoto));
    dispatch(updateApprovedMyPrivatePhoto(approvedMyPrivatePhoto));
    dispatch(updateIsBlocked(isBlocked ? true : false));
    dispatch(updateIsSilentInteraction(isSilentInteraction));
    dispatch(updateIsFavorited(isFavorited));
    dispatch(updateIsCallEnabled(isCallEnabled));
    dispatch(updateIsSugarbaby(isSugarbaby));
    dispatch(updateRole(role));

    dispatch(updateUserProfileDialog(true));
  };
  const onFollowUser = () => {
    const obj = {
      user_id: id,
    };
    postFollowUser(obj);
  };
  const onUnfollowUser = () => {
    const obj = {
      user_id: id,
    };
    postUnfollowUser(obj);
  };
  const onOpenVerifiedCallerDialog = () => {
    dispatch(updateVerifiedCallerDescriptionDialog(true));
  };

  // Render Functions
  const renderFollowButton = () => {
    if (getProfileData?.data?.id !== id) {
      if (isFollowing) {
        return (
          <div className="following-button" onClick={onUnfollowUser}>
            {postUnfollowUserLoading ? (
              <Spinner size={20} isPadding={false} color={"white-spinner"} />
            ) : (
              t("common.following")
            )}
          </div>
        );
      } else {
        return (
          <div className="follow-button" onClick={onFollowUser}>
            {postFollowUserLoading ? (
              <Spinner size={20} isPadding={false} />
            ) : (
              t("common.follow")
            )}
          </div>
        );
      }
    }
  };

  return (
    <div id="leaderboard-user-shared-subcomponent">
      <div className="trophy-container">
        {index === 0 && getIcon("trophyRank1Badge", "trophy")}
        {index === 1 && getIcon("trophyRank2Badge", "trophy")}
        {index === 2 && getIcon("trophyRank3Badge", "trophy")}
      </div>

      <CustomAvatar
        className="profile-picture"
        src={profilePhoto}
        onClick={onViewProfile}
      />

      <div className="user-description-container">
        <div className="top-container">
          {livestreamingAchievementBadge?.length > 0 && (
            <div className="livestreaming-achievement-badge-container">
              {livestreamingAchievementBadge?.map((badge, index) => (
                <img
                  className="livestreaming-achievement-badge"
                  src={badge?.url}
                  alt={badge?.type}
                  key={index}
                />
              ))}
            </div>
          )}

          <div className="username">{username}</div>
          <div className="badge-container">
            {levellingBadge && (
              <div className="level-container">
                <img
                  className="level-badge"
                  src={levellingBadge}
                  alt="levelling-badge"
                />
              </div>
            )}

            {verifiedProfile && (
              <div className="verified-profile-container">
                {getIcon("verifiedProfileBadge", "verified-profile-badge")}
              </div>
            )}

            {verifiedCaller && (
              <div
                className="verified-caller-container"
                onClick={onOpenVerifiedCallerDialog}
              >
                {getIcon("verifiedCallerBadge", "verified-caller-badge")}
              </div>
            )}
          </div>
        </div>

        <div className="bottom-container">
          <div className="diamond-icon-container">
            {userType === "streamer"
              ? getIcon("sbDiamondIcon", "diamond-icon")
              : getIcon("sbCoinIcon", "diamond-icon")}
          </div>

          {userType === "streamer" ? (
            <div className="diamond-count">{amount?.toLocaleString()}</div>
          ) : (
            <div className="coin-count">{amount?.toLocaleString()}</div>
          )}
        </div>
      </div>

      <span className="spacer"></span>

      {renderFollowButton()}
    </div>
  );
};

export default LeaderboardUser;
