// General
import "./slide-in-out-alert-overlay.scss";
import { useState, useEffect, useRef } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateLivestreamKingOfTheMonthTrigger } from "../../../../redux/store/debugStore";
// Components
import GiftAlertElement from "./gift-alert-element/gift-alert-element";
import KingOfTheMonthElement from "./king-of-the-month-element/king-of-the-month-element";

const SlideInOutAlertOverlay = () => {
  // General variables
  const [counter, setCounter] = useState(1);
  const counterRef = useRef(1);

  // Render variables
  const [giftAlertView, setGiftAlertView] = useState([]);

  // Redux variables
  const livestreamGiftAnimation = useSelector(
    (state) => state.livestreaming.livestreamGiftAnimation
  );
  const livestreamKingOfTheMonthTrigger = useSelector(
    (state) => state.debug.livestreamKingOfTheMonthTrigger
  );
  const dispatch = useDispatch();

  // Lifecycle | Mounted & Unmounted
  useEffect(() => {
    return () => {
      dispatch(updateLivestreamKingOfTheMonthTrigger(null));
    };
  }, []);

  // Lifecycle | Check for update | livestreamGiftAnimation
  useEffect(() => {
    if (livestreamGiftAnimation) {
      handleGiftAlertView(livestreamGiftAnimation, counterRef.current);

      setCounter((oldCounter) => oldCounter + 1);
      counterRef.current += 1;
    }
  }, [livestreamGiftAnimation]);

  // Lifecycle | Check for update | livestreamKingOfTheMonthTrigger | Debug only
  useEffect(() => {
    if (livestreamKingOfTheMonthTrigger) {
      handleKingOfTheMonthView(
        livestreamKingOfTheMonthTrigger,
        counterRef.current
      );

      setCounter((oldCounter) => oldCounter + 1);
      counterRef.current += 1;
    }
  }, [livestreamKingOfTheMonthTrigger]);

  // Utility Functions
  const handleGiftAlertView = (livestreamGiftAnimation, counter) => {
    // Create a new div element
    const giftDiv = (
      <GiftAlertElement
        userPhoto={livestreamGiftAnimation?.user_photo}
        username={livestreamGiftAnimation?.username}
        levellingBadge={livestreamGiftAnimation?.levelling_profile?.badge}
        giftName={livestreamGiftAnimation?.data?.gift_name}
        giftAsset={livestreamGiftAnimation?.data?.gift_graphic}
        key={counter}
      />
    );

    // Push the gift alert to the gift alert view
    setGiftAlertView((oldArray) => [...oldArray, giftDiv]);

    // Remove the gift alert after 3 seconds, FIFO
    setTimeout(() => {
      setGiftAlertView((oldArray) => oldArray.slice(1));
    }, 3000);
  };
  const handleKingOfTheMonthView = (
    livestreamKingOfTheMonthTrigger,
    counter
  ) => {
    // Create a new div element
    const kotmDiv = (
      <KingOfTheMonthElement
        asset={livestreamKingOfTheMonthTrigger?.asset}
        profilePhoto={livestreamKingOfTheMonthTrigger?.username}
        username={livestreamKingOfTheMonthTrigger?.username}
        description={livestreamKingOfTheMonthTrigger?.description}
        showCenterLine={false}
        animationDuration={livestreamKingOfTheMonthTrigger?.duration || 3}
        key={counter}
      />
    );

    // Push the gift alert to the gift alert view
    setGiftAlertView((oldArray) => [...oldArray, kotmDiv]);

    // Remove the gift alert after 3 seconds, FIFO
    setTimeout(() => {
      setGiftAlertView((oldArray) => oldArray.slice(1));
    }, livestreamKingOfTheMonthTrigger?.duration * 1000 || 3000);
  };

  return (
    <div id="livestream-slide-in-out-alert-overlay-subcomponent">
      <div className="padding-container">{giftAlertView}</div>
    </div>
  );
};

export default SlideInOutAlertOverlay;
