// General
import "./livestream-profile-view-user-info.scss";
// Material UI
import { Divider } from "@mui/material";
// Components
import UserInfo from "../../../../shared/dialog-content/livestream-profile-view-dialog/user-info/user-info";

const LivestreamProfileViewUserInfo = (props) => {
  const { profileBorder } = props;

  return (
    <div id="livestream-profile-view-dialog">
      <UserInfo profileBorder={profileBorder} />

      <Divider />
    </div>
  );
};

export default LivestreamProfileViewUserInfo;
