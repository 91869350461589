// General
import "./banner-tester.scss";
// Material UI
import { Divider } from "@mui/material";
// Components
import Banner from "../../shared/elements/banner/banner";
import SearchPageBannerTester from "./search-page-banner-tester/search-page-banner-tester";
import InboxPageBannerTester from "./inbox-page-banner-tester/inbox-page-banner-tester";
import LivePageBannerTester from "./live-page-banner-tester/live-page-banner-tester";
import ProfileOverviewPageBannerTester from "./profile-overview-page-banner-tester/profile-overview-page-banner-tester";

const BannerTester = () => {
  return (
    <div id="banner-tester-page">
      <div className="banner-tester-label">Banner Tester</div>

      <SearchPageBannerTester />

      <Divider />

      <InboxPageBannerTester />

      <Divider />

      <LivePageBannerTester />

      <Divider />

      <ProfileOverviewPageBannerTester />

      <Divider />

      <div className="leaderboard-page-banner-container">
        <div className="page-label">Leaderboard Page</div>

        <div className="profile-overview-page-banner">
          <Banner type={"leaderboard-livestreaming-normal"} view={"stack"} />
          <Banner type={"leaderboard-private-call-normal"} view={"stack"} />
          <Banner type={"leaderboard-casino-normal"} view={"stack"} />
          <Banner type={"leaderboard-livestreaming-pk"} view={"stack"} />
        </div>
      </div>
    </div>
  );
};

export default BannerTester;
