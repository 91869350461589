// General
import "./livestream-profile-view-viewer-info-profile-border.scss";
// Redux
import { useSelector } from "react-redux";
// Components
import LivestreamLiveViewer from "./livestream-live-viewer/livestream-live-viewer";

const LivestreamProfileViewViewerInfoProfileBorder = () => {
  // Redux variables
  const profileBorderImage = useSelector(
    (state) => state.profileBorder.profileBorderImage
  );

  return (
    <div className="livestream-profile-view-viewer-info-profile-border">
      <div className="livestream-profile-view-viewer-info-label">
        Livestream Live Viewer
      </div>
      <div className="mobile-desktop-view-label">Mobile/Desktop View</div>

      <div className="mobile-view">
        <LivestreamLiveViewer
          profilePhoto={null}
          role={null}
          livestreamingAchievementBadge={null}
          username={"BabyA"}
          levellingBadge={
            "https://image.sgrbk.com/assets/levelling/badges/v1/level_21.png"
          }
          // Utility
          profileBorder={profileBorderImage}
        />

        <LivestreamLiveViewer
          profilePhoto={null}
          role={null}
          livestreamingAchievementBadge={null}
          username={"BabyA"}
          levellingBadge={
            "https://image.sgrbk.com/assets/levelling/badges/v1/level_21.png"
          }
          // Utility
          profileBorder={profileBorderImage}
        />
      </div>

      <div className="livestream-profile-view-viewer-info-settings-container">
        <div>Settings</div>
      </div>
    </div>
  );
};

export default LivestreamProfileViewViewerInfoProfileBorder;
