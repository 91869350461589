// General
import "./global-settings.scss";
import { useState, useEffect } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateCardBorderEnabled,
  updateCardBorderImage,
} from "../../../../redux/store/cardBorderStore";
import {
  updateHostProfilePicture,
  updateCoHostProfilePicture,
} from "../../../../redux/store/coAnchorCardStore";
// Material UI
import { Button, IconButton, FormControlLabel, Switch } from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";

const GlobalSettings = () => {
  // General variables
  const [imageData, setImageData] = useState(null);
  const [coHostImageData, setCoHostImageData] = useState(null);

  // Redux variables
  const cardBorderEnabled = useSelector(
    (state) => state.cardBorder.cardBorderEnabled
  );
  const dispatch = useDispatch();

  // Event Handlers | Button
  const onFilePicker = async (event) => {
    const file = event.target.files[0];

    if (file !== undefined) {
      try {
        const imageData = await readFile(file);
        setImageData((prevState) => ({
          ...prevState,
          image: imageData,
          file: file,
        }));
        dispatch(updateHostProfilePicture(imageData));
      } catch (error) {
        console.log(error);
      }
    }
  };
  const onCoHostFilePicker = async (event) => {
    const file = event.target.files[0];

    if (file !== undefined) {
      try {
        const imageData = await readFile(file);
        setCoHostImageData((prevState) => ({
          ...prevState,
          image: imageData,
          file: file,
        }));
        dispatch(updateCoHostProfilePicture(imageData));
      } catch (error) {
        console.log(error);
      }
    }
  };

  // Event Handlers | MUI Switch
  const onShowProfileBorder = () => {
    dispatch(updateCardBorderEnabled(!cardBorderEnabled));
  };

  // Utility Functions
  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  return (
    <div id="user-border-global-settings-subcomponent">
      <div className="global-settings-label">Global Settings </div>

      <div className="user-border-content">
        <div className="user-border-preview-container">
          <div className="user-border-preview-label-container">
            <IconButton
              className="profile-img-button"
              disableRipple
              component="label"
            >
              <input
                hidden
                accept="image/*"
                type="file"
                onChange={onFilePicker}
              />
              <UploadIcon />
            </IconButton>

            <div className="user-border-preview-label">Preview</div>
          </div>

          <div className="user-border-preview-image-container">
            <img className="user-border-preview" src={imageData?.image} />
          </div>
        </div>

        <div className="user-border-preview-container">
          <div className="user-border-preview-label-container">
            <IconButton
              className="profile-img-button"
              disableRipple
              component="label"
            >
              <input
                hidden
                accept="image/*"
                type="file"
                onChange={onCoHostFilePicker}
              />
              <UploadIcon />
            </IconButton>

            <div className="user-border-preview-label">Preview</div>
          </div>

          <div className="user-border-preview-image-container">
            <img className="user-border-preview" src={coHostImageData?.image} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalSettings;
