// General
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // General variables
  currentPage: "dashboard",
  navigationHistory: [],
};

export const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    // General Functions
    updateCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    updateNavigationHistory: (state, action) => {
      state.navigationHistory.unshift(action.payload);

      if (state.navigationHistory.length > 10) {
        state.navigationHistory.pop();
      }
    },

    // Utility Functions
    resetNavigationStore: () => {
      return { ...initialState };
    },
  },
});

export const {
  // General Functions
  updateCurrentPage,
  updateNavigationHistory,

  // Utility Functions
  resetNavigationStore,
} = navigationSlice.actions;

export default navigationSlice.reducer;
