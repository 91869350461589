// General
import "./live-element.scss";
// Static Data
import userConst from "../../../../../../const/userConst";
import routeConst from "../../../../../../const/routeConst";
// Redux
import { useDispatch } from "react-redux";
import {
  updatePublicPhotos,
  updatePrivatePhotos,
  updateShouldCallApi,
} from "../../../../../../redux/store/userCardStore";
import {
  updateNotificationIsSeen,
  updateShowNotificationBadge,

  // Utility Functions
  updateCloseNotificationTabPassthrough,
} from "../../../../../../redux/store/notificationStore";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../../../utility/manager/icon-manager/icon-manager";
// Components
import CustomAvatar from "../../../../../shared/elements/custom-avatar/custom-avatar";

const LiveElement = (props) => {
  const {
    notificationId,
    id,
    idInt,
    channelId,
    channelStatus,
    profilePhoto,
    publicPhotos,
    privatePhotos,
    username,
    isVerifiedProfile,
    membershipType,
    isLock,
    timestamp,
    isSeen,
    // Utility
    profileBorder,
  } = props;

  // Redux variables
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Event Handlers | Button
  const onViewUserProfile = () => {
    markAsSeen();
    onCloseNotificationTab();

    dispatch(updatePublicPhotos(publicPhotos));
    dispatch(updatePrivatePhotos(privatePhotos));
    dispatch(updateShouldCallApi(true));
    onNavigate(`${routeConst.profile.view.clearPath}${id}`);
  };
  const onRedirectToLivestream = () => {
    markAsSeen();
    onCloseNotificationTab();

    onNavigate(`${routeConst.live.liveId.clearPath}${channelId}`);
  };

  // Utility Functions
  const markAsSeen = () => {
    const obj = {
      notificationId,
      isSeen: true,
    };
    dispatch(updateNotificationIsSeen(obj));
    setTimeout(() => {
      dispatch(updateShowNotificationBadge());
    }, 1000);
  };
  const onCloseNotificationTab = () => {
    dispatch(updateCloseNotificationTabPassthrough());
  };

  return (
    <div
      id="notification-live-element-subcomponent"
      className={isSeen ? "" : "notification-unseen"}
    >
      <div className="avatar-container" onClick={onViewUserProfile}>
        <CustomAvatar
          className="profile-photo"
          src={profilePhoto}
          isBlur={isLock}
          isLock={isLock}
          profileBorder={profileBorder}
        />
      </div>

      <div className="live-body-container" onClick={onViewUserProfile}>
        <span className="username">{username || "-"}</span>{" "}
        {membershipType !== userConst.membershipType.free && (
          <span className="premium-mini-badge-container">
            {getIcon("premiumMiniBadge", "premium-mini-badge")}
          </span>
        )}
        {isVerifiedProfile && (
          <span className="notification-verified-profile-badge-container">
            {getIcon(
              "verifiedProfileBadge",
              "notification-verified-profile-badge"
            )}
          </span>
        )}
        {t("notification.user_is_live_now")}{" "}
        <span className="timestamp">{timestamp}</span>
        {false && <span className="red-dot"></span>}
      </div>

      {false && channelStatus === "live" && (
        <div className="button-container">
          <div className="watch-now-button" onClick={onRedirectToLivestream}>
            {t("notification.watch_now")}
          </div>
        </div>
      )}
    </div>
  );
};

export default LiveElement;
