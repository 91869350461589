// General
import "./user-card.scss";
import { useEffect } from "react";
import { Link } from "react-router-dom";
// Services
import {
  spiApi,
  useLazyGetPrivateCallStandbyPreJoinQuery,
  useFavoriteUnfavoriteUserMutation,
} from "../../../../services/data.service";
import { sessionService } from "../../../../services/session.service";
import { dashToUnderscore } from "../../../../services/regex.service";
// Static Data
import routeConst from "../../../../const/routeConst";
import userCardConst from "../../../../const/userCardConst";
import userConst from "../../../../const/userConst";
import searchConst from "../../../../const/searchConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateLocalSearchList } from "../../../../redux/store/searchStore";
import { updateLocalLivestreamList } from "../../../../redux/store/livestreamingStore";
import { updateLocalVideoCallList } from "../../../../redux/store/privateCallStore";
import {
  updateBusyInPrivateDialog,
  updateGoPrivatePermissionDialog,
  updatePreJoinDialog,
  updatePreJoinSugarBabyWarningDialog,
  updateVerifiedCallerDescriptionDialog,
  updateUserProfileDialog,
  updateJoinPrivateAndroidWarningDialog,
} from "../../../../redux/store/dialogStore";
import {
  // User View
  updateId,
  updateIdInt,
  updateUsername,
  updateAge,
  updateLocation,
  updateMembershipType,
  updateVerifiedProfile,
  updateVerifiedCaller,
  updateLevellingBadge,
  updateLivestreamingAchievementBadge,
  updateProfilePhoto,
  updatePublicPhotos,
  updatePrivatePhotos,
  updateProfileAchievementBadge,
  updateTorTags,
  updateAboutMe,
  updateLookingFor,
  updateTagline,
  updateAnnualIncome,
  updateNetworth,
  updateOccupation,
  updateLifestyle,
  updateEducation,
  updateRelationship,
  updateSmoking,
  updateDrinking,
  updateHeight,
  updateBodyType,
  updateEthnicity,
  updateEyeColor,
  updateHairColor,
  updateChildren,
  // User Utility
  updateShouldCallApi,
  updatePrivatePhotoAbleToRequest,
  updatePrivatePhotoRequested,
  updatePrivatePhotoApproval,
  updateRequestedMyPrivatePhoto,
  updateApprovedMyPrivatePhoto,
  updateIsOnline,
  updateIsBlocked,
  updateIsSilentInteraction,
  updateIsFavorited,
  updateIsCallEnabled,
  updateIsSugarbaby,
  updateRole,
  updateProfileStatus,
} from "../../../../redux/store/userCardStore";
import {
  // Main Page Functions
  resetVideoCallSearchLists,

  // General Functions
  updateIsCaller,

  // Callee Functions
  updateCalleeId,
  updateCalleeUsername,
  updateCalleeProfilePhoto,
} from "../../../../redux/store/privateCallStore";
import {
  updateChannelId,
  updateLivestreamerId,
  updateLivestreamerIdInt,
  updateLivestreamerProfilePhoto,
  updateLivestreamerAchievements,
  updateLivestreamerUsername,
  updateLivestreamerLevellingBadge,
  updateLivestreamerFollowers,
  updateLivestreamerViewers,
  updateLivestreamerDiamonds,
  updateLivestreamerIsFollowing,
} from "../../../../redux/store/livestreamingStore";
import {
  updateErrorToast,
  updateWarningToast,
} from "../../../../redux/store/toastStore";
// react-device-detect
import { isAndroid } from "react-device-detect";
// Material UI
import { useMediaQuery } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../utility/manager/icon-manager/icon-manager";
// Components
import CustomAvatar from "../custom-avatar/custom-avatar";
import Spinner from "../spinner/spinner";
// import TermsOfRelationship from "../profile/terms-of-relationship/terms-of-relationship";

const UserCard = (props) => {
  const {
    // Settings
    type = searchConst.userCardDisplay.grid, // list, grid

    // User View
    id,
    idInt,
    username,
    age,
    location,
    membershipType,
    verifiedProfile,
    verifiedCaller,
    levellingBadge,
    livestreamingAchievementBadge,
    profilePhoto,
    publicPhotos,
    privatePhotos,
    torTags,
    profileAchievementBadge,
    aboutMe,
    lookingFor,
    tagline,
    annualIncome,
    networth,
    occupation,
    lifestyle,
    education,
    relationship,
    smoking,
    drinking,
    height,
    bodyType,
    ethnicity,
    eyeColor,
    hairColor,
    children,
    withinDistanceInKm,
    // User Utility
    profileStatus,
    privatePhotoAbleToRequest,
    privatePhotoRequested,
    privatePhotoApproval,
    requestedMyPrivatePhoto,
    approvedMyPrivatePhoto,
    isOnline,
    isBlocked,
    isSilentInteraction,
    isFavorited,
    isCallEnabled,
    isSugarbaby,
    role,
    // Livestream
    channelId,
    livestreamerId,
    followerCount,
    viewerCount,
    diamondCount,
    isFollowing,
    // Card Utility
    cardStatus,
    cardBorder,
    cardBorderGradient,
  } = props;

  // API variables
  const [
    getPrivateCallStandbyPreJoin,
    {
      data: getPrivateCallStandbyPreJoinData,
      error: getPrivateCallStandbyPreJoinErrorData,
      isFetching: getPrivateCallStandbyPreJoinFetching,
      isLoading: getPrivateCallStandbyPreJoinLoading,
      isSuccess: getPrivateCallStandbyPreJoinSuccess,
      isError: getPrivateCallStandbyPreJoinError,
    },
  ] = useLazyGetPrivateCallStandbyPreJoinQuery();
  const [
    favoriteUnfavoriteUser,
    {
      data: favoriteUnfavoriteUserData,
      error: favoriteUnfavoriteUserErrorData,
      isLoading: favoriteUnfavoriteUserLoading,
      isSuccess: favoriteUnfavoriteUserSuccess,
      isError: favoriteUnfavoriteUserError,
    },
  ] = useFavoriteUnfavoriteUserMutation();

  // Redux variables
  const dispatch = useDispatch();

  // MUI variables
  const cardBorderEnabled = useSelector(
    (state) => state.cardBorder.cardBorderEnabled
  );
  const isMobile = useMediaQuery("(max-width: 455px)");
  const mobileInterface = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Utility variables
  const regex = dashToUnderscore;

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Private Call Standby Pre Join API Response
  useEffect(() => {
    if (
      getPrivateCallStandbyPreJoinFetching ||
      getPrivateCallStandbyPreJoinLoading
    ) {
    } else if (getPrivateCallStandbyPreJoinSuccess) {
      switch (getPrivateCallStandbyPreJoinData?.status) {
        case 0:
          if (
            getPrivateCallStandbyPreJoinData?.data?.standby?.status === "busy"
          ) {
            // Callee is busy in another private call
            dispatch(updateBusyInPrivateDialog(true));

            // Reset Video Call List
            dispatch(resetVideoCallSearchLists());

            // Refetch Video Call List
            dispatch({
              type: `${spiApi.reducerPath}/invalidateTags`,
              payload: ["VideoCallSearch"],
            });
          } else {
            onOpenDialog("pre-join-private-call");
          }
          break;
        case -1:
          // Someone else is already in the private call
          const toastObj = {
            message: getPrivateCallStandbyPreJoinData?.message,
            autoClose: 3000,
          };
          dispatch(updateErrorToast(toastObj));

          // Reset Video Call List
          dispatch(resetVideoCallSearchLists());

          // Refetch Video Call List
          dispatch({
            type: `${spiApi.reducerPath}/invalidateTags`,
            payload: ["VideoCallSearch"],
          });
          break;
        case -99:
          // Kicked Out
          const warningToastObj = {
            message: getPrivateCallStandbyPreJoinData?.message,
            autoClose: 3000,
          };
          dispatch(updateWarningToast(warningToastObj));
          break;
        case -1003:
          // Sugar baby cannot join another sugarbaby's private call
          onOpenDialog("not-allow-sugar-baby");
          break;
        default:
          break;
      }
    } else if (getPrivateCallStandbyPreJoinError) {
      if (getPrivateCallStandbyPreJoinErrorData?.status === 401) {
        // onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getPrivateCallStandbyPreJoinFetching,
    getPrivateCallStandbyPreJoinLoading,
    getPrivateCallStandbyPreJoinSuccess,
    getPrivateCallStandbyPreJoinError,
  ]);

  // Lifecycle | Check for update | Favorite Unfavorite User API Response
  useEffect(() => {
    if (favoriteUnfavoriteUserLoading) {
    } else if (favoriteUnfavoriteUserSuccess) {
      switch (favoriteUnfavoriteUserData?.status) {
        case 1:
          dispatch(updateIsFavorited(!isFavorited));

          // Update local view
          const obj = {
            idInt,
            privatePhotoRequested,
            isBlocked,
            isFavorited: !isFavorited,
          };
          dispatch(updateLocalSearchList(obj));
          dispatch(updateLocalLivestreamList(obj));
          dispatch(updateLocalVideoCallList(obj));
          break;
        default:
          break;
      }
    } else if (favoriteUnfavoriteUserError) {
    }
  }, [
    favoriteUnfavoriteUserLoading,
    favoriteUnfavoriteUserSuccess,
    favoriteUnfavoriteUserError,
  ]);

  // Event Handlers | Button
  const onUserClick = (type) => {
    if (cardStatus === userCardConst.cardStatus.default) {
      switch (profileStatus) {
        case userCardConst.userProfileStatus.inPrivateAvailable:
        case userCardConst.userProfileStatus.inPrivateBusy:
          if (isAndroid) {
            // For Android devices using PWA, ask user to download Android APK
            dispatch(updateJoinPrivateAndroidWarningDialog(true));
          }

          dispatch(updateCalleeId(id));
          dispatch(updateCalleeUsername(username));
          dispatch(updateCalleeProfilePhoto(profilePhoto));

          const preJoinBody = {
            user_id: id,
          };
          getPrivateCallStandbyPreJoin(preJoinBody);
          break;
        case userCardConst.userProfileStatus.videoCall:
        case userCardConst.userProfileStatus.normal:
        case userCardConst.userProfileStatus.live:
          // User View
          dispatch(updateId(id));
          dispatch(updateIdInt(idInt));
          dispatch(updateUsername(username));
          dispatch(updateAge(age));
          dispatch(updateLocation(location));
          dispatch(updateMembershipType(membershipType));
          dispatch(updateVerifiedProfile(verifiedProfile));
          dispatch(updateVerifiedCaller(verifiedCaller));
          dispatch(updateLevellingBadge(levellingBadge));
          dispatch(
            updateLivestreamingAchievementBadge(livestreamingAchievementBadge)
          );
          dispatch(updateProfilePhoto(profilePhoto));
          dispatch(updatePublicPhotos(publicPhotos));
          dispatch(updatePrivatePhotos(privatePhotos));
          dispatch(updateProfileAchievementBadge(profileAchievementBadge));
          dispatch(updateTorTags(torTags));
          dispatch(updateAboutMe(aboutMe));
          dispatch(updateLookingFor(lookingFor));
          dispatch(updateTagline(tagline));
          dispatch(updateAnnualIncome(annualIncome));
          dispatch(updateNetworth(networth));
          dispatch(updateOccupation(occupation));
          dispatch(updateLifestyle(lifestyle));
          dispatch(updateEducation(education));
          dispatch(updateRelationship(relationship));
          dispatch(updateSmoking(smoking));
          dispatch(updateDrinking(drinking));
          dispatch(updateHeight(height));
          dispatch(updateBodyType(bodyType));
          dispatch(updateEthnicity(ethnicity));
          dispatch(updateEyeColor(eyeColor));
          dispatch(updateHairColor(hairColor));
          dispatch(updateChildren(children));
          // User Utility
          dispatch(updateShouldCallApi(true));
          dispatch(updateProfileStatus(profileStatus));
          dispatch(updatePrivatePhotoAbleToRequest(privatePhotoAbleToRequest));
          dispatch(updatePrivatePhotoRequested(privatePhotoRequested));
          dispatch(updatePrivatePhotoApproval(privatePhotoApproval));
          dispatch(updateRequestedMyPrivatePhoto(requestedMyPrivatePhoto));
          dispatch(updateApprovedMyPrivatePhoto(approvedMyPrivatePhoto));
          dispatch(updateIsBlocked(isBlocked ? true : false));
          dispatch(updateIsSilentInteraction(isSilentInteraction));
          dispatch(updateIsFavorited(isFavorited));
          dispatch(updateIsCallEnabled(isCallEnabled));
          dispatch(updateIsSugarbaby(isSugarbaby));
          dispatch(updateRole(role));

          // Navigate to Profile View
          if (type !== "link") {
            onNavigate(`${routeConst.profile.view.clearPath}${id}`);
          }
          // dispatch(updateUserProfileDialog(true)) // Dialog
          break;
        default:
          break;
      }
    } else {
      switch (profileStatus) {
        case userCardConst.userProfileStatus.live:
          dispatch(updateChannelId(channelId));
          dispatch(updateLivestreamerId(livestreamerId));
          dispatch(updateLivestreamerIdInt(idInt));
          dispatch(updateLivestreamerProfilePhoto(profilePhoto));
          dispatch(
            updateLivestreamerAchievements(livestreamingAchievementBadge)
          );
          dispatch(updateLivestreamerUsername(username));
          dispatch(updateLivestreamerLevellingBadge(levellingBadge));
          dispatch(updateLivestreamerFollowers(followerCount));
          dispatch(updateLivestreamerViewers(viewerCount));
          dispatch(updateLivestreamerDiamonds(diamondCount));
          dispatch(updateLivestreamerIsFollowing(isFollowing));

          onNavigate(`${routeConst.live.liveId.clearPath}${channelId}`);
          break;
        case userCardConst.userProfileStatus.inPrivateAvailable:
        case userCardConst.userProfileStatus.inPrivateBusy:
          if (isAndroid) {
            // For Android devices using PWA, ask user to download Android APK
            dispatch(updateJoinPrivateAndroidWarningDialog(true));
          }

          dispatch(updateCalleeId(id));
          dispatch(updateCalleeUsername(username));
          dispatch(updateCalleeProfilePhoto(profilePhoto));

          const preJoinBody = {
            user_id: id,
          };
          getPrivateCallStandbyPreJoin(preJoinBody);
          break;
        case userCardConst.userProfileStatus.videoCall:
        case userCardConst.userProfileStatus.normal:
          // User View
          dispatch(updateId(id));
          dispatch(updateIdInt(idInt));
          dispatch(updateUsername(username));
          dispatch(updateAge(age));
          dispatch(updateLocation(location));
          dispatch(updateMembershipType(membershipType));
          dispatch(updateVerifiedProfile(verifiedProfile));
          dispatch(updateVerifiedCaller(verifiedCaller));
          dispatch(updateLevellingBadge(levellingBadge));
          dispatch(
            updateLivestreamingAchievementBadge(livestreamingAchievementBadge)
          );
          dispatch(updateProfilePhoto(profilePhoto));
          dispatch(updatePublicPhotos(publicPhotos));
          dispatch(updatePrivatePhotos(privatePhotos));
          dispatch(updateProfileAchievementBadge(profileAchievementBadge));
          dispatch(updateTorTags(torTags));
          dispatch(updateAboutMe(aboutMe));
          dispatch(updateLookingFor(lookingFor));
          dispatch(updateTagline(tagline));
          dispatch(updateAnnualIncome(annualIncome));
          dispatch(updateNetworth(networth));
          dispatch(updateOccupation(occupation));
          dispatch(updateLifestyle(lifestyle));
          dispatch(updateEducation(education));
          dispatch(updateRelationship(relationship));
          dispatch(updateSmoking(smoking));
          dispatch(updateDrinking(drinking));
          dispatch(updateHeight(height));
          dispatch(updateBodyType(bodyType));
          dispatch(updateEthnicity(ethnicity));
          dispatch(updateEyeColor(eyeColor));
          dispatch(updateHairColor(hairColor));
          dispatch(updateChildren(children));
          // User Utility
          dispatch(updateShouldCallApi(true));
          dispatch(updateProfileStatus(profileStatus));
          dispatch(updatePrivatePhotoAbleToRequest(privatePhotoAbleToRequest));
          dispatch(updatePrivatePhotoRequested(privatePhotoRequested));
          dispatch(updatePrivatePhotoApproval(privatePhotoApproval));
          dispatch(updateRequestedMyPrivatePhoto(requestedMyPrivatePhoto));
          dispatch(updateApprovedMyPrivatePhoto(approvedMyPrivatePhoto));
          dispatch(updateIsBlocked(isBlocked ? true : false));
          dispatch(updateIsSilentInteraction(isSilentInteraction));
          dispatch(updateIsFavorited(isFavorited));
          dispatch(updateIsCallEnabled(isCallEnabled));
          dispatch(updateIsSugarbaby(isSugarbaby));
          dispatch(updateRole(role));

          // Navigate to Profile View
          if (type !== "link") {
            onNavigate(`${routeConst.profile.view.clearPath}${id}`);
          }
          // dispatch(updateUserProfileDialog(true)) // Dialog
          break;
        default:
          break;
      }
    }
  };
  const onFavoriteProfile = () => {
    const obj = {
      user_id: id,
      favorite: !isFavorited,
    };
    favoriteUnfavoriteUser(obj);
  };
  const onOpenVerifiedCallerDialog = () => {
    dispatch(updateVerifiedCallerDescriptionDialog(true));
  };

  // Event Handlers | MUI Dialog
  const onOpenDialog = (view) => {
    if (view === "go-private-permission") {
      dispatch(updateIsCaller(true));
      dispatch(updateGoPrivatePermissionDialog(true));
    } else if (view === "pre-join-private-call") {
      dispatch(updateIsCaller(true));
      dispatch(updatePreJoinDialog(true));
    } else if (view === "not-allow-sugar-baby") {
      dispatch(updateIsCaller(false));
      dispatch(updatePreJoinSugarBabyWarningDialog(true));
    }
  };

  // Utility Functions
  const convertToMultiLanguage = (output, index) => {
    let result = "-";

    if (output && output !== "-") {
      // Convert to lower case except height
      let outputLowerCase;
      if (index !== 6 && index !== 11) {
        outputLowerCase = output.toLowerCase();
      }

      switch (index) {
        case 0: // occupation
          result = output;
          break;
        case 1: // lifestyle
          result = t(
            `filter.opt_lifestyle.${outputLowerCase.replace(regex, "_")}`
          );
          break;
        case 2: // education
          result = t(
            `filter.opt_education.${outputLowerCase.replace(regex, "_")}`
          );
          break;
        case 3: // relationship
          result = t(
            `filter.opt_relationship.${outputLowerCase.replace(regex, "_")}`
          );
          break;
        case 4: // smoking
          result = t(
            `filter.opt_smoking.${outputLowerCase.replace(regex, "_")}`
          );
          break;
        case 5: // drinking
          result = t(
            `filter.opt_drinking.${outputLowerCase.replace(regex, "_")}`
          );
          break;
        case 6: // height
          result = `${output} cm`;
          break;
        case 7: // bodyType
          result = t(
            `filter.opt_body_type.${outputLowerCase.replace(regex, "_")}`
          );
          break;
        case 8: // ethnicity
          result = t(
            `filter.opt_ethnicity.${outputLowerCase.replace(regex, "_")}`
          );
          break;
        case 9: // eyeColor
          result = t(`filter.opt_color.${outputLowerCase.replace(regex, "_")}`);
          break;
        case 10: // hairColor
          result = t(`filter.opt_color.${outputLowerCase.replace(regex, "_")}`);
          break;
        case 11: // children
          result = output;
          break;
        default:
          break;
      }
    }

    return result;
  };

  return (
    <div id="user-card-shared-subcomponent">
      {type === searchConst.userCardDisplay.grid && (
        <div
          className={`relative ${
            profileStatus === userCardConst.userProfileStatus.inPrivateAvailable
              ? "border-gradient"
              : ""
          }`}
          style={{
            background: `linear-gradient(90deg, ${cardBorderGradient?.one}, ${cardBorderGradient?.two} 70%, ${cardBorderGradient?.three}) padding-box, linear-gradient(90deg, ${cardBorderGradient?.one}, ${cardBorderGradient?.two} 70%, ${cardBorderGradient?.three}) border-box`,
            backgroundSize: "600% 600%",
          }}
        >
          <div
            className={`search-card-container ${
              cardBorderEnabled && cardBorder ? "reduce-user-card" : ""
            }`}
          >
            <div className="profile-image-container">
              <CustomAvatar
                className="profile-image"
                src={profilePhoto}
                role={role}
              />
            </div>

            <div className="description-container">
              {cardStatus === userCardConst.cardStatus.default ? (
                <Link
                  className="top-description-container"
                  to={`${routeConst.profile.view.clearPath}${id}`}
                  onClick={() => onUserClick("link")}
                >
                  {/* User Status - Live */}
                  {profileStatus === userCardConst.userProfileStatus.live && (
                    <div className="live">
                      <div className="live-status">
                        {t("search.badge.live")}
                      </div>
                      <div className="viewer-count-container">
                        <div className="eye-logo-container">
                          <VisibilityIcon className="eye-logo" />
                        </div>
                        <div className="viewer-count">{viewerCount || 0}</div>
                      </div>
                    </div>
                  )}

                  {/* User Status - Available for Private Call */}
                  {profileStatus ===
                    userCardConst.userProfileStatus.inPrivateAvailable && (
                    <div className="private-call-available">
                      {t("search.badge.private_room")}

                      {/* Verified Caller Badge */}
                      {verifiedCaller && (
                        <div
                          className="verified-caller-badge-container"
                          onClick={onOpenVerifiedCallerDialog}
                        >
                          {getIcon(
                            "verifiedCallerBadge",
                            "verified-caller-badge"
                          )}
                        </div>
                      )}
                    </div>
                  )}

                  {/* User Status - Busy in Private Call */}
                  {profileStatus ===
                    userCardConst.userProfileStatus.inPrivateBusy && (
                    <div className="private-call-busy">
                      {t("search.badge.busy_in_private")}

                      {/* Verified Caller Badge */}
                      {verifiedCaller && (
                        <div className="verified-caller-badge-container">
                          {getIcon(
                            "verifiedCallerBadge",
                            "verified-caller-badge"
                          )}
                        </div>
                      )}
                    </div>
                  )}

                  {/* User Status - Video Call */}
                  {profileStatus ===
                    userCardConst.userProfileStatus.videoCall && (
                    <div className="video-call">
                      {t("search.badge.video_call")}
                    </div>
                  )}
                </Link>
              ) : (
                <div
                  className="top-description-container"
                  onClick={() => onUserClick("default")}
                >
                  {/* User Status - Live */}
                  {profileStatus === userCardConst.userProfileStatus.live && (
                    <div className="live">
                      <div className="live-status">
                        {t("search.badge.live")}
                      </div>
                      <div className="viewer-count-container">
                        <div className="eye-logo-container">
                          <VisibilityIcon className="eye-logo" />
                        </div>
                        <div className="viewer-count">{viewerCount || 0}</div>
                      </div>
                    </div>
                  )}

                  {/* User Status - Available for Private Call */}
                  {profileStatus ===
                    userCardConst.userProfileStatus.inPrivateAvailable && (
                    <div className="private-call-available">
                      {t("search.badge.private_room")}

                      {/* Verified Caller Badge */}
                      {verifiedCaller && (
                        <div
                          className="verified-caller-badge-container"
                          onClick={onOpenVerifiedCallerDialog}
                        >
                          {getIcon(
                            "verifiedCallerBadge",
                            "verified-caller-badge"
                          )}
                        </div>
                      )}
                    </div>
                  )}

                  {/* User Status - Busy in Private Call */}
                  {profileStatus ===
                    userCardConst.userProfileStatus.inPrivateBusy && (
                    <div className="private-call-busy">
                      {t("search.badge.busy_in_private")}

                      {/* Verified Caller Badge */}
                      {verifiedCaller && (
                        <div className="verified-caller-badge-container">
                          {getIcon(
                            "verifiedCallerBadge",
                            "verified-caller-badge"
                          )}
                        </div>
                      )}
                    </div>
                  )}

                  {/* User Status - Video Call */}
                  {profileStatus ===
                    userCardConst.userProfileStatus.videoCall && (
                    <div className="video-call">
                      {t("search.badge.video_call")}
                    </div>
                  )}
                </div>
              )}

              <div className="bottom-description-container">
                <div className="left-detail-container">
                  {withinDistanceInKm && (
                    <div className="within-distance-container">
                      <div className="pin-icon-container">
                        <FmdGoodIcon className="pin-icon" />
                      </div>

                      <div className="distance-in-km-label">
                        {t("profile.n_km_away", { n: withinDistanceInKm })}
                      </div>
                    </div>
                  )}

                  {livestreamingAchievementBadge?.length > 0 && (
                    <div className="livestreaming-achievements-badge-container">
                      {livestreamingAchievementBadge?.map(
                        (achievement, index) => (
                          <img
                            className="livestreaming-achievements-badge"
                            src={achievement?.url}
                            key={index}
                          />
                        )
                      )}
                    </div>
                  )}

                  <div className="username-description">
                    <div className="username">
                      {username || ""}
                      {age && <span>,</span>}
                    </div>
                    {age && <div className="age">{age || ""}</div>}
                    {isOnline && <div className="status"></div>}

                    {/* Verified Profile */}
                    {verifiedProfile && (
                      <div className="verified-profile-badge-container">
                        {getIcon(
                          "verifiedProfileBadge",
                          "verified-profile-badge"
                        )}
                      </div>
                    )}

                    {/* Premium Mini Badge */}
                    {(membershipType === userConst.membershipType.premium ||
                      membershipType === userConst.membershipType.freePremium ||
                      membershipType === userConst.membershipType.diamond) && (
                      <div className="premium-mini-badge-container">
                        {getIcon("premiumMiniBadge", "premium-mini-badge")}
                      </div>
                    )}
                  </div>

                  <div className="location-description">
                    <div className="location">{location || ""}</div>
                    {levellingBadge && (
                      <div className="badge-container">
                        <img
                          className="badge"
                          src={levellingBadge}
                          alt="badge"
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className="right-detail-container">
                  {favoriteUnfavoriteUserLoading ? (
                    <Spinner size={20} isPadding={false} />
                  ) : (
                    <div
                      className="favorite-icon-container"
                      onClick={onFavoriteProfile}
                    >
                      {isFavorited
                        ? getIcon("favoritedUserCardIcon", "favorite-icon")
                        : getIcon("favoriteWhiteAltIcon", "favorite-icon")}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {cardBorderEnabled && cardBorder && (
            <div className="custom-card-border-container">
              <img className="custom-card-border" src={cardBorder} />
            </div>
          )}
        </div>
      )}

      {type === searchConst.userCardDisplay.list && (
        <div className="search-list-container">
          {/* Profile Photo */}
          <div
            className={`photo-container ${isMobile ? "larger" : ""}`}
            onClick={() => onUserClick("default")}
          >
            <div className="profile-photo-container">
              <CustomAvatar
                className="profile-photo"
                src={profilePhoto}
                role={role}
              />

              {favoriteUnfavoriteUserLoading ? (
                <Spinner size={20} />
              ) : (
                <div
                  className="favorite-icon-container"
                  onClick={onFavoriteProfile}
                >
                  {isFavorited
                    ? getIcon("favoritedUserCardIcon", "favorite-icon")
                    : getIcon("favoriteWhiteAltIcon", "favorite-icon")}
                </div>
              )}
            </div>
          </div>

          {/* Profile Details */}
          <div className={`details-container ${isMobile ? "smaller" : ""}`}>
            <div className="basic-details-container">
              <div className="details-container">
                <div className="name-age-container">
                  {/* Username */}
                  <div className="username">{username || "-"},</div>

                  {/* Age */}
                  <div className="age">{age || "-"}</div>

                  {/* Verified Profile */}
                  {verifiedProfile && (
                    <div className="verified-profile-badge-container">
                      {getIcon(
                        "verifiedProfileBadge",
                        "verified-profile-badge"
                      )}
                    </div>
                  )}

                  {/* Verified Caller */}
                  {verifiedCaller && (
                    <div className="verified-caller-badge-container">
                      {getIcon("verifiedCallerBadge", "verified-caller-badge")}
                    </div>
                  )}

                  {/* Premium Mini Badge */}
                  {(membershipType === userConst.membershipType.premium ||
                    membershipType === userConst.membershipType.freePremium ||
                    membershipType === userConst.membershipType.diamond) && (
                    <div className="premium-mini-badge-container">
                      {getIcon("premiumMiniBadge", "premium-mini-badge")}
                    </div>
                  )}

                  <span className="spacer"></span>

                  {withinDistanceInKm && (
                    <div className="within-distance-container">
                      <div className="pin-icon-container">
                        <FmdGoodIcon className="pin-icon" />
                      </div>

                      <div className="distance-in-km-label">
                        {t("profile.n_km_away", { n: withinDistanceInKm })}
                      </div>
                    </div>
                  )}
                </div>

                {/* Location */}
                <div className="location">{location || "-"}</div>

                {/* About Me */}
                <div className="tagline">{tagline || "-"}</div>
              </div>
            </div>

            <div
              className={`indept-details-container ${isMobile ? "column" : ""}`}
            >
              <div className={`left-container ${isMobile ? "full-width" : ""}`}>
                {/* Height */}
                <div className="height-container">
                  <div className="height-label">{t("profile.height")}:</div>
                  <div className="height-value">{height || "-"}</div>
                </div>

                {/* Body Weight */}
                <div className="body-weight-container">
                  <div className="body-weight-label">
                    {t("profile.body_type")}:
                  </div>
                  <div className="body-weight-value">
                    {convertToMultiLanguage(bodyType, 7) || "-"}
                  </div>
                </div>
              </div>

              <div
                className={`right-container ${isMobile ? "full-width" : ""}`}
              >
                {/* Ethnicity */}
                <div className="ethnicity-container">
                  <div className="ethnicity-label">
                    {t("profile.ethnicity")}:
                  </div>
                  <div className="ethnicity-value">
                    {convertToMultiLanguage(ethnicity, 8) || "-"}
                  </div>
                </div>

                {/* Lifestyle */}
                <div className="lifestyle-container">
                  <div className="lifestyle-label">
                    {t("profile.lifestyle")}:
                  </div>
                  <div className="lifestyle-value">
                    {convertToMultiLanguage(lifestyle, 1) || "-"}
                  </div>
                </div>
              </div>
            </div>

            <div className="tor-details-container">
              {/* <TermsOfRelationship
                enableTitle={false}
                isShared={true}
                sharedTorTags={torTags}
              /> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserCard;
