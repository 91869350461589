// General
import "./livestream-live-leaderboard-profile-border.scss";
// Redux
import { useSelector } from "react-redux";
// Components
import LivestreamLiveLeaderboard from "./livestream-live-leaderboard/livestream-live-leaderboard";

const LivestreamLiveLeaderboardProfileBorder = () => {
  // Redux variables
  const profileBorderImage = useSelector(
    (state) => state.profileBorder.profileBorderImage
  );

  return (
    <div className="livestream-live-leaderboard-profile-border">
      <div className="livestream-live-leaderboard-label">
        Livestream Live Leaderboard
      </div>
      <div className="mobile-desktop-view-label">Mobile/Desktop View</div>

      <div className="mobile-view">
        <LivestreamLiveLeaderboard
          index={0}
          profilePhoto={null}
          livestreamingAchievementBadge={null}
          username={"BabyA"}
          levellingBadge={
            "https://image.sgrbk.com/assets/levelling/badges/v1/level_21.png"
          }
          verifiedProfile={false}
          verifiedCaller={false}
          userType={"streamer"}
          amount={10000}
          // Utility
          profileBorder={profileBorderImage}
        />

        <LivestreamLiveLeaderboard
          index={2}
          profilePhoto={null}
          livestreamingAchievementBadge={null}
          username={"King Daddy"}
          levellingBadge={
            "https://image.sgrbk.com/assets/levelling/badges/v1/level_21.png"
          }
          verifiedProfile={false}
          verifiedCaller={false}
          userType={"viewer"}
          amount={10000}
          // Utility
          profileBorder={profileBorderImage}
        />
      </div>

      <div className="livestream-live-leaderboard-settings-container">
        <div>Settings</div>
      </div>
    </div>
  );
};

export default LivestreamLiveLeaderboardProfileBorder;
