// General
import "./co-anchor-card.scss";
import { useState } from "react";
// Redux
import { useSelector } from "react-redux";
// Material UI
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
// Components
import GlobalSettings from "./global-settings/global-settings";
import UserCardManager from "../user-card/user-card-manager/user-card-manager";

const CoAnchorCard = () => {
  const hostProfilePicture = useSelector(
    (state) => state.coAnchorCard.hostProfilePicture
  );
  const coHostProfilePicture = useSelector(
    (state) => state.coAnchorCard.coHostProfilePicture
  );

  // General variables
  const [userCardList, setUserCardList] = useState([
    {
      type: "co_anchor",
      hostProfilePhoto: hostProfilePicture,
      coHostProfilePhoto: coHostProfilePicture,
    },
    {
      type: "co_anchor",
      hostProfilePhoto: coHostProfilePicture,
      coHostProfilePhoto: hostProfilePicture,
    },
    {
      type: "gaming",
      backgroundImage:
        "https://image.sgrbk.com/assets/gaming/casino_profile-v3.jpg",
    },
  ]);

  // Redux variables
  const cardBorderImage = useSelector(
    (state) => state.cardBorder.cardBorderImage
  );

  // Event Handlers | Button
  const onAddUserCard = () => {
    setUserCardList((prevState) => [...prevState, { type: "user" }]);
  };
  const onAddGamingCard = () => {
    setUserCardList((prevState) => [...prevState, { type: "gaming" }]);
  };
  const onFilePicker = async (event, index) => {
    const file = event.target.files[0];

    if (file !== undefined) {
      try {
        const imageData = await readFile(file);
        setUserCardList((prevState) => {
          const newState = [...prevState];
          newState[index].backgroundImage = imageData;
          return newState;
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  // Utility Functions
  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  return (
    <div id="co-anchor-card-page">
      <div className="max-width-container">
        <div className="user-card-padding-container">
          <div className="user-card-border-title">User Card Border</div>

          <GlobalSettings />

          <div className="infinite-scroller-container">
            {userCardList?.map((user, index) => (
              <div className="list-container" key={index}>
                <UserCardManager
                  type={user?.type}
                  username={"Honey B"}
                  age={"50"}
                  isOnline={true}
                  location={"Johor Bahru"}
                  hostProfilePhoto={
                    index === 0 ? hostProfilePicture : coHostProfilePicture
                  }
                  coHostProfilePhoto={
                    index === 0 ? coHostProfilePicture : hostProfilePicture
                  }
                  profilePhoto={user?.backgroundImage}
                  backgroundImage={user?.backgroundImage}
                  cardBorder={cardBorderImage}
                />

                <Button
                  className="add-background-image-container"
                  component="label"
                >
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={(file) => onFilePicker(file, index)}
                  />
                </Button>
              </div>
            ))}

            <div className="add-card-container">
              <div className="add-user-card" onClick={onAddUserCard}>
                <AddIcon />
                Add user card
              </div>

              <div className="add-gaming-card" onClick={onAddGamingCard}>
                <AddIcon />
                Add gaming card
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoAnchorCard;
