// General
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  hostProfilePicture: null,
  coHostProfilePicture: null,
};

export const coAnchorCardSlice = createSlice({
  name: "coAnchorCard",
  initialState,
  reducers: {
    updateHostProfilePicture: (state, action) => {
      state.hostProfilePicture = action.payload;
    },
    updateCoHostProfilePicture: (state, action) => {
      state.coHostProfilePicture = action.payload;
    },

    // Utility Functions
    resetCoAnchorCardStore: () => {
      return { ...initialState };
    },
  },
});

export const {
  updateHostProfilePicture,
  updateCoHostProfilePicture,

  // Utility Functions
  resetCoAnchorCardStore,
} = coAnchorCardSlice.actions;

export default coAnchorCardSlice.reducer;
