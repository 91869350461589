// General
import "./test-tools.scss";
// Static Data
import utilityConst from "../../../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateLivestreamTestToolsDialog } from "../../../../../redux/store/dialogStore";
// Material UI
import HandymanIcon from "@mui/icons-material/Handyman";

const TestTools = () => {
  // Redux variables
  const releaseChannel = useSelector((state) => state.app.releaseChannel);
  const dispatch = useDispatch();

  // Event Handlers | Button
  const onOpenTestTools = () => {
    dispatch(updateLivestreamTestToolsDialog(true));
  };

  if (
    process.env["REACT_APP_ENVIRONMENT"] === "development" ||
    releaseChannel !== utilityConst.releaseChannel.stable
  ) {
    return (
      <div id="test-tools-subcomponent">
        <div
          className="livestream-test-icon-container"
          onClick={onOpenTestTools}
        >
          <HandymanIcon className="livestream-test-icon" />
        </div>
      </div>
    );
  }
};

export default TestTools;
