// General
import "./profile-info.scss";
// Static Data
import userConst from "../../../../../const/userConst";
// Custom Hooks
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
import useGetMembershipBadge from "../../../../utility/custom-hooks/useGetMembershipBadge-hook";
// Component
import CustomAvatar from "../../../../shared/elements/custom-avatar/custom-avatar";

const ProfileInfo = (props) => {
  const {
    profilePhoto,
    username,
    membershipType,
    levellingBadge,
    role,
    // Utility
    profileBorder,
  } = props;

  // Custom Hooks Functions
  const getIcon = IconManager();
  const getMembershipBadge = useGetMembershipBadge();

  return (
    <div id="overview-profile-info-subcomponent">
      <div className="profile-photo-container">
        <CustomAvatar
          className="profile-photo"
          src={profilePhoto}
          role={role}
          profileBorder={profileBorder}
        />
      </div>

      <div className="username-container">
        <div className="name">{username}</div>
        {(membershipType === userConst.membershipType.premium ||
          membershipType === userConst.membershipType.freePremium) &&
          getIcon("premiumMiniBadge", "premium-mini-badge")}
      </div>

      <div className="badge-container">
        {(membershipType === userConst.membershipType.premium ||
          membershipType === userConst.membershipType.freePremium) && (
          <div className="premium-badge-container">
            <img
              className="premium-badge"
              src={getMembershipBadge(membershipType)}
            />
          </div>
        )}

        {levellingBadge && (
          <div className="levelling-badge-container">
            <img className="levelling-badge" src={levellingBadge} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileInfo;
