// General
import "./App.scss";
// Components
import I18nMounted from "./components/utility/app-mounted/i18n-mounted/i18n-mounted";
import MomentjsMounted from "./components/utility/app-mounted/momentjs-mounted/momentjs-mounted";
import ApiMounted from "./components/utility/app-mounted/api-mounted/api-mounted";
import CheckLoginMounted from "./components/utility/app-mounted/check-login-mounted/check-login-mounted";
import GoogleMapsMounted from "./components/utility/app-mounted/google-maps-mounted/google-maps-mounted";
import PusherMounted from "./components/utility/app-mounted/pusher-mounted/pusher-mounted";
import UtilityMounted from "./components/utility/app-mounted/utility-mounted/utility-mounted";
import ToastNotificationManager from "./components/utility/manager/toast-notification-manager/toast-notification-manager";
import GlobalDialogMounted from "./components/utility/app-mounted/global-dialog-mounted/global-dialog-mounted";
import GlobalToastMounted from "./components/utility/app-mounted/global-toast-mounted/global-toast-mounted";
import SideNavbar from "./components/shared/elements/navbar/side-navbar/side-navbar";
import AppBar from "./components/shared/elements/app-bar/app-bar";
import NavBar from "./components/shared/elements/navbar/navbar";
import AppRouting from "./components/app-routing/app-routing";
import BottomNavbar from "./components/shared/elements/bottom-navbar/bottom-navbar";
import FloatingActionButton from "./components/shared/floating-action-button/floating-action-button";

function App() {
  return (
    <div id="App" className="App">
      {/* Shadow Components */}
      <I18nMounted />
      <MomentjsMounted />
      <ApiMounted />
      <CheckLoginMounted />
      {/* <GoogleMapsMounted /> */}
      <PusherMounted />
      <UtilityMounted />

      {/* Manager */}
      <ToastNotificationManager />

      {/* Viewable Front End Starts Here */}
      {/* Global Dialog */}
      <GlobalDialogMounted />

      {/* Global Toast */}
      <GlobalToastMounted />

      <NavBar />

      <SideNavbar type={"floating"} />

      <AppRouting />

      {/* Floating Action Button */}
      <FloatingActionButton />
    </div>
  );
}

export default App;
