// General
import "./livestream-live-leaderboard.scss";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import CustomAvatar from "../../../../shared/elements/custom-avatar/custom-avatar";
import Spinner from "../../../../shared/elements/spinner/spinner";

const LivestreamLiveLeaderboard = (props) => {
  const {
    index,
    profilePhoto,
    livestreamingAchievementBadge,
    username,
    levellingBadge,
    verifiedProfile,
    verifiedCaller,
    userType,
    amount,
    // Utility
    profileBorder,
  } = props;

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Event Handlers | Button
  const onFollowUser = () => {};

  return (
    <div id="leaderboard-user-shared-subcomponent">
      <div className="trophy-container">
        {index === 0 && getIcon("trophyRank1Badge", "trophy")}
        {index === 1 && getIcon("trophyRank2Badge", "trophy")}
        {index === 2 && getIcon("trophyRank3Badge", "trophy")}
      </div>

      <CustomAvatar
        className="profile-picture"
        src={profilePhoto}
        profileBorder={profileBorder}
        // onClick={onViewProfile}
      />

      <div className="user-description-container">
        <div className="top-container">
          {livestreamingAchievementBadge?.length > 0 && (
            <div className="livestreaming-achievement-badge-container">
              {livestreamingAchievementBadge?.map((badge, index) => (
                <img
                  className="livestreaming-achievement-badge"
                  src={badge?.url}
                  alt={badge?.type}
                  key={index}
                />
              ))}
            </div>
          )}

          <div className="username">{username}</div>
          <div className="badge-container">
            {levellingBadge && (
              <div className="level-container">
                <img
                  className="level-badge"
                  src={levellingBadge}
                  alt="levelling-badge"
                />
              </div>
            )}

            {verifiedProfile && (
              <div className="verified-profile-container">
                {getIcon("verifiedProfileBadge", "verified-profile-badge")}
              </div>
            )}

            {verifiedCaller && (
              <div
                className="verified-caller-container"
                // onClick={onOpenVerifiedCallerDialog}
              >
                {getIcon("verifiedCallerBadge", "verified-caller-badge")}
              </div>
            )}
          </div>
        </div>

        <div className="bottom-container">
          <div className="diamond-icon-container">
            {userType === "streamer"
              ? getIcon("sbDiamondIcon", "diamond-icon")
              : getIcon("sbCoinIcon", "diamond-icon")}
          </div>

          {userType === "streamer" ? (
            <div className="diamond-count">{amount?.toLocaleString()}</div>
          ) : (
            <div className="coin-count">{amount?.toLocaleString()}</div>
          )}
        </div>
      </div>

      <span className="spacer"></span>

      <div className="follow-button" onClick={onFollowUser}>
        {false ? <Spinner size={20} isPadding={false} /> : t("common.follow")}
      </div>
    </div>
  );
};

export default LivestreamLiveLeaderboard;
