// General
import "./video-player.scss";
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
// Redux
import { useSelector, useDispatch } from "react-redux";
// Material UI
import { useMediaQuery } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
// Custom Hooks
import useIsMounted from "../../../utility/custom-hooks/useIsMounted-hook";
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import AssetManager from "../../../utility/manager/asset-manager/asset-manager";

const VideoPlayer = () => {
  // General variables
  const [hls, setHls] = useState(null);
  const [hasInteraction, setHasInteraction] = useState(true);
  const videoRef = useRef(null);
  const isMounted = useRef(false);

  // Redux variables
  const videoPlayerMute = useSelector(
    (state) => state.livestreaming.videoPlayerMute
  );
  const playVideoPassthrough = useSelector(
    (state) => state.livestreaming.playVideoPassthrough
  );
  const livestreamPusherEvent = useSelector(
    (state) => state.livestreaming.livestreamPusherEvent
  );
  const dispatch = useDispatch();

  // MUI variables
  const isDesktop = useMediaQuery("(min-width: 1305px)");
  const isTablet = useMediaQuery("(min-width: 900px)");
  const isMobile = useMediaQuery("(max-width: 900px)");

  // Router variables
  const { id } = useParams();

  // Custom Hooks Functions
  const mounted = useIsMounted();
  const onNavigate = useCustomNavigate();
  const getAsset = AssetManager();

  // Lifecycle | Unmounted
  useEffect(() => {
    return () => {
      if (!mounted()) {
        // Destroy hls.js on unmounted to prevent memory leak
        if (hls) {
          hls.destroy();
        }

        // Destroy video player
        if (videoRef.current) {
          videoRef.current.pause();
          videoRef.current.removeAttribute("src");
          videoRef.current.load();
        }
      }
    };
  }, [mounted]);

  // Lifecycle | Check for update | livestreamPusherEvent
  useEffect(() => {
    if (!livestreamPusherEvent) return;

    switch (livestreamPusherEvent?.type) {
      case "viewer_kicked":
      case "kicked":
        videoRef.current.pause();
        break;
      default:
        break;
    }
  }, [livestreamPusherEvent]);

  // Lifecycle | Check for update | playVideoPassthrough
  useEffect(() => {
    if (isMounted.current) {
      if (playVideoPassthrough) {
        videoRef.current?.play();
      }
    } else {
      isMounted.current = true;
    }
  }, [playVideoPassthrough]);

  return (
    <div
      id="livestream-video-player-subcomponent"
      className={!isMobile ? "desktop-video-player" : "mobile-video-player"}
    >
      <video
        id="live-video"
        className={!isMobile ? "maintain-aspect-ratio" : "video-covered"}
        ref={videoRef}
        playsInline
        src={getAsset("livestreamSampleVideo")}
        autoPlay={true}
        loop={true}
        muted={videoPlayerMute}
      ></video>

      {hasInteraction && (
        <div className="play-overlay-container">
          <PlayArrowIcon />
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;
