// General
import "./gift-animation-test-element.scss";
import { useState, useRef } from "react";
// Redux
import { useDispatch } from "react-redux";
import { updateLivestreamGiftAnimationTrigger } from "../../../../../redux/store/debugStore";
import { updateLivestreamTestToolsDialog } from "../../../../../redux/store/dialogStore";
// Material UI
import { Button } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";

const GiftAnimationTestElement = () => {
  // General variables
  const giftAnimationVideoRef = useRef(null);
  const [giftAnimationData, setGiftAnimationData] = useState(null);
  const [isVideoSourceSupported, setIsVideoSourceSupported] = useState(null);

  // Redux variables
  const dispatch = useDispatch();

  // Event Handlers | Button
  const onPlayGiftAnimation = () => {
    if (!giftAnimationData) return;

    const obj = {
      video: giftAnimationData?.video,
    };
    dispatch(updateLivestreamGiftAnimationTrigger(obj));
    onCloseDialog();
  };
  const onFilePicker = async (event) => {
    const file = event.target.files[0];

    if (file !== undefined) {
      try {
        const videoData = await readFile(file);
        setGiftAnimationData((prevState) => ({
          ...prevState,
          video: videoData,
          file: file,
        }));

        // Check if the video can be played on this browser
        const canPlay = giftAnimationVideoRef.current.canPlayType(file.type);
        if (canPlay === "") {
          setIsVideoSourceSupported(false);
        } else {
          setIsVideoSourceSupported(true);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const onPlayGiftAnimationPreviewVideo = () => {
    if (!giftAnimationData) return;

    giftAnimationVideoRef.current.play();
  };
  const onPauseGiftAnimationPreviewVideo = () => {
    if (!giftAnimationData) return;

    giftAnimationVideoRef.current.pause();
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateLivestreamTestToolsDialog(false));
  };

  // Utility Functions
  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  return (
    <div id="gift-animation-test-container">
      {/* <div className="king-of-the-month-label">Gift Animation</div> */}

      <div className="gift-animation-preview">
        <div className="gift-animation-preview-center">
          <video
            id="test-gift-animation-video-player"
            ref={giftAnimationVideoRef}
            src={giftAnimationData?.video}
            playsInline
            loop={true}
            muted={true}
            controls={true}
          ></video>
        </div>
      </div>

      <div className="upload-gift-animation-container">
        <Button className="profile-img-button" disableRipple component="label">
          <input hidden accept="video/*" type="file" onChange={onFilePicker} />
          <div>Upload Video</div>
        </Button>
      </div>

      {giftAnimationData && (
        <div className="video-source-file-supported-container">
          {isVideoSourceSupported
            ? "✅ This browser support uploaded video file format"
            : isVideoSourceSupported === false
            ? "❌ This browser does not support uploaded video file format"
            : ""}
        </div>
      )}

      <div className="gift-animation-note">
        Note: Safari uses .mov and the rest (chrome, firefox, etc) uses .webm.
        Designers are required to provide at least .mov and .webm file so that
        it will work with react/PWA. Please consult developers for more details.
      </div>

      <div className="gift-animation-preview-controls-container">
        <div
          className="play-gift-animation-button"
          onClick={onPlayGiftAnimationPreviewVideo}
        >
          <PlayArrowIcon />
        </div>

        <div
          className="pause-gift-animation-button"
          onClick={onPauseGiftAnimationPreviewVideo}
        >
          <PauseIcon />
        </div>
      </div>

      <div
        className={
          giftAnimationData ? "test-animation-button" : "disabled-button"
        }
        onClick={onPlayGiftAnimation}
      >
        Play Animation
      </div>
    </div>
  );
};

export default GiftAnimationTestElement;
