// General
import "./gift-element.scss";
// Static Data
import livestreamingConst from "../../../../../const/livestreamingConst";
// Redux
import { useSelector } from "react-redux";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import CustomAvatar from "../../custom-avatar/custom-avatar";

const GiftElement = (props) => {
  const {
    livestreamingAchievementBadge,
    profilePhoto,
    username,
    levellingBadge,
    giftName,
    giftValue,
    giftImage,

    // Style
    backgroundColor = "none",
    font = livestreamingConst.config.giftElement.font,
    textColor = livestreamingConst.config.giftElement.desktopFontColor,

    // Testing Style
    border = "none",
    borderRadius = "0px",
    textStroke = "unset",
    textShadow = "none",

    // Utility
    profileBorder,
  } = props;

  // Redux variables
  const isDaddy = useSelector((state) => state.user.isDaddy);

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  return (
    <div
      id="chat-section-gift-element-subcomponent"
      style={{
        background: backgroundColor,
        border: border,
        borderRadius: borderRadius,
        WebkitTextStroke: textStroke,
        textShadow: textShadow,
      }}
    >
      <div className="profile-picture-container">
        <CustomAvatar
          className="profile-picture"
          src={profilePhoto}
          profileBorder={profileBorder}
        />
      </div>

      <div className="chat-info-container">
        <div className="user-info-container">
          {livestreamingAchievementBadge?.length > 0 && (
            <div className="livestreaming-achievement-badge-container">
              {livestreamingAchievementBadge?.map((badge, index) => (
                <img
                  className="livestreaming-achievement-badge"
                  src={badge?.url}
                  alt={badge?.type}
                  key={index}
                />
              ))}
            </div>
          )}

          <div className="username" style={{ font: font, color: textColor }}>
            {username || "-"}
          </div>

          {levellingBadge && (
            <div className="levelling-badge-container">
              <img
                className="levelling-badge"
                src={levellingBadge}
                alt="levelling-badge"
              />
            </div>
          )}
          <span className="sent-text" style={{ font: font, color: textColor }}>
            {t("common.sent_single")}
          </span>
        </div>

        <div className="gift-name" style={{ font: font, color: textColor }}>
          {giftName}
        </div>

        {!isDaddy && giftValue && (
          <div className="gift-diamond-count">
            <div className="diamond-icon-container">
              {getIcon("sbDiamondIcon", "diamond-icon")}
            </div>
            +{giftValue}
          </div>
        )}
      </div>

      <div className="gift-icon-container">
        <img className="gift-icon" src={giftImage} alt="gift" />
      </div>
    </div>
  );
};

export default GiftElement;
