// General
import "./livestream-mobile-topbar-profile-border.scss";
// Redux
import { useSelector } from "react-redux";
// Components
import LivestreamMobileTopbar from "./livestream-mobile-topbar/livestream-mobile-topbar";

const LivestreamMobileTopbarProfileBorder = () => {
  // Redux variables
  const profileBorderImage = useSelector(
    (state) => state.profileBorder.profileBorderImage
  );

  return (
    <div className="livestream-mobile-topbar-profile-border">
      <div className="livestream-mobile-topbar-label">
        Livestream Mobile Topbar
      </div>
      <div className="mobile-desktop-view-label">Mobile View</div>

      <div className="mobile-view">
        <LivestreamMobileTopbar
          livestreamerUsername={"BabyA"}
          livestreamerProfilePhoto={null}
          livestreamerViewers={10}
          livestreamerDiamonds={10000000}
          livestreamerIsFollowing={false}
          livestreamerLevellingBadge={
            "https://image.sgrbk.com/assets/levelling/badges/v1/level_21.png"
          }
          // Utility
          profileBorder={profileBorderImage}
        />
      </div>

      <div className="livestream-mobile-topbar-settings-container">
        <div>Settings</div>
      </div>
    </div>
  );
};

export default LivestreamMobileTopbarProfileBorder;
