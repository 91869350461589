// General
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  usersList: [],
  userMiniProfileData: null,

  // General variables
  isDaddy: null,
  isMommy: null,
  isSugarbaby: null,
  isSugarbabyMale: null,
  membershipType: null, // Using temporary because profile/me API membershipType is not accurate

  // Utility variables
  editProfileTab: 0,
  email: "",
  changeEmail: {
    oldEmail: "",
    newEmail: "",
  },

  // Follower variables
  followersTabValue: 0,
  followersList: [],
  followingList: [],
  giftersList: [],

  // Daily Check-in variables
  checkInInfo: null,

  // Photo Permission variables
  photoPermissionList: [],

  // Blocked Users variables
  blockedUsersList: [],

  // Location variables
  latitude: null,
  longitude: null,

  // Edit Profile variables
  editLocation: {
    lat: null,
    lng: null,
    location_data: {
      city: null,
      state: null,
      formatted_address: "",
      country: null,
      country_iso: null,
    },
    type: "user_defined",
  },

  // Utility variables
  editProfilePassthrough: {}, // Navbar -> Edit Profile
  saveVideoCallSettingsPassthrough: {}, // Navbar -> Video Call Customize Settings

  // API Response variables
  oldUserProfile: null, // Use only in edit profile
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // Users List Functions
    updateUsersList: (state, action) => {
      const usersData = [...state.usersList, ...action.payload];
      state.usersList = usersData;
    },
    addUser: (state, action) => {
      const usersData = [...state.usersList];
      usersData.push(action.payload);
      state.usersList = usersData;
    },
    removeUserById: (state, action) => {
      const idInt = action.payload;
      const usersData = [...state.usersList];
      const filteredUsersData = usersData.filter(
        (user) => user.id_int !== idInt
      );
      state.usersList = filteredUsersData;
    },

    // User Mini Profile Functions
    updateUserMiniProfileData: (state, action) => {
      state.userMiniProfileData = action.payload;
    },

    // General Functions
    updateIsDaddy: (state, action) => {
      state.isDaddy = action.payload;
    },
    updateIsMommy: (state, action) => {
      state.isMommy = action.payload;
    },
    updateIsSugarbaby: (state, action) => {
      state.isSugarbaby = action.payload;
    },
    updateIsSugarbabyMale: (state, action) => {
      state.isSugarbabyMale = action.payload;
    },
    updateMembershipType: (state, action) => {
      state.membershipType = action.payload;
    },

    // Utility Functions
    updateEditProfileTab: (state, action) => {
      state.editProfileTab = action.payload;
    },
    updateEmail: (state, action) => {
      state.email = action.payload;
    },
    updateOldEmail: (state, action) => {
      state.changeEmail.oldEmail = action.payload;
    },
    updateNewEmail: (state, action) => {
      state.changeEmail.newEmail = action.payload;
    },

    // Follower Functions
    updateFollowersTabValue: (state, action) => {
      state.followersTabValue = action.payload;
    },
    updateFollowersList: (state, action) => {
      const followersData = [...state.followersList, ...action.payload];
      const uniqueFollowersData = [];
      const hashTable = {};

      followersData.forEach((follower) => {
        if (!hashTable[follower.id]) {
          hashTable[follower.id] = true;
          uniqueFollowersData.push(follower);
        }
      });

      state.followersList = uniqueFollowersData;
    },
    updateFollowingList: (state, action) => {
      const followingData = [...state.followingList, ...action.payload];
      const uniqueFollowingData = [];
      const hashTable = {};

      followingData.forEach((following) => {
        if (!hashTable[following.id]) {
          hashTable[following.id] = true;
          uniqueFollowingData.push(following);
        }
      });
      state.followingList = uniqueFollowingData;
    },
    updateGiftersList: (state, action) => {
      const giftersData = [...state.giftersList, ...action.payload];
      const uniqueGiftersData = [];
      const hashTable = {};

      giftersData.forEach((gifter) => {
        if (!hashTable[gifter.id]) {
          hashTable[gifter.id] = true;
          uniqueGiftersData.push(gifter);
        }
      });

      state.giftersList = uniqueGiftersData;
    },
    resetFollowersList: (state) => {
      state.followersList = [];
    },
    resetFollowingList: (state) => {
      state.followingList = [];
    },
    resetGiftersList: (state) => {
      state.giftersList = [];
    },

    // Daily Check-in Functions
    updateCheckInInfo: (state, action) => {
      state.checkInInfo = action.payload;
    },

    // Blocked Users Functions
    updateBlockedUsersList: (state, action) => {
      const blockedUsersList = [...state.blockedUsersList, ...action.payload];

      state.blockedUsersList = blockedUsersList;
    },
    updateLocalBlockedUsersList: (state, action) => {
      const idInt = action.payload.idInt;
      const isBlocked = action.payload.isBlocked;

      let blockedUsersListArr = [...state.blockedUsersList];

      for (let i = 0; i < blockedUsersListArr.length; i++) {
        if (blockedUsersListArr[i].user.id_int === idInt) {
          if (isBlocked !== null) {
            blockedUsersListArr[i].user.interaction.is_blocked = isBlocked;
          }
          break;
        }
      }

      state.blockedUsersList = blockedUsersListArr;
    },
    resetBlockedUsersList: (state) => {
      state.blockedUsersList = [];
    },
    updateBlockedUsersPagination: (state, action) => {
      state.blockedUsersPagination = action.payload;
    },

    // Location Functions
    updateLatitude: (state, action) => {
      state.latitude = action.payload;
    },
    updateLongitude: (state, action) => {
      state.longitude = action.payload;
    },

    // UserProfile Functions
    updateUsername: (state, action) => {
      state.oldUserProfile.profile.username = action.payload;
      // state.userProfile.username = action.payload;
    },
    updateBirthday: (state, action) => {
      state.oldUserProfile.profile.birthday = action.payload;
      // state.userProfile.appearance.birth_date = action.payload;
    },
    updateTagline: (state, action) => {
      state.oldUserProfile.profile.tagline = action.payload;
      // state.userProfile.tagline = action.payload;
    },
    updateLifestyle: (state, action) => {
      state.oldUserProfile.profile.lifestyle = action.payload;
      // state.userProfile.occupation.lifestyle_budget = action.payload;
    },
    updateNetworth: (state, action) => {
      state.oldUserProfile.profile.networth = action.payload;
      // state.userProfile.occupation.net_worth = action.payload;
    },
    updateAnnualIncome: (state, action) => {
      state.oldUserProfile.profile.annualincome = action.payload;
      // state.userProfile.occupation.annual_income = action.payload;
    },
    updateLocation: (state, action) => {
      state.oldUserProfile.location = action.payload;
      // state.userProfile.location.full_address = action.payload;
    },
    updateHeight: (state, action) => {
      state.oldUserProfile.profiledetails.height = action.payload;
      // state.userProfile.appearance.height = action.payload;
    },
    updateBodyType: (state, action) => {
      state.oldUserProfile.profiledetails.bodytype = action.payload;
      // state.userProfile.appearance.body_type = action.payload;
    },
    updateEthnicity: (state, action) => {
      state.oldUserProfile.profiledetails.ethnicity = action.payload;
      // state.userProfile.appearance.ethnicity = action.payload;
    },
    updateRace: (state, action) => {
      state.oldUserProfile.profiledetails.race = action.payload;
    },
    updateEyeColor: (state, action) => {
      state.oldUserProfile.profiledetails.eyecolor = action.payload;
      // state.userProfile.appearance.eye_color = action.payload;
    },
    updateHairColor: (state, action) => {
      state.oldUserProfile.profiledetails.haircolor = action.payload;
      // state.userProfile.appearance.hair_color = action.payload;
    },
    updateOccupation: (state, action) => {
      state.oldUserProfile.profiledetails.occupation = action.payload;
      // state.userProfile.occupation.occupation = action.payload;
    },
    updateEducation: (state, action) => {
      state.oldUserProfile.profiledetails.education = action.payload;
      // state.userProfile.occupation.education = action.payload;
    },
    updateRelationship: (state, action) => {
      state.oldUserProfile.profiledetails.relationship = action.payload;
      // state.userProfile.relationship.relationship_status = action.payload;
    },
    updateChildren: (state, action) => {
      state.oldUserProfile.profiledetails.children = action.payload;
      // state.userProfile.relationship.children = action.payload;
    },
    updateSmoking: (state, action) => {
      state.oldUserProfile.profiledetails.smoking = action.payload;
      // state.userProfile.habit.smoking = action.payload;
    },
    updateDrinking: (state, action) => {
      state.oldUserProfile.profiledetails.drinking = action.payload;
      // state.userProfile.habit.drinking = action.payload;
    },
    updateAboutMe: (state, action) => {
      state.oldUserProfile.profile.aboutme = action.payload;
      // state.userProfile.aboutme = action.payload;
    },
    updateLookingFor: (state, action) => {
      state.oldUserProfile.profile.lookingfordetails = action.payload;
      // state.userProfile.lookingfor = action.payload;
    },

    // Edit Profile Functions
    updateEditLocation: (state, action) => {
      state.editLocation = action.payload;
    },
    updateEditLocationFormattedAddress: (state, action) => {
      state.editLocation.location_data.formatted_address = action.payload;
    },
    resetEditLocation: (state) => {
      state.editLocation = {
        lat: null,
        lng: null,
        location_data: {
          city: null,
          state: null,
          formatted_address: "",
          country: null,
          country_iso: null,
        },
        type: "user_defined",
      };
    },

    // API Response Functions
    updateOldUserProfile: (state, action) => {
      state.oldUserProfile = action.payload;
    },

    // Utility Functions
    resetUserStore: () => {
      return { ...initialState };
    },
    updateEditProfilePassthrough: (state, action) => {
      state.editProfilePassthrough = action.payload;
    },
    updateSaveVideoCallSettingsPassthrough: (state, action) => {
      state.saveVideoCallSettingsPassthrough = action.payload;
    },
    updateFollowState: (state, action) => {
      const userId = action.payload.userId;
      const isFollowing = action.payload.isFollowing;

      let followersListArr = [...state.followersList];
      let followingListArr = [...state.followingList];
      let giftersListArr = [...state.giftersList];

      // Followers
      for (let i = 0; i < followersListArr.length; i++) {
        if (followersListArr[i].id === userId) {
          followersListArr[i].live_streaming_meta.is_following = isFollowing;
        }
      }

      // Following
      for (let i = 0; i < followingListArr.length; i++) {
        if (followingListArr[i].id === userId) {
          followingListArr[i].live_streaming_meta.is_following = isFollowing;
        }
      }

      // Gifters
      for (let i = 0; i < giftersListArr.length; i++) {
        if (giftersListArr[i].id === userId) {
          giftersListArr[i].live_streaming_meta.is_following = isFollowing;
        }
      }

      state.followersList = followersListArr;
      state.followingList = followingListArr;
      state.giftersList = giftersListArr;
    },
    updateFollowerRemoveState: (state, action) => {
      const userId = action.payload.userId;

      let followersListArr = [...state.followersList];

      for (let i = 0; i < followersListArr.length; i++) {
        if (followersListArr[i].id === userId) {
          followersListArr.splice(i, 1);
        }
      }

      state.followersList = followersListArr;
    },
  },
});

export const {
  // Users List Functions
  updateUsersList,
  addUser,
  removeUserById,

  // Mini Profile Functions
  updateUserMiniProfileData,

  // General Functions
  updateIsDaddy,
  updateIsMommy,
  updateIsSugarbaby,
  updateIsSugarbabyMale,
  updateMembershipType,

  // Utility Functions
  updateEditProfileTab,
  updateEmail,
  updateOldEmail,
  updateNewEmail,

  // Follower Functions
  updateFollowersTabValue,
  updateFollowersList,
  updateFollowingList,
  updateGiftersList,
  resetFollowersList,
  resetFollowingList,
  resetGiftersList,

  // Daily Check-in Functions
  updateCheckInInfo,

  // Blocked Users Functions
  updateBlockedUsersList,
  updateLocalBlockedUsersList,
  resetBlockedUsersList,
  updateBlockedUsersPagination,

  // Location Functions
  updateLatitude,
  updateLongitude,

  // UserProfile Functions
  updateUsername,
  updateBirthday,
  updateTagline,
  updateLifestyle,
  updateNetworth,
  updateAnnualIncome,
  updateLocation,
  updateHeight,
  updateBodyType,
  updateEthnicity,
  updateRace,
  updateEyeColor,
  updateHairColor,
  updateOccupation,
  updateEducation,
  updateRelationship,
  updateChildren,
  updateSmoking,
  updateDrinking,
  updateAboutMe,
  updateLookingFor,

  // Edit Profile Functions
  updateEditLocation,
  updateEditLocationFormattedAddress,
  resetEditLocation,

  // API Response Functions
  updateOldUserProfile,

  // Utility Functions
  resetUserStore,
  updateEditProfilePassthrough,
  updateSaveVideoCallSettingsPassthrough,
  updateFollowState,
  updateFollowerRemoveState,
} = userSlice.actions;

export default userSlice.reducer;
