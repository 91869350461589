// General
import "./topbar.scss";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
// Static Data
import routeConst from "../../../../../const/routeConst";
import userConst from "../../../../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateLivestreamProfileViewDialog,
  updateLivestreamReportDialog,
} from "../../../../../redux/store/dialogStore";
import {
  updateLivestreamerViewers,
  updateLivestreamerIsFollowing,
  toggleVideoPlayerMute,
} from "../../../../../redux/store/livestreamingStore";
// Material UI
import CheckIcon from "@mui/icons-material/Check";
import AddIcon from "@mui/icons-material/Add";
// Custom Hooks
import useCustomNavigate from "../../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import CustomAvatar from "../../../../shared/elements/custom-avatar/custom-avatar";
import Spinner from "../../../../shared/elements/spinner/spinner";
import LivestreamProfileViewDialog from "../../../../shared/dialog-content/livestream-profile-view-dialog/livestream-profile-view-dialog";

const Topbar = (props) => {
  const {
    state,
    showProfile = true,
    showVolume = true,
    showReport = true,
    showBack = true,
  } = props;

  // Redux variables
  const livestreamerId = useSelector(
    (state) => state.livestreaming.livestreamerId
  );
  const livestreamerProfilePhoto = useSelector(
    (state) => state.livestreaming.livestreamerProfilePhoto
  );
  const livestreamerAchievements = useSelector(
    (state) => state.livestreaming.livestreamerAchievements
  );
  const livestreamerUsername = useSelector(
    (state) => state.livestreaming.livestreamerUsername
  );
  const livestreamerLevellingBadge = useSelector(
    (state) => state.livestreaming.livestreamerLevellingBadge
  );
  const livestreamerViewers = useSelector(
    (state) => state.livestreaming.livestreamerViewers
  );
  const livestreamerDiamonds = useSelector(
    (state) => state.livestreaming.livestreamerDiamonds
  );
  const livestreamerIsFollowing = useSelector(
    (state) => state.livestreaming.livestreamerIsFollowing
  );
  const videoPlayerMute = useSelector(
    (state) => state.livestreaming.videoPlayerMute
  );
  const dispatch = useDispatch();

  // Router variables
  const { id } = useParams();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Check for update | Viewer Count
  useEffect(() => {}, [livestreamerViewers]);

  // Event Handlers | Button
  const onViewProfile = () => {
    dispatch(updateLivestreamProfileViewDialog(true));
  };
  const onFollowLivestreamer = () => {
    const obj = {
      user_id: livestreamerId,
    };

    if (livestreamerIsFollowing) {
      // postUnfollowUser(obj);
    } else {
      // postFollowUser(obj);
    }
  };
  const onToggleMute = () => {
    dispatch(toggleVideoPlayerMute());
  };
  const onReportLivestream = () => {
    dispatch(updateLivestreamReportDialog(true));
  };
  const onBack = () => {
    if (state === "livestreamer") {
      console.log("livestreamer");
    } else {
      onNavigate(routeConst.dashboard.path);
    }
  };

  // Utility Functions
  const onGetDiamonds = (diamonds) => {
    if (diamonds >= 1000000000) {
      return `${(diamonds / 1000000000).toFixed(0)}B`;
    } else if (diamonds >= 1000000) {
      return `${(diamonds / 1000000).toFixed(0)}M`;
    } else if (diamonds >= 1000) {
      return `${(diamonds / 1000).toFixed(0)}K`;
    } else {
      return diamonds;
    }
  };

  return (
    <div id="livestream-interactable-overlay-topbar-subcomponent">
      <div className="user-container">
        {showProfile && (
          <div className="user-content-container">
            <div className="profile-picture-container">
              <CustomAvatar
                className="profile-picture"
                src={livestreamerProfilePhoto}
                role={userConst.userRole.sugarbaby}
                onClick={onViewProfile}
              />

              {livestreamerLevellingBadge && (
                <div className="levelling-badge-container">
                  <img
                    className="levelling-badge"
                    src={livestreamerLevellingBadge}
                    alt="levelling-badge"
                  />
                </div>
              )}
            </div>

            <div className="user-details-container">
              <div className="username-container">
                {livestreamerAchievements?.chat_badges_set?.length > 0 && (
                  <div className="achievement-container">
                    {livestreamerAchievements?.chat_badges_set?.map(
                      (badge, index) => (
                        <img
                          className="livestreamer-badge"
                          src={badge?.url}
                          key={index}
                        />
                      )
                    )}
                  </div>
                )}
                <div className="username">{livestreamerUsername || "-"}</div>
              </div>

              <div className="livestream-stats-container">
                <div className="viewer-count-container">
                  <div className="viewer-count-icon-container">
                    {getIcon("liveViewerIcon", "viewer-count-icon")}
                  </div>

                  <div className="viewer-count">{livestreamerViewers || 0}</div>
                </div>

                <div className="diamond-count-container">
                  <div className="diamond-icon-container">
                    {getIcon("sbDiamondIcon", "diamond-icon")}
                  </div>

                  <div className="diamond-count">
                    {onGetDiamonds(livestreamerDiamonds) || 0}
                  </div>
                </div>
              </div>
            </div>

            <div className="follow-button-container">
              <div className="follow-button" onClick={onFollowLivestreamer}>
                {true ? (
                  <Spinner size={20} isPadding={false} />
                ) : livestreamerIsFollowing ? (
                  <CheckIcon />
                ) : (
                  <AddIcon />
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="livestream-buttons-container">
        {showVolume && (
          <div className="volume-button-container" onClick={onToggleMute}>
            {videoPlayerMute
              ? getIcon("volumeMuteIcon", "volume-button")
              : getIcon("volumeUnmuteIcon", "volume-button")}
          </div>
        )}

        {showReport && (
          <div
            className="live-report-button-container"
            onClick={onReportLivestream}
          >
            {getIcon("liveReportIcon", "live-report-button")}
          </div>
        )}

        {showBack && (
          <div className="back-button-container" onClick={onBack}>
            {getIcon("liveBackIcon", "back-button")}
          </div>
        )}
      </div>

      <LivestreamProfileViewDialog />
    </div>
  );
};

export default Topbar;
