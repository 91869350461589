// General
import "./interest-user-list-profile-border.scss";
// Redux
import { useSelector } from "react-redux";
// Moment
import moment from "moment";
// Components
import InterestUserList from "./interest-user-list/interest-user-list";

const InterestUserListProfileBorder = () => {
  // Redux variables
  const profileBorderImage = useSelector(
    (state) => state.profileBorder.profileBorderImage
  );

  return (
    <div className="interest-user-list-profile-border">
      <div className="interest-user-list-label">Interest Page User List</div>
      <div className="mobile-desktop-view-label">Mobile/Desktop View</div>

      <div className="mobile-view">
        <InterestUserList
          isBlur={false}
          username={"King Daddy"}
          profilePhoto={null}
          role={"sd"}
          livestreamingAchievementBadge={null}
          verifiedProfile={false}
          verifiedCaller={false}
          membershipType={"premium"}
          timestamp={moment()}
          location={"Singapore"}
          // Utility
          profileBorder={profileBorderImage}
        />

        <InterestUserList
          isBlur={false}
          username={"King Daddy"}
          profilePhoto={null}
          role={"sd"}
          livestreamingAchievementBadge={null}
          verifiedProfile={false}
          verifiedCaller={false}
          membershipType={"premium"}
          timestamp={moment()}
          location={"Singapore"}
          // Utility
          profileBorder={profileBorderImage}
        />
      </div>

      <div className="interest-user-list-settings-container">
        <div>Settings</div>
      </div>
    </div>
  );
};

export default InterestUserListProfileBorder;
