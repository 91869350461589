// General
import "./livestream-chat-section-profile-border.scss";
// Redux
import { useSelector } from "react-redux";
// Components
import LivestreamChatSection from "./livestream-chat-section/livestream-chat-section";

const LivestreamChatSectionProfileBorder = () => {
  // Redux variables
  const profileBorderImage = useSelector(
    (state) => state.profileBorder.profileBorderImage
  );

  return (
    <div className="livestream-chat-section-profile-border">
      <div className="livestream-chat-section-label">
        Livestream/Private Call Chat Section
      </div>
      <div className="mobile-desktop-view-label">Mobile/Desktop View</div>

      <div className="mobile-view">
        <LivestreamChatSection
          state={"livestream"}
          livestreamerUsername={"King Daddy"}
          livestreamerIsFollowing={false}
          livestreamerProfilePhoto={null}
          message={{
            username: "King Daddy",
            user_photo: null,
          }}
          // Utility
          profileBorder={profileBorderImage}
        />
      </div>

      <div className="livestream-chat-section-settings-container">
        <div>Settings</div>
      </div>
    </div>
  );
};

export default LivestreamChatSectionProfileBorder;
