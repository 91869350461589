// General
import "./king-of-the-month-test-element.scss";
import { useState } from "react";
// Redux
import { useDispatch } from "react-redux";
import { updateLivestreamKingOfTheMonthTrigger } from "../../../../../redux/store/debugStore";
import { updateLivestreamTestToolsDialog } from "../../../../../redux/store/dialogStore";
// Material UI
import { Button, TextField, MenuItem } from "@mui/material";
// Components
import KingOfTheMonthElement from "../../../../livestream/simulate-livestream/slide-in-out-alert-overlay/king-of-the-month-element/king-of-the-month-element";

const KingOfTheMonthTestElement = () => {
  // General variables
  const [imageData, setImageData] = useState(null);
  const [username, setUsername] = useState("");
  const [description, setDescription] = useState("");
  const [showCenterLine, setShowCenterLine] = useState(true);
  const [animationDuration, setAnimationDuration] = useState(3);
  const [imageDimension, setImageDimension] = useState(null);

  // Constant variables
  const animationDurationData = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
  ];

  // Redux variables
  const dispatch = useDispatch();

  // Event Handlers | Button
  const onPlayAnimation = () => {
    if (!imageData) return;

    const obj = {
      profilePhoto: null,
      username,
      description,
      asset: imageData?.image,
      duration: animationDuration,
    };
    dispatch(updateLivestreamKingOfTheMonthTrigger(obj));
    onCloseDialog();
  };
  const onShowCenterLine = () => {
    setShowCenterLine((prevState) => !prevState);
  };
  const onFilePicker = async (event) => {
    const file = event.target.files[0];

    if (file !== undefined) {
      try {
        const imageData = await readFile(file);
        setImageData((prevState) => ({
          ...prevState,
          image: imageData,
          file: file,
        }));
      } catch (error) {
        console.log(error);
      }
    }
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateLivestreamTestToolsDialog(false));
  };

  // Event Handlers | MUI Text Field
  const onNameChange = (event) => {
    setUsername(event.target.value);
  };
  const onDescriptionChange = (event) => {
    setDescription(event.target.value);
  };
  const onAnimationDurationChange = (event) => {
    setAnimationDuration(event.target.value);
  };

  // Utility Functions
  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };
  const onGetImageDimension = (data) => {
    setImageDimension(data);
  };

  return (
    <div id="king-of-the-month-container">
      {/* <div className="king-of-the-month-label">King of the month</div> */}

      <div className="king-of-the-month-preview-container">
        <div className="king-of-the-month-preview">
          <KingOfTheMonthElement
            asset={imageData?.image}
            profilePhoto={null}
            username={username}
            description={description}
            showCenterLine={showCenterLine}
            animationDuration={0}
            // Callback
            onGetImageDimension={onGetImageDimension}
          />

          {imageDimension && (
            <div className="king-of-the-month-width">{`${imageDimension?.width}px`}</div>
          )}
          {imageDimension && (
            <div className="king-of-the-month-height">{`${imageDimension?.height}px`}</div>
          )}
        </div>
      </div>

      <div className="upload-king-of-the-month-container">
        <Button className="profile-img-button" disableRipple component="label">
          <input hidden accept="image/*" type="file" onChange={onFilePicker} />
          <div>Upload GIF</div>
        </Button>
      </div>

      <div className="show-center-line-button" onClick={onShowCenterLine}>
        {showCenterLine ? "Hide Center Line" : "Show Center Line"}
      </div>

      <div className="king-of-the-month-text">
        <div className="username-form-field-container">
          <TextField
            className="username-form-field"
            value={username}
            onChange={onNameChange}
            label="King of the Month Username"
            variant="outlined"
          />
        </div>

        <div className="username-form-field-container">
          <TextField
            className="username-form-field"
            value={description}
            onChange={onDescriptionChange}
            label="King of the Month Description"
            variant="outlined"
          />
        </div>
      </div>

      <div className="animation-duration-container">
        <div className="animation-duration-form-field-container">
          <TextField
            className="animation-duration-form-field"
            select
            label="Animation duration (seconds)"
            value={animationDuration}
            onChange={onAnimationDurationChange}
            variant="outlined"
          >
            {animationDurationData?.map((duration, index) => (
              <MenuItem value={duration} key={index}>
                {duration}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </div>

      <div
        className={imageData ? "test-animation-button" : "disabled-button"}
        onClick={onPlayAnimation}
      >
        Play Animation
      </div>
    </div>
  );
};

export default KingOfTheMonthTestElement;
