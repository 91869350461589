// General
import "./livestream-profile-view-user-info-profile-border.scss";
// Redux
import { useSelector } from "react-redux";
// Components
import LivestreamProfileViewUserInfo from "./livestream-profile-view-user-info/livestream-profile-view-user-info";

const LivestreamProfileViewUserInfoProfileBorder = () => {
  // Redux variables
  const profileBorderImage = useSelector(
    (state) => state.profileBorder.profileBorderImage
  );

  return (
    <div className="livestream-profile-view-user-info-profile-border">
      <div className="livestream-profile-view-user-info-label">
        Livestream Profile View User Info
      </div>
      <div className="mobile-desktop-view-label">Mobile View</div>

      <div className="mobile-view">
        <LivestreamProfileViewUserInfo
          livestreamerUsername={"BabyA"}
          livestreamerProfilePhoto={null}
          livestreamerViewers={10}
          livestreamerDiamonds={10000000}
          livestreamerIsFollowing={false}
          livestreamerLevellingBadge={
            "https://image.sgrbk.com/assets/levelling/badges/v1/level_21.png"
          }
          // Utility
          profileBorder={profileBorderImage}
        />
      </div>

      <div className="livestream-profile-view-user-info-settings-container">
        <div>Settings</div>
      </div>
    </div>
  );
};

export default LivestreamProfileViewUserInfoProfileBorder;
