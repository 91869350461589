// General
import "./livestream-chat-section.scss";
// Static Data
import livestreamingConst from "../../../../../const/livestreamingConst";
// Material UI
import { CircularProgress, useMediaQuery } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// i18next
import { useTranslation } from "react-i18next";
// Components
import CustomAvatar from "../../../../shared/elements/custom-avatar/custom-avatar";
import ChatElement from "../../../../shared/elements/chat-section/chat-element/chat-element";
import GiftElement from "../../../../shared/elements/chat-section/gift-element/gift-element";
import JoiningElement from "../../../../shared/elements/chat-section/joining-element/joining-element";
import FollowingElement from "../../../../shared/elements/chat-section/following-element/following-element";
import EmojiElement from "../../../../shared/elements/chat-section/emoji-element/emoji-element";

const LivestreamChatSection = (props) => {
  const {
    state = "livestream",
    livestreamerUsername,
    livestreamerIsFollowing,
    livestreamerProfilePhoto,
    message,
    // Utility
    profileBorder,
  } = props;

  // MUI variables
  const isDesktop = useMediaQuery("(min-width: 900px)");
  const isTablet = useMediaQuery("(min-width: 1305px)");
  const isMobile = useMediaQuery("(max-width: 900px)");

  // i18next variables
  const { t } = useTranslation();

  // Event Handlers | Button
  const onFollowLivestreamer = () => {};

  return (
    <div className="livestream-chat-container">
      <div
        className={`follow-label ${
          state === "livestream" && !isMobile ? "" : "yellow"
        }`}
      >
        {t("pusher.remind_follow", { name: livestreamerUsername })}
      </div>

      <div className="follow-button-container" onClick={onFollowLivestreamer}>
        {false ? (
          <CircularProgress className="following-loading-spinner" size={20} />
        ) : livestreamerIsFollowing ? (
          <CheckIcon />
        ) : (
          <AddIcon />
        )}

        <div className="text">
          {livestreamerIsFollowing
            ? t("live-follow-button.following")
            : t("live-follow-button.follow")}
        </div>

        <div className="livestreamer-profile-photo-container">
          <CustomAvatar
            className="livestreamer-profile-photo"
            src={livestreamerProfilePhoto}
            profileBorder={profileBorder}
          />
        </div>
      </div>

      <ChatElement
        livestreamingAchievementBadge={message?.chat_badges_set}
        profilePhoto={message?.user_photo}
        username={message?.username}
        levellingBadge={message?.levelling_profile?.badge}
        message={message?.data?.message}
        // Style
        usernameFont={livestreamingConst.config.chatElement.font}
        usernameColor={
          state === "livestream" && !isMobile
            ? livestreamingConst.config.chatElement.desktopFontColor
            : livestreamingConst.config.chatElement.mobileFontColor
        }
        chatFont={livestreamingConst.config.chatElement.font}
        chatColor={
          state === "livestream" && !isMobile
            ? livestreamingConst.config.chatElement.desktopFontColor
            : livestreamingConst.config.chatElement.mobileFontColor
        }
        // Utility
        profileBorder={profileBorder}
      />

      <GiftElement
        state={state}
        livestreamingAchievementBadge={message?.chat_badges_set}
        profilePhoto={message?.user_photo}
        username={message?.username}
        levellingBadge={message?.levelling_profile?.badge}
        giftName={message?.data?.gift_name}
        giftImage={message?.data?.gift_graphic}
        // Style
        backgroundColor={"none"}
        font={livestreamingConst.config.giftElement.font}
        textColor={
          !isMobile
            ? livestreamingConst.config.giftElement.desktopFontColor
            : livestreamingConst.config.giftElement.mobileFontColor
        }
        // Utility
        profileBorder={profileBorder}
      />

      <JoiningElement
        state={state}
        livestreamingAchievementBadge={message?.chat_badges_set}
        profilePhoto={message?.user_photo}
        username={message?.username}
        levellingBadge={message?.levelling_profile?.badge}
        // Style
        font={livestreamingConst.config.joiningElement.font}
        textColor={
          !isMobile
            ? livestreamingConst.config.joiningElement.desktopFontColor
            : livestreamingConst.config.joiningElement.mobileFontColor
        }
        // Utility
        profileBorder={profileBorder}
      />

      <FollowingElement
        livestreamingAchievementBadge={message?.chat_badges_set}
        profilePhoto={message?.user_photo}
        username={message?.username}
        levellingBadge={message?.levelling_profile?.badge}
        livestreamerUsername={livestreamerUsername}
        // Style
        font={livestreamingConst.config.followingElement.font}
        textColor={
          !isMobile
            ? livestreamingConst.config.followingElement.desktopFontColor
            : livestreamingConst.config.followingElement.mobileFontColor
        }
        // Utility
        profileBorder={profileBorder}
      />

      <EmojiElement
        state={state}
        livestreamingAchievementBadge={message?.chat_badges_set}
        profilePhoto={message?.user_photo}
        username={message?.username}
        levellingBadge={message?.levelling_profile?.badge}
        emoji={message?.data?.emoji_unicode}
        // Style
        font={livestreamingConst.config.emojiElement.font}
        textColor={
          !isMobile
            ? livestreamingConst.config.emojiElement.desktopFontColor
            : livestreamingConst.config.emojiElement.mobileFontColor
        }
        // Utility
        profileBorder={profileBorder}
      />
    </div>
  );
};

export default LivestreamChatSection;
