// General
import "./viewer-info.scss";
import { useState } from "react";
// React Swipeable Views
import SwipeableViews from "react-swipeable-views/lib/SwipeableViews";
// Material UI
import { Tabs, Tab, Box, useTheme } from "@mui/material";
import PropTypes from "prop-types";
// i18next
import { useTranslation } from "react-i18next";
// Components
import LiveTab from "./live-tab/live-tab";
import TopFansTab from "./top-fans-tab/top-fans-tab";

const ViewerInfo = (props) => {
  const { state } = props;

  // MUI variables
  const theme = useTheme();
  const [value, setValue] = useState(0);

  // i18next variables
  const { t } = useTranslation();

  // Event Handlers | MUI Tabs
  const onTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const onTabChangeBySwipe = (index) => {
    setValue(index);
  };

  return (
    <div
      id="livestream-profile-view-dialog-viewer-info-subcomponent"
      className={state === "dialog" ? "screen-height" : "fix-height"}
    >
      <div className="tab-container">
        <Tabs
          className="custom-tabs"
          value={value}
          onChange={onTabChange}
          TabIndicatorProps={{ style: { backgroundColor: "#710d0d" } }}
          variant="fullWidth"
        >
          <Tab label={t("mini-profile-tabs.live")} {...a11yProps(0)} />
          <Tab label={t("mini-profile-tabs.top_fans")} {...a11yProps(1)} />
        </Tabs>

        <SwipeableViews
          className="swipeable-container"
          containerStyle={{
            transition: "transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s",
          }}
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={onTabChangeBySwipe}
        >
          <TabPanel
            className="tab-panel"
            value={value}
            index={0}
            dir={theme.direction}
          >
            <LiveTab />
          </TabPanel>

          <TabPanel
            className="tab-panel"
            value={value}
            index={1}
            dir={theme.direction}
          >
            <TopFansTab />
          </TabPanel>
        </SwipeableViews>
      </div>
    </div>
  );
};

export default ViewerInfo;

// Material UI | Tab Functions
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }} className="tab-box-container">
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
