// General
import "./floating-action-button.scss";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateDrawerOpen } from "../../../redux/store/appStore";
// Material UI
import { Fab } from "@mui/material";
import IcecreamIcon from "@mui/icons-material/Icecream";
import routeConst from "../../../const/routeConst";

const FloatingActionButton = () => {
  // General variables
  const [showFab, setShowFab] = useState(false);

  // Redux variables
  const drawerOpen = useSelector((state) => state.app.drawerOpen);
  const dispatch = useDispatch();

  // Router variables
  const location = useLocation();

  // Lifecycle | Check for update | location
  useEffect(() => {
    if (
      location.pathname === routeConst.livestream.simulateLivestream.path ||
      location.pathname === routeConst.livestream.kingOfTheMonth.path ||
      location.pathname === routeConst.livestream.kingOfTheMonth.path
    ) {
      setShowFab(true);
    } else {
      setShowFab(false);
    }
  }, [location]);

  // Event Handlers | Button
  const onClickFab = () => {
    dispatch(updateDrawerOpen(!drawerOpen));
  };

  if (showFab) {
    return (
      <Fab id="drawer-floating-action-button" onClick={onClickFab}>
        <IcecreamIcon className="open-drawer-button" />
      </Fab>
    );
  }
};

export default FloatingActionButton;
