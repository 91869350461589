// General
import "./side-navbar.scss";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
// Static Data
import routeConst from "../../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateDrawerOpen } from "../../../../../redux/store/appStore";
import { updateUserMiniProfileData } from "../../../../../redux/store/userStore";
import { updateCurrentPage } from "../../../../../redux/store/navigationStore";
import {
  updateAddUserDialog,
  updateUserMiniProfileDialog,
} from "../../../../../redux/store/dialogStore";
// Material UI
import { Drawer, IconButton, Divider, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import LiveTvRoundedIcon from "@mui/icons-material/LiveTvRounded";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import WhatshotRoundedIcon from "@mui/icons-material/WhatshotRounded";
import SlideshowRoundedIcon from "@mui/icons-material/SlideshowRounded";
import AdUnitsRoundedIcon from "@mui/icons-material/AdUnitsRounded";
import AnimationRoundedIcon from "@mui/icons-material/AnimationRounded";
import AddIcon from "@mui/icons-material/Add";
import AccountBoxRoundedIcon from "@mui/icons-material/AccountBoxRounded";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
// Custom Hooks
import useCustomNavigate from "../../../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import CustomAvatar from "../../custom-avatar/custom-avatar";

const SideNavbar = (props) => {
  const { type } = props;

  // General variables
  const [showSideNavbar, setShowSideNavbar] = useState(false);

  // Render variables
  let navbarBody = <div></div>;

  // Redux variables
  const drawerOpen = useSelector((state) => state.app.drawerOpen);
  const currentPage = useSelector((state) => state.navigation.currentPage);
  const usersList = useSelector((state) => state.user.usersList);
  const dispatch = useDispatch();

  // MUI variables
  const theme = useTheme();

  // Router variables
  const location = useLocation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Check for update | location
  useEffect(() => {
    if (
      location.pathname === routeConst.livestream.simulateLivestream.path ||
      location.pathname === routeConst.livestream.kingOfTheMonth.path ||
      location.pathname === routeConst.livestream.kingOfTheMonth.path
    ) {
      setShowSideNavbar(false);
    } else {
      setShowSideNavbar(true);
    }
  }, [location]);

  // Event Handlers | Button
  const handleDrawerClose = () => {
    dispatch(updateDrawerOpen(false));
  };
  const onRedirect = (name, route) => {
    dispatch(updateCurrentPage(name));
    onNavigate(route);
  };
  const onAddUserDialog = () => {
    dispatch(updateAddUserDialog(true));
  };
  const onViewUser = (user, index) => {
    let obj = { ...user, index: index };

    dispatch(updateUserMiniProfileData(obj));
    dispatch(updateUserMiniProfileDialog(true));
  };

  // Utility Style
  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  }));

  // Render Functions
  navbarBody = (
    <div className="navbar-padding-container">
      <Divider />

      <div className="user-list-container">
        <div className="user-label">Profiles</div>

        <div className="user-list">
          <IconButton onClick={onAddUserDialog}>
            <AddIcon className="add-user-icon" />
          </IconButton>

          {usersList?.map((user, index) => (
            <div
              className="user-icon"
              onClick={() => onViewUser(user, index)}
              key={index}
            >
              <CustomAvatar src={user?.profile_photo?.original_photo} />
            </div>
          ))}
        </div>
      </div>

      <div className="navigation-button-container">
        {/* Dashboard */}
        <div
          className={`dashboard-button-container ${
            currentPage === routeConst.dashboard.name ? "selected-button" : ""
          }`}
          onClick={() =>
            onRedirect(routeConst.dashboard.name, routeConst.dashboard.path)
          }
        >
          <div className="dashboard-icon-container">
            <HomeRoundedIcon className="dashboard-icon" />
          </div>
          <div className="dashboard-label">{routeConst.dashboard.name}</div>
        </div>

        <div className="navbar-sub-label">{routeConst.user.name}</div>

        {/* Profile Photo Border */}
        <div
          className={`livestream-button-container ${
            currentPage === routeConst.user.profilePhotoBorder.name
              ? "selected-button"
              : ""
          }`}
          onClick={() =>
            onRedirect(
              routeConst.user.profilePhotoBorder.name,
              routeConst.user.profilePhotoBorder.path
            )
          }
        >
          <div className="livestream-icon-container">
            <PersonRoundedIcon className="livestream-icon" />
          </div>
          <div className="livestream-label">
            {routeConst.user.profilePhotoBorder.name}
          </div>
        </div>

        {/* User Card Border */}
        <div
          className={`livestream-button-container ${
            currentPage === routeConst.user.userCardV2.name
              ? "selected-button"
              : ""
          }`}
          onClick={() =>
            onRedirect(
              routeConst.user.userCardV2.name,
              routeConst.user.userCardV2.path
            )
          }
        >
          <div className="livestream-icon-container">
            <AccountBoxRoundedIcon className="livestream-icon" />
          </div>
          <div className="livestream-label">
            {routeConst.user.userCardV2.name}
          </div>
        </div>

        {/* User Card Border V2 */}
        <div
          className={`livestream-button-container ${
            currentPage === routeConst.user.userCard.name
              ? "selected-button"
              : ""
          }`}
          onClick={() =>
            onRedirect(
              routeConst.user.userCard.name,
              routeConst.user.userCard.path
            )
          }
        >
          <div className="livestream-icon-container">
            <AccountBoxRoundedIcon className="livestream-icon" />
          </div>
          <div className="livestream-label">
            {routeConst.user.userCard.name}
          </div>
        </div>

        {/* Co-Anchor Card */}
        <div
          className={`livestream-button-container ${
            currentPage === routeConst.user.coAnchorCard.name
              ? "selected-button"
              : ""
          }`}
          onClick={() =>
            onRedirect(
              routeConst.user.coAnchorCard.name,
              routeConst.user.coAnchorCard.path
            )
          }
        >
          <div className="livestream-icon-container">
            <AccountBoxRoundedIcon className="livestream-icon" />
          </div>
          <div className="livestream-label">
            {routeConst.user.coAnchorCard.name}
          </div>
        </div>

        <div className="navbar-sub-label">{routeConst.livestream.name}</div>

        <div
          className={`livestream-button-container ${
            currentPage === routeConst.livestream.simulateLivestream.name
              ? "selected-button"
              : ""
          }`}
          onClick={() =>
            onRedirect(
              routeConst.livestream.simulateLivestream.name,
              routeConst.livestream.simulateLivestream.path
            )
          }
        >
          <div className="livestream-icon-container">
            <LiveTvRoundedIcon className="livestream-icon" />
          </div>
          <div className="livestream-label">
            {routeConst.livestream.simulateLivestream.name}
          </div>
        </div>

        <div
          className={`livestream-button-container ${
            currentPage === routeConst.livestream.kingOfTheMonth.name
              ? "selected-button"
              : ""
          }`}
          onClick={() =>
            onRedirect(
              routeConst.livestream.kingOfTheMonth.name,
              routeConst.livestream.kingOfTheMonth.path
            )
          }
        >
          <div className="livestream-icon-container">
            <WhatshotRoundedIcon className="livestream-icon" />
          </div>
          <div className="livestream-label">
            {routeConst.livestream.kingOfTheMonth.name}
          </div>
        </div>

        <div
          className={`livestream-button-container ${
            currentPage === routeConst.livestream.giftAnimation.name
              ? "selected-button"
              : ""
          }`}
          onClick={() =>
            onRedirect(
              routeConst.livestream.giftAnimation.name,
              routeConst.livestream.giftAnimation.path
            )
          }
        >
          <div className="livestream-icon-container">
            <AnimationRoundedIcon className="livestream-icon" />
          </div>
          <div className="livestream-label">
            {routeConst.livestream.giftAnimation.name}
          </div>
        </div>

        <div className="navbar-sub-label">{routeConst.banner.name}</div>

        <div
          className={`dashboard-button-container ${
            currentPage === routeConst.banner.bannerTester.name
              ? "selected-button"
              : ""
          }`}
          onClick={() =>
            onRedirect(
              routeConst.banner.bannerTester.name,
              routeConst.banner.bannerTester.path
            )
          }
        >
          <div className="dashboard-icon-container">
            <AdUnitsRoundedIcon className="dashboard-icon" />
          </div>
          <div className="dashboard-label">
            {routeConst.banner.bannerTester.name}
          </div>
        </div>

        <div className="navbar-sub-label">{routeConst.coins.name}</div>

        <div
          className={`dashboard-button-container ${
            currentPage === routeConst.coins.coinPackageDesigner.name
              ? "selected-button"
              : ""
          }`}
          onClick={() =>
            onRedirect(
              routeConst.coins.coinPackageDesigner.name,
              routeConst.coins.coinPackageDesigner.path
            )
          }
        >
          <div className="dashboard-icon-container">
            <MonetizationOnIcon className="dashboard-icon" />
          </div>
          <div className="dashboard-label">
            {routeConst.coins.coinPackageDesigner.name}
          </div>
        </div>
      </div>
    </div>
  );

  // Render
  if (type === "floating") {
    return (
      <Drawer
        id="floating-side-navbar"
        anchor={"left"}
        open={drawerOpen}
        onClose={handleDrawerClose}
      >
        <DrawerHeader>
          <div className="brand-logo">Sugar Studio</div>

          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>

        {navbarBody}
      </Drawer>
    );
  } else if (type === "static") {
    if (showSideNavbar) {
      return (
        <div
          id="side-navbar"
          anchor={"left"}
          open={drawerOpen}
          onClose={handleDrawerClose}
        >
          <div className="brand-logo-container">
            <div className="brand-logo">Sugar Studio</div>
          </div>

          {navbarBody}
        </div>
      );
    }
  }
};

export default SideNavbar;
