// General
import "./messages-list.scss";
// Static Data
import userConst from "../../../../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateVerifiedCallerDescriptionDialog } from "../../../../../redux/store/dialogStore";
// Material UI
import { Divider, useMediaQuery } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useGetReadableDate from "../../../../utility/custom-hooks/useGetReadableDate-hook";
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import CustomAvatar from "../../../../shared/elements/custom-avatar/custom-avatar";

const MessagesList = (props) => {
  const {
    // User Data
    userId,
    userIdInt,
    username,
    age,
    location,
    membershipType,
    verifiedProfile,
    verifiedCaller,
    levellingBadge,
    profilePhoto,
    publicPhotos,
    privatePhotos,
    torTags,
    profileAchievementBadge,
    aboutMe,
    lookingFor,
    occupation,
    lifestyle,
    education,
    relationship,
    smoking,
    drinking,
    height,
    bodyType,
    ethnicity,
    eyeColor,
    hairColor,
    children,
    // User Utility
    shouldCallApi,
    privatePhotoAbleToRequest,
    privatePhotoRequested,
    privatePhotoApproval,
    requestedMyPrivatePhoto,
    approvedMyPrivatePhoto,
    isOnline,
    isBlocked,
    isSilentInteraction,
    isFavorited,
    isCallEnabled,
    isSugarbaby,
    role,

    // Conversation Data
    conversationId,
    conversationIdInt,
    livestreamingAchievementBadge,
    privateCallSettings,
    lastMessageContent,
    lastMessageTimestamp,
    lastMessageMine,
    lastMessageSeen,
    callStatus,
    callDuration,
    ownMembershipType,

    // Utility
    profileBorder,
  } = props;

  // Render variables
  let lastSeenView;

  // Redux variables
  const selectedConversation = useSelector(
    (state) => state.inbox.selectedConversation
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 850px)");

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getReadableDate = useGetReadableDate();
  const getIcon = IconManager();

  // Event Handlers | Button
  const onOpenVerifiedCallerDialog = () => {
    dispatch(updateVerifiedCallerDescriptionDialog(true));
  };

  // Utility Functions
  const getLastMessage = () => {
    if (callStatus) {
      // Backend does not provide translated text for call status, so FE will have to translate
      switch (callStatus) {
        case "rejected":
          return t("inbox.call_declined");
        case "cancelled":
          return t("inbox.call_cancelled");
        case "ended":
          return `${t("inbox.call_ended")} (${getCallDuration(callDuration)})`;
        case "missed":
          return t("inbox.call_not_answered");
        default:
          break;
      }
    } else if (
      lastMessageContent
        ?.toLowerCase()
        .includes("just given you access to my private photos")
    ) {
      // Backend does not provide translated text for private photos request, so FE will have to translate
      return t("inbox.i_have_just_given_you_access");
    } else if (
      lastMessageContent
        ?.toLowerCase()
        .includes("request to view your private photos")
    ) {
      // Backend does not provide translated text for private photos request, so FE will have to translate
      return t("inbox.request_to_view_your_private_photos");
    } else {
      const cleanMessage = lastMessageContent?.replace(/<br\s*\/?>/gi, " ");
      return cleanMessage;
    }
  };
  const getCallDuration = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  // Render Functions | Last Seen View
  if (ownMembershipType === userConst.membershipType.free) {
    // Free membership users can only see if the last message was seen or not
    if (!lastMessageMine && !lastMessageSeen) {
      lastSeenView = <div className="read"></div>;
    }
  } else {
    if (!lastMessageMine && !lastMessageSeen) {
      lastSeenView = <div className="read"></div>;
    } else if (!lastMessageMine && lastMessageSeen) {
      lastSeenView = <div></div>;
    } else if (lastMessageMine && lastMessageSeen) {
      lastSeenView = getIcon("blueTickIcon", "blue-tick-icon");
    } else {
      lastSeenView = getIcon("greyTickIcon", "grey-tick-icon");
    }
  }

  return (
    <div
      id="messages-tab-messages-list-subcomponent"
      // className={
      //   selectedConversation === conversationId ? "selected-conversation" : ""
      // }
    >
      <div className="padding-container">
        <div className="message-container">
          <div className="user-image-container">
            <CustomAvatar
              className="user-image"
              src={profilePhoto}
              role={role}
              isOnline={isOnline}
              profileBorder={profileBorder}
            />
          </div>

          <div className="user-description-container">
            <div className="user-top-container">
              {livestreamingAchievementBadge?.length > 0 && (
                <div className="livestreaming-achievement-badge-container">
                  {livestreamingAchievementBadge?.map((badge, index) => (
                    <img
                      className="livestreaming-achievement-badge"
                      src={badge?.url}
                      alt={badge?.type}
                      key={index}
                    />
                  ))}
                </div>
              )}

              <div className="user-name">{username}</div>

              <div className="user-badge-container">
                {(membershipType === userConst.membershipType.premium ||
                  membershipType === userConst.membershipType.freePremium ||
                  membershipType === userConst.membershipType.diamond) && (
                  <div className="premium-mini-badge-container">
                    {getIcon("premiumMiniBadge", "premium-mini-badge")}
                  </div>
                )}

                {verifiedProfile && (
                  <div className="verified-profile-badge-container">
                    {getIcon("verifiedProfileBadge", "verified-profile-badge")}
                  </div>
                )}

                {verifiedCaller && (
                  <div
                    className="verified-caller-badge-container"
                    onClick={onOpenVerifiedCallerDialog}
                  >
                    {getIcon("verifiedCallerBadge", "verified-caller-badge")}
                  </div>
                )}

                {membershipType === "admin" && (
                  <div className="admin-badge-container">
                    <PersonIcon className="person-icon" />
                    <div className="admin-label">Admin</div>
                  </div>
                )}

                {levellingBadge && (
                  <div className="level-badge-container">
                    <img className="level-badge" src={levellingBadge} />
                  </div>
                )}
              </div>
            </div>

            <div className="user-bottom-container">
              <div
                className="user-last-message"
                dangerouslySetInnerHTML={{ __html: getLastMessage() }}
              ></div>
            </div>
          </div>

          <div className="user-last-updated-container">
            <div className="last-updated">
              {getReadableDate(lastMessageTimestamp)}
            </div>

            {lastSeenView}
          </div>
        </div>
      </div>

      <Divider className="divider" light />
    </div>
  );
};

export default MessagesList;
