// General
import "./livestream-kicked-dialog.scss";
import { useEffect } from "react";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateLivestreamKickedDialog } from "../../../../redux/store/dialogStore";
// Material UI
import { Dialog } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

const LivestreamKickedDialog = () => {
  // Redux variables
  const livestreamKickedDialog = useSelector(
    (state) => state.dialog.livestreamKickedDialog
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!livestreamKickedDialog) return;
  }, [livestreamKickedDialog]);

  // Event Handlers | MUI Dialog
  const onCloseDialog = (event, reason) => {
    if (reason === "backdropClick") return;

    dispatch(updateLivestreamKickedDialog(false));
  };

  return (
    <Dialog
      className="custom-radius35-dialog"
      open={livestreamKickedDialog}
      onClose={onCloseDialog}
      hideBackdrop={true}
    >
      <div id="livestream-kicked-dialog">
        <div className="padding-container">
          <div className="kick-icon-container">
            {getIcon("actionAlertIcon", "kick-icon")}
          </div>

          <div className="title">{t("report.you_have_been_kicked")}</div>

          <div className="description">
            {t("report.you_have_been_kicked_desc")}
          </div>

          <div className="behave-text">{t("report.please_behave")}</div>

          <div
            className="back-button"
            onClick={() => onNavigate(routeConst.live.path)}
          >
            {t("live.back_to_search")}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default LivestreamKickedDialog;
