// General
import "./notification-list-profile-border.scss";
// Redux
import { useSelector } from "react-redux";
// Components
import NotificationList from "./notification-list/notification-list";

const NotificationListProfileBorder = () => {
  // Redux variables
  const profileBorderImage = useSelector(
    (state) => state.profileBorder.profileBorderImage
  );

  return (
    <div className="notification-list-profile-border">
      <div className="notification-list-label">Notification List</div>
      <div className="mobile-desktop-view-label">Mobile/Desktop View</div>

      <div className="mobile-view">
        <NotificationList
          notification={null}
          profileBorder={profileBorderImage}
        />
      </div>

      <div className="notification-list-settings-container">
        <div>Settings</div>
      </div>
    </div>
  );
};

export default NotificationListProfileBorder;
