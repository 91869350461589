// General
import "./icon-manager.scss";
// Static Data
import utilityConst from "../../../../const/utilityConst";
// Redux
import { useSelector } from "react-redux";
// Material UI
import MaterialHomeIcon from "@mui/icons-material/Home";
import MaterialInboxIcon from "@mui/icons-material/Inbox";
import MaterialVideocamIcon from "@mui/icons-material/Videocam";
import MaterialLiveTvIcon from "@mui/icons-material/LiveTv";
import MaterialFavoriteIcon from "@mui/icons-material/Favorite";
import MaterialAccountCircleIcon from "@mui/icons-material/AccountCircle";
import MaterialFilterAltIcon from "@mui/icons-material/FilterAlt";
import MaterialFilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import MaterialLeaderboardIcon from "@mui/icons-material/Leaderboard";
import MaterialMonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import MaterialDiamondIcon from "@mui/icons-material/Diamond";
import MaterialCalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import MaterialSettingsIcon from "@mui/icons-material/Settings";
import MaterialArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import MaterialHelpIcon from "@mui/icons-material/Help";
import MaterialMoreVertIcon from "@mui/icons-material/MoreVert";
import MaterialHelpOutlineIcon from "@mui/icons-material/HelpOutline";
import MaterialAccessTimeIcon from "@mui/icons-material/AccessTime";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MaterialDoneIcon from "@mui/icons-material/DoneRounded";
import MaterialPriorityHighIcon from "@mui/icons-material/PriorityHighRounded";
import MaterialAddRoundedIcon from "@mui/icons-material/AddRounded";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
// Custom Hooks
import AssetManager from "../asset-manager/asset-manager";

const IconManager = () => {
  // Redux variables
  const isDarkMode = useSelector((state) => state.app.isDarkMode);
  const darkTheme = useSelector((state) => state.app.darkTheme);
  const iconTheme = useSelector((state) => state.app.iconTheme);

  // Custom Hooks Functions
  const getAsset = AssetManager();

  // Main Function
  const returnIcon = (iconType, className) => {
    const iconMapping = {
      // Logo
      sugarbookLogo: {
        default: (
          <img
            className={className}
            src={getAsset("sugarbookLogo")}
            alt="sugarbook"
          />
        ),
      },
      sugarbookLogoWithTagline: {
        default: (
          <img
            className={className}
            src={getAsset("sugarbookLogoWithTagline")}
            alt={"sugarbook"}
          />
        ),
      },
      sugarbookAppLogo: {
        default: (
          <img
            className={className}
            src={getAsset("sugarbookAppLogo")}
            alt={"sugarbook-app"}
          />
        ),
      },
      facebookLogo: {
        default: (
          <img
            className={className}
            src={getAsset("facebookLogo")}
            alt={"facebook"}
          />
        ),
      },
      visaLogo: {
        default: (
          <img className={className} src={getAsset("visaLogo")} alt={"visa"} />
        ),
      },
      mastercardLogo: {
        default: (
          <img
            className={className}
            src={getAsset("mastercardLogo")}
            alt={"mastercard"}
          />
        ),
      },
      maestroLogo: {
        default: (
          <img
            className={className}
            src={getAsset("maestroLogo")}
            alt={"maestro"}
          />
        ),
      },
      visaElectronLogo: {
        default: (
          <img
            className={className}
            src={getAsset("visaElectronLogo")}
            alt={"visa-electron"}
          />
        ),
      },
      bleueLogo: {
        default: (
          <img
            className={className}
            src={getAsset("bleueLogo")}
            alt={"bleue"}
          />
        ),
      },
      dinersLogo: {
        default: (
          <img
            className={className}
            src={getAsset("dinersLogo")}
            alt={"diners"}
          />
        ),
      },
      discoverLogo: {
        default: (
          <img
            className={className}
            src={getAsset("discoverLogo")}
            alt={"discover"}
          />
        ),
      },
      jcbLogo: {
        default: (
          <img className={className} src={getAsset("jcbLogo")} alt={"jcb"} />
        ),
      },
      fpxLogo: {
        default: (
          <img className={className} src={getAsset("fpxLogo")} alt={"fpx"} />
        ),
      },
      // Icon
      sugarbookIcon: {
        default: (
          <img
            className={className}
            src={getAsset("sugarbookIcon")}
            alt={"sb"}
          />
        ),
      },
      homeIcon: {
        default: (
          <img className={className} src={getAsset("homeIcon")} alt="home" />
        ),
        material: (
          <MaterialHomeIcon
            className={`${className} ${
              isDarkMode ? "white-material-icon" : ""
            }`}
          />
        ),
      },
      homeIconSelected: {
        default: (
          <img
            className={className}
            src={getAsset("homeIconSelected")}
            alt="home"
          />
        ),
        material: (
          <MaterialHomeIcon
            className={`${className} ${
              isDarkMode ? "white-material-icon" : ""
            }`}
          />
        ),
      },
      messagingIcon: {
        default: (
          <img
            className={className}
            src={getAsset("messagingIcon")}
            alt="messaging"
          />
        ),
        material: (
          <MaterialInboxIcon
            className={`${className} ${
              isDarkMode ? "white-material-icon" : ""
            }`}
          />
        ),
      },
      messagingIconSelected: {
        default: (
          <img
            className={className}
            src={getAsset("messagingIconSelected")}
            alt="messaging"
          />
        ),
        material: (
          <MaterialInboxIcon
            className={`${className} ${
              isDarkMode ? "white-material-icon" : ""
            }`}
          />
        ),
      },
      videoIcon: {
        default: (
          <img className={className} src={getAsset("videoIcon")} alt="video" />
        ),
        material: (
          <MaterialVideocamIcon
            className={`${className} ${
              isDarkMode ? "white-material-icon" : ""
            }`}
          />
        ),
      },
      videoIconSelected: {
        default: (
          <img
            className={className}
            src={getAsset("videoIconSelected")}
            alt="video"
          />
        ),
        material: (
          <MaterialVideocamIcon
            className={`${className} ${
              isDarkMode ? "white-material-icon" : ""
            }`}
          />
        ),
      },
      liveIcon: {
        default: (
          <img className={className} src={getAsset("liveIcon")} alt="live" />
        ),
        material: (
          <MaterialLiveTvIcon
            className={`${className} ${
              isDarkMode ? "white-material-icon" : ""
            }`}
          />
        ),
      },
      liveIconSelected: {
        default: (
          <img
            className={className}
            src={getAsset("liveIconSelected")}
            alt="live"
          />
        ),
        material: (
          <MaterialLiveTvIcon
            className={`${className} ${
              isDarkMode ? "white-material-icon" : ""
            }`}
          />
        ),
      },
      favoriteIcon: {
        default: (
          <img
            className={className}
            src={getAsset("favoriteIcon")}
            alt="favorite"
          />
        ),
        material: (
          <MaterialFavoriteIcon
            className={`${className} ${
              isDarkMode ? "white-material-icon" : ""
            }`}
          />
        ),
      },
      favoriteIconSelected: {
        default: (
          <img
            className={className}
            src={getAsset("favoriteIconSelected")}
            alt="favorite"
          />
        ),
        material: (
          <MaterialFavoriteIcon
            className={`${className} ${
              isDarkMode ? "white-material-icon" : ""
            }`}
          />
        ),
      },
      notificationIcon: {
        default: (
          <img
            className={className}
            src={getAsset("notificationIcon")}
            alt="notification"
          />
        ),
      },
      emptyNotificationIcon: {
        default: (
          <img
            className={className}
            src={getAsset("emptyNotificationIcon")}
            alt="notification"
          />
        ),
      },
      profileIcon: {
        default: (
          <img
            className={className}
            src={getAsset("profileIcon")}
            alt="profile"
          />
        ),
        material: (
          <MaterialAccountCircleIcon
            className={`${className} ${
              isDarkMode ? "white-material-icon" : ""
            }`}
          />
        ),
      },
      profileIconSelected: {
        default: (
          <img
            className={className}
            src={getAsset("profileIconSelected")}
            alt="profile"
          />
        ),
        material: (
          <MaterialAccountCircleIcon
            className={`${className} ${
              isDarkMode ? "white-material-icon" : ""
            }`}
          />
        ),
      },
      searchFilterIcon: {
        default: (
          <img
            className={className}
            src={getAsset("searchFilterIcon")}
            alt="filter-icon"
          />
        ),
        material: (
          <MaterialFilterAltOffIcon
            className={`${className} ${
              isDarkMode ? "white-material-icon" : ""
            }`}
          />
        ),
      },
      searchFilteredIcon: {
        default: (
          <img
            className={className}
            src={getAsset("searchFilteredIcon")}
            alt="filter-icon"
          />
        ),
        material: (
          <MaterialFilterAltIcon
            className={`${className} ${
              isDarkMode ? "white-material-icon" : ""
            }`}
          />
        ),
      },
      searchDesktopIcon: {
        default: (
          <img
            className={className}
            src={getAsset("searchDesktopIcon")}
            alt={"search"}
          />
        ),
      },
      messageDesktopIcon: {
        default: (
          <img
            className={className}
            src={getAsset("messageDesktopIcon")}
            alt={"inbox"}
          />
        ),
      },
      favoriteDesktopIcon: {
        default: (
          <img
            className={className}
            src={getAsset("favoriteDesktopIcon")}
            alt={"interest"}
          />
        ),
      },
      videoCallDesktopIcon: {
        default: (
          <img
            className={className}
            src={getAsset("videoCallDesktopIcon")}
            alt={"interest"}
          />
        ),
      },
      liveDesktopIcon: {
        default: (
          <img
            className={className}
            src={getAsset("liveDesktopIcon")}
            alt={"live"}
          />
        ),
      },
      keyboardArrowDownIcon: {
        default: <KeyboardArrowDownIcon className={className} />,
      },
      chevronRightIcon: {
        default: <ChevronRightIcon className={className} />,
      },
      leaderboardNewIcon: {
        default: (
          <img
            className={className}
            src={getAsset("leaderboardNewIcon")}
            alt="leaderboard-icon"
          />
        ),
        material: (
          <MaterialLeaderboardIcon
            className={`${className} ${
              isDarkMode ? "white-material-icon" : ""
            }`}
          />
        ),
      },
      leaderboardIcon: {
        default: (
          <img
            className={className}
            src={getAsset("leaderboardIcon")}
            alt="leaderboard-icon"
          />
        ),
        material: (
          <MaterialLeaderboardIcon
            className={`${className} ${
              isDarkMode ? "white-material-icon" : ""
            }`}
          />
        ),
      },
      sbCoinIcon: {
        default: (
          <img
            className={className}
            src={getAsset("sbCoinIcon")}
            alt="sb-coin"
          />
        ),
        material: (
          <MaterialMonetizationOnIcon
            className={`${className} ${
              isDarkMode ? "white-material-icon" : ""
            }`}
          />
        ),
      },
      sbCoinTickIcon: {
        default: (
          <img
            className={className}
            src={getAsset("sbCoinTickIcon")}
            alt="sb-coin-tick"
          />
        ),
        material: (
          <MaterialMonetizationOnIcon
            className={`${className} ${
              isDarkMode ? "white-material-icon" : ""
            }`}
          />
        ),
      },
      sbDiamondIcon: {
        default: (
          <img
            className={className}
            src={getAsset("sbDiamondIcon")}
            alt="diamond"
          />
        ),
        material: (
          <MaterialDiamondIcon
            className={`${className} ${
              isDarkMode ? "white-material-icon" : ""
            }`}
          />
        ),
      },
      calendarIcon: {
        default: (
          <img
            className={className}
            src={getAsset("calendarIcon")}
            alt="calendar-icon"
          />
        ),
        material: (
          <MaterialCalendarMonthIcon
            className={`${className} ${
              isDarkMode ? "white-material-icon" : ""
            }`}
          />
        ),
      },
      settingsIcon: {
        default: (
          <img
            className={className}
            src={getAsset("settingsIcon")}
            alt="settings"
          />
        ),
        material: (
          <MaterialSettingsIcon
            className={`${className} ${
              isDarkMode ? "white-material-icon" : ""
            }`}
          />
        ),
      },
      backIcon: {
        default: (
          <img className={className} src={getAsset("backIcon")} alt="back" />
        ),
        material: (
          <MaterialArrowBackIosIcon
            className={`${className} ${
              isDarkMode ? "white-material-icon" : ""
            }`}
          />
        ),
      },
      backLightIcon: {
        default: (
          <img
            className={className}
            src={getAsset("backLightIcon")}
            alt="back"
          />
        ),
        material: (
          <MaterialArrowBackIosIcon
            className={`${className} ${
              isDarkMode ? "white-material-icon" : ""
            }`}
          />
        ),
      },
      closeLightIcon: {
        default: (
          <img
            className={className}
            src={getAsset("closeLightIcon")}
            alt={"close-button"}
          />
        ),
      },
      infoIcon: {
        default: (
          <img className={className} src={getAsset("infoIcon")} alt="info" />
        ),
        material: (
          <MaterialHelpIcon
            className={`${className} ${
              isDarkMode ? "white-material-icon" : ""
            }`}
          />
        ),
      },
      videoCallIcon: {
        default: (
          <img
            className={className}
            src={getAsset("videoCallIcon")}
            alt="video-call"
          />
        ),
      },
      moreVertIcon: {
        default: <MaterialMoreVertIcon className={className} />,
        material: (
          <MaterialMoreVertIcon
            className={`${className} ${
              isDarkMode ? "white-material-icon" : ""
            }`}
          />
        ),
      },
      blueTickIcon: {
        default: (
          <img
            className={className}
            src={getAsset("blueTickIcon")}
            alt={"read"}
          />
        ),
      },
      greyTickIcon: {
        default: (
          <img
            className={className}
            src={getAsset("greyTickIcon")}
            alt={"unread"}
          />
        ),
      },
      redSettingsIcon: {
        default: (
          <img
            className={className}
            src={getAsset("redSettingsIcon")}
            alt={"video-call-settings"}
          />
        ),
      },
      redInfoIcon: {
        default: (
          <img
            className={className}
            src={getAsset("redInfoIcon")}
            alt={"learn-more"}
          />
        ),
      },
      helpIcon: {
        default: (
          <img className={className} src={getAsset("helpIcon")} alt={"info"} />
        ),
        material: (
          <MaterialHelpOutlineIcon
            className={`${className} ${
              isDarkMode ? "white-material-icon" : ""
            }`}
          />
        ),
      },
      pkIcon: {
        default: (
          <img className={className} src={getAsset("pkIcon")} alt={"pk"} />
        ),
      },
      leaderboardPkIcon: {
        default: (
          <img
            className={className}
            src={getAsset("leaderboardPkIcon")}
            alt={"pk"}
          />
        ),
      },
      pkMedalIcon: {
        default: (
          <img
            className={className}
            src={getAsset("pkMedalIcon")}
            alt={"medal"}
          />
        ),
      },
      defaultProfilePhotoIcon: {
        default: (
          <img
            className={className}
            src={getAsset("defaultProfilePhotoIcon")}
            alt={"profile"}
          />
        ),
      },
      volumeUnmuteIcon: {
        default: (
          <img
            className={className}
            src={getAsset("volumeUnmuteIcon")}
            alt={"unmute"}
          />
        ),
      },
      volumeMuteIcon: {
        default: (
          <img
            className={className}
            src={getAsset("volumeMuteIcon")}
            alt={"mute"}
          />
        ),
      },
      liveReportIcon: {
        default: (
          <img
            className={className}
            src={getAsset("liveReportIcon")}
            alt={"report"}
          />
        ),
      },
      liveBackIcon: {
        default: (
          <img
            className={className}
            src={getAsset("liveBackIcon")}
            alt={"back"}
          />
        ),
      },
      liveViewerIcon: {
        default: (
          <img
            className={className}
            src={getAsset("liveViewerIcon")}
            alt={"viewer"}
          />
        ),
      },
      liveAddCoinIcon: {
        default: (
          <img
            className={className}
            src={getAsset("liveAddCoinIcon")}
            alt={"add-coin"}
          />
        ),
      },
      pinChatIcon: {
        default: (
          <img
            className={className}
            src={getAsset("pinChatIcon")}
            alt={"pin-chat"}
          />
        ),
      },
      inReviewIcon: {
        default: (
          <img
            className={className}
            src={getAsset("inReviewIcon")}
            alt={"review"}
          />
        ),
        material: (
          <MaterialAccessTimeIcon
            className={`${className} ${
              isDarkMode ? "white-material-icon" : ""
            }`}
          />
        ),
      },
      addPhotoIcon: {
        default: (
          <img
            className={className}
            src={getAsset("addPhotoIcon")}
            alt={"add"}
          />
        ),
      },
      uploadIcon: {
        default: (
          <img
            className={className}
            src={getAsset("uploadIcon")}
            alt={"upload"}
          />
        ),
      },
      profilePhotoIcon: {
        default: (
          <img
            className={className}
            src={getAsset("profilePhotoIcon")}
            alt={"photo"}
          />
        ),
      },
      profileAboutMeIcon: {
        default: (
          <img
            className={className}
            src={getAsset("profileAboutMeIcon")}
            alt={"about-me"}
          />
        ),
      },
      profileAchievementIcon: {
        default: (
          <img
            className={className}
            src={getAsset("profileAchievementIcon")}
            alt={"achievement"}
          />
        ),
      },
      privatePhotoLockIcon: {
        default: (
          <img
            className={className}
            src={getAsset("privatePhotoLockIcon")}
            alt={"locked"}
          />
        ),
      },
      premiumLockIcon: {
        default: (
          <img
            className={className}
            src={getAsset("premiumLockIcon")}
            alt="empty"
          />
        ),
      },
      userProfileCompleteIcon: {
        default: (
          <img
            className={className}
            src={getAsset("userProfileCompleteIcon")}
            alt={"person"}
          />
        ),
      },
      mailIcon: {
        default: (
          <img className={className} src={getAsset("mailIcon")} alt="mail" />
        ),
      },
      videoCallUnavailableIcon: {
        default: (
          <img
            className={className}
            src={getAsset("videoCallUnavailableIcon")}
            alt={"busy"}
          />
        ),
      },
      closeIcon: {
        default: (
          <img
            className={className}
            src={getAsset("closeIcon")}
            alt={"close"}
          />
        ),
      },
      deleteIcon: {
        default: (
          <img
            className={className}
            src={getAsset("deleteIcon")}
            alt={"delete"}
          />
        ),
      },
      blockedMembersIcon: {
        default: (
          <img
            className={className}
            src={getAsset("blockedMembersIcon")}
            alt={"blocked-members"}
          />
        ),
      },
      deactivateAccountIcon: {
        default: (
          <img
            className={className}
            src={getAsset("deactivateAccountIcon")}
            alt={"deactivate"}
          />
        ),
      },
      locationPermissionIcon: {
        default: (
          <img
            className={className}
            src={getAsset("locationPermissionIcon")}
            alt={"location-permission"}
          />
        ),
      },
      locationPermissionDeniedIcon: {
        default: (
          <img
            className={className}
            src={getAsset("locationPermissionDeniedIcon")}
            alt={"location-permission-denied"}
          />
        ),
      },
      premiumUnlockIcon: {
        default: (
          <img
            className={className}
            src={getAsset("premiumUnlockIcon")}
            alt={"premium"}
          />
        ),
      },
      shareIcon: {
        default: (
          <img
            className={className}
            src={getAsset("shareIcon")}
            alt={"share"}
          />
        ),
      },
      playOutlineIcon: {
        default: (
          <img
            className={className}
            src={getAsset("playOutlineIcon")}
            alt={"play"}
          />
        ),
      },
      actionAlertIcon: {
        default: (
          <img
            className={className}
            src={getAsset("actionAlertIcon")}
            alt={"alert"}
          />
        ),
      },
      actionSuccessIcon: {
        default: (
          <img
            className={className}
            src={getAsset("actionSuccessIcon")}
            alt={"success"}
          />
        ),
      },
      dropPhotoIcon: {
        default: (
          <img
            className={className}
            src={getAsset("dropPhotoIcon")}
            alt={"drop"}
          />
        ),
      },
      lowPowerIcon: {
        default: (
          <img
            className={className}
            src={getAsset("lowPowerIcon")}
            alt={"low-power"}
          />
        ),
      },
      notificationPermissionIcon: {
        default: (
          <img
            className={className}
            src={getAsset("notificationPermissionIcon")}
            alt={"notification-permission"}
          />
        ),
      },
      paymentErrorIcon: {
        default: (
          <img
            className={className}
            src={getAsset("paymentErrorIcon")}
            alt={"payment-error"}
          />
        ),
      },
      paymentWarningIcon: {
        default: (
          <img
            className={className}
            src={getAsset("paymentWarningIcon")}
            alt={"payment-warning"}
          />
        ),
      },
      rotateIcon: {
        default: (
          <img
            className={className}
            src={getAsset("rotateIcon")}
            alt={"flip"}
          />
        ),
      },
      closeProfileIcon: {
        default: (
          <img
            className={className}
            src={getAsset("closeProfileIcon")}
            alt={"close"}
          />
        ),
      },
      deleteProfileIcon: {
        default: (
          <img
            className={className}
            src={getAsset("deleteProfileIcon")}
            alt={"delete"}
          />
        ),
      },
      rejectedProfileIcon: {
        default: (
          <img
            className={className}
            src={getAsset("rejectedProfileIcon")}
            alt={"rejected"}
          />
        ),
      },
      profileMessageIcon: {
        default: (
          <img
            className={className}
            src={getAsset("profileMessageIcon")}
            alt={"message"}
          />
        ),
      },
      profileVideoCallIcon: {
        default: (
          <img
            className={className}
            src={getAsset("profileVideoCallIcon")}
            alt={"video-call"}
          />
        ),
      },
      viewProfileIcon: {
        default: (
          <img
            className={className}
            src={getAsset("viewProfileIcon")}
            alt={"view-profile"}
          />
        ),
      },
      avatarFallbackMan: {
        default: (
          <img
            className={className}
            src={getAsset("avatarFallbackMan")}
            alt={"avatar"}
          />
        ),
      },
      avatarFallbackWoman: {
        default: (
          <img
            className={className}
            src={getAsset("avatarFallbackWoman")}
            alt={"avatar"}
          />
        ),
      },
      avatarFallbackNeutral: {
        default: (
          <img
            className={className}
            src={getAsset("avatarFallbackNeutral")}
            alt={"avatar"}
          />
        ),
      },
      photoPermissionEmptyIcon: {
        default: (
          <img
            className={className}
            src={getAsset("photoPermissionEmptyIcon")}
            alt={"permissions"}
          />
        ),
      },
      privateStandbyIcon: {
        default: (
          <img
            className={className}
            src={getAsset("privateStandbyIcon")}
            alt={"private"}
          />
        ),
      },
      goldLockIcon: {
        default: (
          <img
            className={className}
            src={getAsset("goldLockIcon")}
            alt={"lock"}
          />
        ),
      },
      messageRedIcon: {
        default: (
          <img
            className={className}
            src={getAsset("messageRedIcon")}
            alt={"message"}
          />
        ),
      },
      messageWhiteIcon: {
        default: (
          <img
            className={className}
            src={getAsset("messageWhiteIcon")}
            alt={"message"}
          />
        ),
      },
      favoriteRedIcon: {
        default: (
          <img
            className={className}
            src={getAsset("favoriteRedIcon")}
            alt={"favorite"}
          />
        ),
      },
      favoriteWhiteIcon: {
        default: (
          <img
            className={className}
            src={getAsset("favoriteWhiteIcon")}
            alt={"favorite"}
          />
        ),
      },
      favoriteWhiteAltIcon: {
        default: (
          <img
            className={className}
            src={getAsset("favoriteWhiteAltIcon")}
            alt={"favorite"}
          />
        ),
      },
      favoritedRedIcon: {
        default: (
          <img
            className={className}
            src={getAsset("favoritedRedIcon")}
            alt={"favorite"}
          />
        ),
      },
      favoritedUserCardIcon: {
        default: (
          <img
            className={className}
            src={getAsset("favoritedUserCardIcon")}
            alt={"favorite"}
          />
        ),
      },
      videoCallRedIcon: {
        default: (
          <img
            className={className}
            src={getAsset("videoCallRedIcon")}
            alt={"video-call"}
          />
        ),
      },
      messagingOutlineIcon: {
        default: (
          <img
            className={className}
            src={getAsset("messagingOutlineIcon")}
            alt={"message"}
          />
        ),
      },
      viewedMeOutlineIcon: {
        default: (
          <img
            className={className}
            src={getAsset("viewedMeOutlineIcon")}
            alt={"viewed-me"}
          />
        ),
      },
      lockWhiteIcon: {
        default: (
          <img
            className={className}
            src={getAsset("lockWhiteIcon")}
            alt={"lock"}
          />
        ),
      },
      chargedMessageEnableIcon: {
        default: (
          <img
            className={className}
            src={getAsset("chargedMessageEnableIcon")}
            alt={"charged-messages-enable"}
          />
        ),
      },
      chargedMessageDisableIcon: {
        default: (
          <img
            className={className}
            src={getAsset("chargedMessageDisableIcon")}
            alt={"charged-messages-disable"}
          />
        ),
      },
      resetPermissionIcon: {
        default: (
          <img
            className={className}
            src={getAsset("resetPermissionIcon")}
            alt={"reset"}
          />
        ),
      },
      standbyKickIcon: {
        default: (
          <img
            className={className}
            src={getAsset("standbyKickIcon")}
            alt={"kick"}
          />
        ),
      },
      endCallIcon: {
        default: (
          <img
            className={className}
            src={getAsset("endCallIcon")}
            alt={"end-call"}
          />
        ),
      },
      videoCallMoreIcon: {
        default: (
          <img
            className={className}
            src={getAsset("videoCallMoreIcon")}
            alt={"more"}
          />
        ),
      },
      mailOutlineIcon: {
        default: (
          <img
            className={className}
            src={getAsset("mailOutlineIcon")}
            alt={"mail"}
          />
        ),
      },
      priceTagIcon: {
        default: (
          <img
            className={className}
            src={getAsset("priceTagIcon")}
            alt={"price-tag"}
          />
        ),
      },
      creditCardOutlineIcon: {
        default: (
          <img
            className={className}
            src={getAsset("creditCardOutlineIcon")}
            alt={"credit-card"}
          />
        ),
      },
      worldOutlineIcon: {
        default: (
          <img
            className={className}
            src={getAsset("worldOutlineIcon")}
            alt={"world"}
          />
        ),
      },
      upgradePremiumIcon: {
        default: (
          <img
            className={className}
            src={getAsset("upgradePremiumIcon")}
            alt={"premium"}
          />
        ),
      },
      upgradeStarIcon: {
        default: (
          <img
            className={className}
            src={getAsset("upgradeStarIcon")}
            alt={"upgrade-star"}
          />
        ),
      },
      paymentErrorAltIcon: {
        default: (
          <img
            className={className}
            src={getAsset("paymentErrorAltIcon")}
            alt={"payment-error"}
          />
        ),
      },
      callSentIcon: {
        default: (
          <img
            className="message-icon"
            src={getAsset("callSentIcon")}
            alt={"call-sent"}
          />
        ),
      },
      videoCallAvailableIcon: {
        default: (
          <img
            className={className}
            src={getAsset("videoCallAvailableIcon")}
            alt={"available"}
          />
        ),
      },
      videoCallMissedIcon: {
        default: (
          <img
            className={className}
            src={getAsset("videoCallMissedIcon")}
            alt={"missed"}
          />
        ),
      },
      paymentSecureLockIcon: {
        default: (
          <img
            className={className}
            src={getAsset("paymentSecureLockIcon")}
            alt={"secure-lock"}
          />
        ),
      },
      paymentSecureShieldIcon: {
        default: (
          <img
            className={className}
            src={getAsset("paymentSecureShieldIcon")}
            alt={"secure-shield"}
          />
        ),
      },
      pauseIcon: {
        default: (
          <img
            className={className}
            src={getAsset("pauseIcon")}
            alt={"pause"}
          />
        ),
      },
      playIcon: {
        default: (
          <img className={className} src={getAsset("playIcon")} alt={"play"} />
        ),
      },
      feedLikeIcon: {
        default: (
          <img
            className={className}
            src={getAsset("feedLikeIcon")}
            alt={"like"}
          />
        ),
      },
      feedSendTipIcon: {
        default: (
          <img
            className={className}
            src={getAsset("feedSendTipIcon")}
            alt={"tip"}
          />
        ),
      },
      feedMessageIcon: {
        default: (
          <img
            className={className}
            src={getAsset("feedMessageIcon")}
            alt={"message"}
          />
        ),
      },
      goLivePlayIcon: {
        default: (
          <img
            className={className}
            src={getAsset("goLivePlayIcon")}
            alt={"go-live"}
          />
        ),
      },
      pinLocationIcon: {
        default: (
          <img
            className={className}
            src={getAsset("pinLocationIcon")}
            alt={"location"}
          />
        ),
      },
      pictureFrameIcon: {
        default: (
          <img
            className={className}
            src={getAsset("pictureFrameIcon")}
            alt={"photos"}
          />
        ),
      },
      priceTagProfileIcon: {
        default: (
          <img
            className={className}
            src={getAsset("priceTagProfileIcon")}
            alt={"personal"}
          />
        ),
      },
      userIcon: {
        default: (
          <img className={className} src={getAsset("userIcon")} alt={"user"} />
        ),
      },
      descriptionIcon: {
        default: (
          <img
            className={className}
            src={getAsset("descriptionIcon")}
            alt={"description"}
          />
        ),
      },
      bankIcon: {
        default: (
          <img className={className} src={getAsset("bankIcon")} alt={"bank"} />
        ),
      },
      profileSmileyIcon: {
        default: (
          <img
            className={className}
            src={getAsset("profileSmileyIcon")}
            alt={"smiley"}
          />
        ),
      },
      materialLockIcon: {
        default: (
          <img
            className={className}
            src={getAsset("materialLockIcon")}
            alt={"lock"}
          />
        ),
      },
      tickIcon: {
        default: <MaterialDoneIcon className={className} />,
      },
      cameraBlueOutlineIcon: {
        default: (
          <img
            className={className}
            src={getAsset("cameraBlueOutlineIcon")}
            alt={"upload"}
          />
        ),
      },
      shieldCheckOutlineIcon: {
        default: (
          <img
            className={className}
            src={getAsset("shieldCheckOutlineIcon")}
            alt={"shield"}
          />
        ),
      },
      safetyOutlineIcon: {
        default: (
          <img
            className={className}
            src={getAsset("safetyOutlineIcon")}
            alt={"safety"}
          />
        ),
      },
      redCautionIcon: {
        default: (
          <img
            className={className}
            src={getAsset("redCautionIcon")}
            alt={"caution"}
          />
        ),
      },
      exclamationMarkIcon: {
        default: <MaterialPriorityHighIcon className={className} />,
      },
      addRoundedIcon: {
        default: <MaterialAddRoundedIcon className={className} />,
      },
      signupBackIcon: {
        default: (
          <img
            className={className}
            src={getAsset("signupBackIcon")}
            alt={"back"}
          />
        ),
      },
      blueBulbIcon: {
        default: (
          <img
            className={className}
            src={getAsset("blueBulbIcon")}
            alt={"bulb"}
          />
        ),
      },
      userIsLiveLiveIcon: {
        default: <VideocamOutlinedIcon className={className} />,
      },
      leaderboardCasinoChips: {
        default: (
          <img
            className={className}
            src={getAsset("leaderboardCasinoChips")}
            alt={"chips"}
          />
        ),
      },
      casinoGameIcon: {
        default: (
          <img
            className={className}
            src={getAsset("casinoGameIcon")}
            alt={"casino"}
          />
        ),
      },
      diceIcon: {
        default: (
          <img className={className} src={getAsset("diceIcon")} alt={"dice"} />
        ),
      },
      threeAceIcon: {
        default: (
          <img
            className={className}
            src={getAsset("threeAceIcon")}
            alt={"card"}
          />
        ),
      },
      // Badge
      premiumMiniBadge: {
        default: (
          <img
            className={className}
            src={getAsset("premiumMiniBadge")}
            alt="premium"
          />
        ),
      },
      verifiedProfileBadge: {
        default: (
          <img
            className={className}
            src={getAsset("verifiedProfileBadge")}
            alt="verified-profile"
          />
        ),
      },
      unverifiedProfileBadge: {
        default: (
          <img
            className={className}
            src={getAsset("unverifiedProfileBadge")}
            alt="unverified-profile"
          />
        ),
      },
      verifiedProfileOutlineBadge: {
        default: (
          <img
            className={className}
            src={getAsset("verifiedProfileOutlineBadge")}
            alt="verified-profile"
          />
        ),
      },
      verifiedCallerBadge: {
        default: (
          <img
            className={className}
            src={getAsset("verifiedCallerBadge")}
            alt="verified-caller"
          />
        ),
      },
      moreGiftBadge: {
        default: (
          <img
            className={className}
            src={getAsset("moreGiftBadge")}
            alt={"more-gift"}
          />
        ),
      },
      sendMessageLightIcon: {
        default: (
          <img
            className={className}
            src={getAsset("sendMessageLightIcon")}
            alt={"send"}
          />
        ),
      },
      sendMessageDarkIcon: {
        default: (
          <img
            className={className}
            src={getAsset("sendMessageDarkIcon")}
            alt={"send"}
          />
        ),
      },
      leaderboardRank1: {
        default: (
          <img
            className={className}
            src={getAsset("leaderboardRank1")}
            alt={"rank-1"}
          />
        ),
      },
      leaderboardRank2: {
        default: (
          <img
            className={className}
            src={getAsset("leaderboardRank2")}
            alt={"rank-2"}
          />
        ),
      },
      leaderboardRank3: {
        default: (
          <img
            className={className}
            src={getAsset("leaderboardRank3")}
            alt={"rank-3"}
          />
        ),
      },
      newBadge: {
        default: (
          <img className={className} src={getAsset("newBadge")} alt={"new"} />
        ),
      },
      superOfferBadge: {
        default: (
          <img
            className={className}
            src={getAsset("superOfferBadge")}
            alt={"super-offer"}
          />
        ),
      },
      megaOfferBadge: {
        default: (
          <img
            className={className}
            src={getAsset("megaOfferBadge")}
            alt={"mega-offer"}
          />
        ),
      },
      privateRoomBadge: {
        default: (
          <img
            className={className}
            src={getAsset("privateRoomBadge")}
            alt={"private-room"}
          />
        ),
      },
      tier1Badge: {
        default: (
          <img
            className={className}
            src={getAsset("tier1Badge")}
            alt={"tier1"}
          />
        ),
      },
      tier2Badge: {
        default: (
          <img
            className={className}
            src={getAsset("tier2Badge")}
            alt={"tier2"}
          />
        ),
      },
      tier3Badge: {
        default: (
          <img
            className={className}
            src={getAsset("tier3Badge")}
            alt={"tier3"}
          />
        ),
      },
      trophyRank1Badge: {
        default: (
          <img
            className={className}
            src={getAsset("trophyRank1")}
            alt={"rank1"}
          />
        ),
      },
      trophyRank2Badge: {
        default: (
          <img
            className={className}
            src={getAsset("trophyRank2")}
            alt={"rank2"}
          />
        ),
      },
      trophyRank3Badge: {
        default: (
          <img
            className={className}
            src={getAsset("trophyRank3")}
            alt={"rank3"}
          />
        ),
      },
      discreteBadge: {
        default: (
          <img
            className={className}
            src={getAsset("discreteBadge")}
            alt={"discrete"}
          />
        ),
      },
      securityBadge: {
        default: (
          <img
            className={className}
            src={getAsset("securityBadge")}
            alt={"security"}
          />
        ),
      },
      sslBadge: {
        default: (
          <img className={className} src={getAsset("sslBadge")} alt={"ssl"} />
        ),
      },
      // Image
      tipCoins: {
        default: (
          <img className={className} src={getAsset("tipCoins")} alt={"tip"} />
        ),
      },
      tipCoinsAccepted: {
        default: (
          <img
            className={className}
            src={getAsset("tipCoinsAccepted")}
            alt={"tip"}
          />
        ),
      },
      // Emoji
      blowKissEmoji: {
        default: (
          <img
            className={className}
            src={getAsset("blowKissEmoji")}
            alt={"blow-kiss-emoji"}
          />
        ),
      },
      heartEyesEmoji: {
        default: (
          <img
            className={className}
            src={getAsset("heartEyesEmoji")}
            alt={"heart-eyes-emoji"}
          />
        ),
      },
      thumbsUpEmoji: {
        default: (
          <img
            className={className}
            src={getAsset("thumbsUpEmoji")}
            alt={"thumbs-up-emoji"}
          />
        ),
      },
      fireEmoji: {
        default: (
          <img
            className={className}
            src={getAsset("fireEmoji")}
            alt={"fire-emoji"}
          />
        ),
      },
      // Gif
      callRequestGif: {
        default: (
          <img
            className={className}
            src={getAsset("callRequestGif")}
            alt={"call-request"}
          />
        ),
      },
      callAcceptGif: {
        default: (
          <img
            className={className}
            src={getAsset("callAcceptGif")}
            alt={"call-accepted"}
          />
        ),
      },
      callRejectGif: {
        default: (
          <img
            className={className}
            src={getAsset("callRejectGif")}
            alt={"call-reject"}
          />
        ),
      },
      callWaitingGif: {
        default: (
          <img
            className={className}
            src={getAsset("callWaitingGif")}
            alt={"call-waiting"}
          />
        ),
      },
      template: {
        default: null,
        material: null,
      },
    };
    const iconConfig = iconMapping[iconType] || {};

    switch (iconTheme) {
      case utilityConst.iconTheme.default:
        return iconConfig?.default;
      case utilityConst.iconTheme.material:
        return iconConfig?.material || iconConfig?.default;
      default:
        return iconConfig?.default;
    }
  };

  return returnIcon;
};

export default IconManager;
