// General
import "./livestream-report-dialog.scss";
import { useEffect, forwardRef } from "react";
import { useParams, useSearchParams } from "react-router-dom";
// Services
import {
  useLazyGetLivestreamingReportCriteriaQuery,
  usePostReportLivestreamingChannelMutation,
} from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateLivestreamReportDialog,
  updateLivestreamReportSuccessDialog,
} from "../../../../redux/store/dialogStore";
// Material UI
import { Dialog, Slide, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import LivestreamReportSuccessDialog from "../livestream-report-success-dialog/livestream-report-success-dialog";

const LivestreamReportDialog = () => {
  // API variables
  const [
    getLivestreamingReportCriteria,
    {
      data: getLivestreamingReportCriteriaData,
      error: getLivestreamingReportCriteriaErrorData,
      isFetching: getLivestreamingReportCriteriaFetching,
      isLoading: getLivestreamingReportCriteriaLoading,
      isSuccess: getLivestreamingReportCriteriaSuccess,
      isError: getLivestreamingReportCriteriaError,
    },
  ] = useLazyGetLivestreamingReportCriteriaQuery();
  const [
    postReportLivestreamingChannel,
    {
      data: postReportLivestreamingChannelData,
      error: postReportLivestreamingChannelErrorData,
      isLoading: postReportLivestreamingChannelLoading,
      isSuccess: postReportLivestreamingChannelSuccess,
      isError: postReportLivestreamingChannelError,
    },
  ] = usePostReportLivestreamingChannelMutation();

  // Render variables
  let reportStreamListView;

  // Redux variables
  const livestreamReportDialog = useSelector(
    (state) => state.dialog.livestreamReportDialog
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // Router variables
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    if (livestreamReportDialog) {
      setSearchParams("livestreamReportDialog=true");
    } else {
      // getLivestreamingReportCriteria(null, true);
      setSearchParams();
    }
  }, [livestreamReportDialog]);

  // Lifecycle | Check for update | Report Livestreaming Channel API Response
  useEffect(() => {
    if (postReportLivestreamingChannelLoading) {
    } else if (postReportLivestreamingChannelSuccess) {
      dispatch(updateLivestreamReportSuccessDialog(true));
    } else if (postReportLivestreamingChannelError) {
      if (postReportLivestreamingChannelErrorData?.status === 401) {
        // onNavigate(routeConst.logout.path);
      }
    }
  }, [
    postReportLivestreamingChannelLoading,
    postReportLivestreamingChannelSuccess,
    postReportLivestreamingChannelError,
  ]);

  // Lifecycle | Check for update | searchParams
  useEffect(() => {
    if (searchParams?.get("livestreamReportDialog") === "true") return;

    dispatch(updateLivestreamReportDialog(false));
  }, [searchParams]);

  // Event Handlers | Button
  const onCloseThisDialog = () => {
    dispatch(updateLivestreamReportDialog(false));
  };
  const onReportLivestream = (index) => {
    const obj = {
      channel_id: id,
      flag_option_id: index,
    };

    postReportLivestreamingChannel(obj);
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateLivestreamReportDialog(false));
  };

  // Render Functions | Loading
  if (
    getLivestreamingReportCriteriaFetching ||
    getLivestreamingReportCriteriaLoading
  ) {
  } else if (getLivestreamingReportCriteriaSuccess) {
    if (
      getLivestreamingReportCriteriaData?.status === 1 &&
      getLivestreamingReportCriteriaData?.data?.length > 0
    ) {
      reportStreamListView = getLivestreamingReportCriteriaData.data.map(
        (item, index) => (
          <div
            className="button-container"
            key={index}
            onClick={() => onReportLivestream(index + 1)}
          >
            {item.description}
          </div>
        )
      );
    }
  } else if (getLivestreamingReportCriteriaError) {
    if (getLivestreamingReportCriteriaErrorData?.status === 401) {
      // onNavigate(routeConst.logout.path);
    }
  }

  return (
    <Dialog
      className={
        isMobile ? "custom-bottom-sheet-dialog" : "custom-radius35-dialog"
      }
      fullScreen={isMobile ? true : false}
      open={livestreamReportDialog}
      TransitionComponent={Transition}
      onClose={onCloseDialog}
    >
      <div id="livestream-report-dialog">
        <div className="padding-container">
          <div className="header">
            <Trans>{t("live.report_streamer_title")}</Trans>

            <div className="close-icon-container" onClick={onCloseThisDialog}>
              <CloseIcon className="close-icon" />
            </div>
          </div>

          <div className="subheader">{t("live.report_streamer_desc")}</div>

          <div className="buttons-list-container">
            {reportStreamListView}

            <div className="button-container" onClick={onCloseThisDialog}>
              {t("live.report_oops_nevermind")}
            </div>
          </div>
        </div>

        <LivestreamReportSuccessDialog />
      </div>
    </Dialog>
  );
};

export default LivestreamReportDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
