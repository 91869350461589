// General
import "./coins-package-designer.scss";
import { useState } from "react";
// Redux
import { useSelector } from "react-redux";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hook
import IconManager from "../../utility/manager/icon-manager/icon-manager";
// Components
import GlobalSettings from "./global-settings/global-settings";

const CoinsPackageDesigner = () => {
  // General variables
  const [selectedCoin, setSelectedCoin] = useState(null);
  const [coinsPackage, setCoinsPackage] = useState([
    {
      product_hash_id: "ZeaA9Z4bBk65",
      product_code: "127",
      product_type: "onetime",
      product_name: "coin",
      description: "216,888 Sugarbook Coins",
      quantity: 216888,
      quantity_d: "216,888",
      renewal_cycle: null,
      renewal_cycle_count: null,
      graphics: {
        svg: "https://image.sgrbk.com/artwork/icons/coins/coin1-v1.svg",
      },
      currency: "USD",
      display_currency: "USD",
      amount: 999.99,
      amount_d: "999.99",
      amount_recurring: null,
      amount_recurring_d: null,
      discount_percent: 25,
      discount_text: "SAVE 25%",
      discount_available: true,
      discounted_amount: 749.99,
      discounted_amount_d: "749.99",
      discounted_amount_recurring: null,
      discounted_amount_recurring_d: null,
      usd_amount: 999.99,
      usd_amount_d: "999.99",
      usd_amount_recurring: null,
      usd_amount_recurring_d: null,
      usd_discounted_amount: 749.99,
      usd_discounted_amount_d: "749.99",
      usd_discounted_amount_recurring: null,
      usd_discounted_amount_recurring_d: null,
      gateways: ["securionpay", "xenopay"],
      placement: 7,
      is_selected: false,
      is_featured: false,
      is_offer_1x: true,
      is_offer_2x: false,
      is_event_based: true,
      categories: ["coins"],
      note_1: "+16,888",
      note_2: null,
      note_3: null,
      params: null,
      event_name: "Sexy Blindfold Night",
    },
    {
      product_hash_id: "ZeaA9Z4bBk66",
      product_code: "127",
      product_type: "onetime",
      product_name: "coin",
      description: "216,888 Sugarbook Coins",
      quantity: 216888,
      quantity_d: "216,888",
      renewal_cycle: null,
      renewal_cycle_count: null,
      graphics: {
        svg: "https://image.sgrbk.com/artwork/icons/coins/coin1-v1.svg",
      },
      currency: "USD",
      display_currency: "USD",
      amount: 999.99,
      amount_d: "999.99",
      amount_recurring: null,
      amount_recurring_d: null,
      discount_percent: 25,
      discount_text: "SAVE 25%",
      discount_available: true,
      discounted_amount: 749.99,
      discounted_amount_d: "749.99",
      discounted_amount_recurring: null,
      discounted_amount_recurring_d: null,
      usd_amount: 999.99,
      usd_amount_d: "999.99",
      usd_amount_recurring: null,
      usd_amount_recurring_d: null,
      usd_discounted_amount: 749.99,
      usd_discounted_amount_d: "749.99",
      usd_discounted_amount_recurring: null,
      usd_discounted_amount_recurring_d: null,
      gateways: ["securionpay", "xenopay"],
      placement: 7,
      is_selected: false,
      is_featured: false,
      is_offer_1x: true,
      is_offer_2x: false,
      is_event_based: true,
      categories: ["coins"],
      note_1: "+16,888",
      note_2: null,
      note_3: null,
      params: null,
      event_name: "Sexy Blindfold Night",
    },
    {
      product_hash_id: "ZeaA9Z4bBk67",
      product_code: "127",
      product_type: "onetime",
      product_name: "coin",
      description: "216,888 Sugarbook Coins",
      quantity: 216888,
      quantity_d: "216,888",
      renewal_cycle: null,
      renewal_cycle_count: null,
      graphics: {
        svg: "https://image.sgrbk.com/artwork/icons/coins/coin1-v1.svg",
      },
      currency: "USD",
      display_currency: "USD",
      amount: 999.99,
      amount_d: "999.99",
      amount_recurring: null,
      amount_recurring_d: null,
      discount_percent: 25,
      discount_text: "SAVE 25%",
      discount_available: true,
      discounted_amount: 749.99,
      discounted_amount_d: "749.99",
      discounted_amount_recurring: null,
      discounted_amount_recurring_d: null,
      usd_amount: 999.99,
      usd_amount_d: "999.99",
      usd_amount_recurring: null,
      usd_amount_recurring_d: null,
      usd_discounted_amount: 749.99,
      usd_discounted_amount_d: "749.99",
      usd_discounted_amount_recurring: null,
      usd_discounted_amount_recurring_d: null,
      gateways: ["securionpay", "xenopay"],
      placement: 7,
      is_selected: false,
      is_featured: false,
      is_offer_1x: true,
      is_offer_2x: false,
      is_event_based: true,
      categories: ["coins"],
      note_1: "+16,888",
      note_2: null,
      note_3: null,
      params: null,
      event_name: "Sexy Blindfold Night",
    },
  ]);

  // Redux variables
  const topLeftSticker = useSelector(
    (state) => state.coinsPackageDesigner.topLeftSticker
  );
  const coinsAmount = useSelector(
    (state) => state.coinsPackageDesigner.coinsAmount
  );
  const extraCoinsAmount = useSelector(
    (state) => state.coinsPackageDesigner.extraCoinsAmount
  );
  const discountPercentage = useSelector(
    (state) => state.coinsPackageDesigner.discountPercentage
  );
  const payAmount = useSelector(
    (state) => state.coinsPackageDesigner.payAmount
  );
  const discountPayAmount = useSelector(
    (state) => state.coinsPackageDesigner.discountPayAmount
  );
  const bottomFloatingText = useSelector(
    (state) => state.coinsPackageDesigner.bottomFloatingText
  );
  const mainBackgroundColor = useSelector(
    (state) => state.coinsPackageDesigner.mainBackgroundColor
  );
  const mainSelectedBackgroundColor = useSelector(
    (state) => state.coinsPackageDesigner.mainSelectedBackgroundColor
  );
  const mainBorderColor = useSelector(
    (state) => state.coinsPackageDesigner.mainBorderColor
  );
  const mainSelectedBorderColor = useSelector(
    (state) => state.coinsPackageDesigner.mainSelectedBorderColor
  );
  const extraCoinsAmountBackgroundColor = useSelector(
    (state) => state.coinsPackageDesigner.extraCoinsAmountBackgroundColor
  );
  const extraCoinsAmountTextColor = useSelector(
    (state) => state.coinsPackageDesigner.extraCoinsAmountTextColor
  );
  const discountPercentageBackgroundColor = useSelector(
    (state) => state.coinsPackageDesigner.discountPercentageBackgroundColor
  );
  const discountPercentageTextColor = useSelector(
    (state) => state.coinsPackageDesigner.discountPercentageTextColor
  );
  const coinsAmountTextColor = useSelector(
    (state) => state.coinsPackageDesigner.coinsAmountTextColor
  );
  const payAmountTextColor = useSelector(
    (state) => state.coinsPackageDesigner.payAmountTextColor
  );
  const discountPayAmountTextColor = useSelector(
    (state) => state.coinsPackageDesigner.discountPayAmountTextColor
  );
  const bottomFloatingTextBackgroundColor = useSelector(
    (state) => state.coinsPackageDesigner.bottomFloatingTextBackgroundColor
  );
  const bottomFloatingTextTextColor = useSelector(
    (state) => state.coinsPackageDesigner.bottomFloatingTextTextColor
  );

  // i18next variables
  const { t } = useTranslation();

  // Event Handlers | Button
  const onSelectCoinPackage = (product) => {
    setSelectedCoin(product?.product_hash_id);
  };

  // Custom Hooks Functions
  const getIcon = IconManager();

  return (
    <div id="coins-package-designer-page">
      <div className="max-width-container">
        <div className="coins-package-designer-padding-container">
          <div className="coins-package-designer-title">
            Coins Package Designer
          </div>

          <GlobalSettings />

          <div className="coins-package-designer-content">
            {coinsPackage?.map((coin, index) => {
              if (index === 1) {
                return (
                  <div
                    className={`coins-container ${
                      selectedCoin === coin?.product_hash_id
                        ? "selected-coin"
                        : ""
                    }`}
                    style={{
                      background:
                        selectedCoin === coin?.product_hash_id
                          ? mainSelectedBackgroundColor
                          : mainBackgroundColor,
                      borderColor:
                        selectedCoin === coin?.product_hash_id
                          ? mainSelectedBorderColor
                          : mainBorderColor,
                    }}
                    onClick={() => onSelectCoinPackage(coin)}
                    key={index}
                  >
                    <div className="first-row">
                      <div className="limited-time-badge">
                        <img
                          className="limited-time-image"
                          src={topLeftSticker}
                        />
                      </div>

                      <div className="savings-container">
                        {extraCoinsAmount && (
                          <div
                            className="note-1"
                            style={{
                              background: extraCoinsAmountBackgroundColor,
                              color: extraCoinsAmountTextColor,
                            }}
                          >
                            {extraCoinsAmount}
                          </div>
                        )}

                        {discountPercentage && (
                          <div
                            className="discount-text"
                            style={{
                              background: discountPercentageBackgroundColor,
                              color: discountPercentageTextColor,
                            }}
                          >
                            {discountPercentage}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="second-row">
                      <div className="coin-image-container">
                        {getIcon("sbCoinIcon", "coin-image")}
                      </div>
                      <div
                        className="quantity-label"
                        style={{ color: coinsAmountTextColor }}
                      >
                        {coinsAmount?.toLocaleString()}
                      </div>
                    </div>

                    <div className="third-row">
                      <div
                        className={`price-label ${
                          payAmount ? "discounted-price-label" : ""
                        }`}
                        style={{ color: payAmountTextColor }}
                      >
                        {coin?.display_currency}{" "}
                        {discountPayAmount
                          ? discountPayAmount?.toLocaleString()
                          : payAmount?.toLocaleString()}
                      </div>
                    </div>

                    <div className="fourth-row">
                      {discountPayAmount && (
                        <div
                          className="original-price-label"
                          style={{ color: discountPayAmountTextColor }}
                        >
                          {coin?.display_currency} {payAmount?.toLocaleString()}
                        </div>
                      )}
                    </div>

                    {bottomFloatingText && (
                      <div
                        className="bottom-floating-text"
                        style={{
                          background: bottomFloatingTextBackgroundColor,
                          color: bottomFloatingTextTextColor,
                        }}
                      >
                        {bottomFloatingText}
                      </div>
                    )}
                  </div>
                );
              } else {
                return (
                  <div
                    className={`coins-container ${
                      selectedCoin === coin?.product_hash_id
                        ? "selected-coin"
                        : ""
                    }`}
                    onClick={() => onSelectCoinPackage(coin)}
                    key={index}
                  >
                    <div className="first-row">
                      <div className="limited-time-badge">
                        {coin?.is_offer_1x &&
                          getIcon("superOfferBadge", "limited-time-image")}

                        {coin?.is_offer_2x &&
                          getIcon("megaOfferBadge", "limited-time-image")}
                      </div>

                      <div className="savings-container">
                        {coin?.note_1 && (
                          <div className="note-1">{coin?.note_1}</div>
                        )}

                        {coin?.discount_text && (
                          <div className="discount-text">
                            {coin?.discount_text}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="second-row">
                      <div className="coin-image-container">
                        {getIcon("sbCoinIcon", "coin-image")}
                      </div>
                      <div className="quantity-label">
                        {coin?.quantity?.toLocaleString()}
                      </div>
                    </div>

                    <div className="third-row">
                      <div
                        className={`price-label ${
                          coin?.discounted_amount
                            ? "discounted-price-label"
                            : ""
                        }`}
                      >
                        {coin?.display_currency}{" "}
                        {coin?.discounted_amount?.toLocaleString() ??
                          coin?.amount?.toLocaleString()}
                      </div>
                    </div>

                    <div className="fourth-row">
                      {coin?.discounted_amount && (
                        <div className="original-price-label">
                          {coin?.display_currency}{" "}
                          {coin?.amount?.toLocaleString()}
                        </div>
                      )}
                    </div>

                    {coin?.is_featured && (
                      <div className="most-popular">
                        {t("shop.most_popular")}
                      </div>
                    )}

                    {coin?.is_event_based && (
                      <div className="bottom-floating-text">
                        {t("shop.limited_time_only")}
                      </div>
                    )}
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoinsPackageDesigner;
