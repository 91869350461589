// General
import "./conversation-page-profile-border.scss";
// Redux
import { useSelector } from "react-redux";
// Components
import MessagingChat from "./messaging-chat/messaging-chat";

const ConversationPageProfileBorder = () => {
  // Redux variables
  const profileBorderImage = useSelector(
    (state) => state.profileBorder.profileBorderImage
  );

  return (
    <div className="conversation-page-profile-border">
      <div className="conversation-label">Conversation Page</div>
      <div className="mobile-desktop-view-label">Mobile/Desktop View</div>

      <div className="sample-view">
        <div className="space-around">
          <div className="mobile-view">
            <MessagingChat
              username={"King Daddy"}
              // Utility
              profileBorder={profileBorderImage}
            />
          </div>
        </div>
      </div>

      <div className="conversation-page-settings-container">
        <div>Settings</div>
      </div>
    </div>
  );
};

export default ConversationPageProfileBorder;
