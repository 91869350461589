// General
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  topLeftSticker: null,
  coinsAmount: 216888,
  extraCoinsAmount: 16888,
  discountPercentage: "-27%",
  payAmount: 728.88,
  discountPayAmount: 999.99,
  bottomFloatingText: "12 - 14 FEB ONLY",

  // Styling
  mainBackgroundColor: "#EEEEEE",
  mainSelectedBackgroundColor: "#EEEEEE",
  mainBorderColor: "#EEEEEE",
  mainSelectedBorderColor: "#FFCA33",
  extraCoinsAmountBackgroundColor: "#FF37AF",
  extraCoinsAmountTextColor: "white",
  discountPercentageBackgroundColor: "#5AEA38",
  discountPercentageTextColor: "black",
  coinsAmountTextColor: "black",
  payAmountTextColor: "#04D100",
  discountPayAmountTextColor: "#FF0001",
  bottomFloatingTextBackgroundColor: "linear-gradient(236.45deg, #54fd34 -10.68%, #34c52e 109.24%);",
  bottomFloatingTextTextColor: "white",
};

export const coinsPackageDesignerSlice = createSlice({
  name: "coinsPackageDesigner",
  initialState,
  reducers: {
    updateTopLeftSticker: (state, action) => {
      state.topLeftSticker = action.payload;
    },
    updateCoinsAmount: (state, action) => {
      state.coinsAmount = action.payload;
    },
    updateExtraCoinsAmount: (state, action) => {
      state.extraCoinsAmount = action.payload;
    },
    updateDiscountPercentage: (state, action) => {
      state.discountPercentage = action.payload;
    },
    updatePayAmount: (state, action) => {
      state.payAmount = action.payload;
    },
    updateDiscountPayAmount: (state, action) => {
      state.discountPayAmount = action.payload;
    },
    updateBottomFloatingText: (state, action) => {
      state.bottomFloatingText = action.payload;
    },

    // Styling Functions
    updateMainBackgroundColor: (state, action) => {
      state.mainBackgroundColor = action.payload;
    },
    updateMainSelectedBackgroundColor: (state, action) => {
      state.mainSelectedBackgroundColor = action.payload;
    },
    updateMainBorderColor: (state, action) => {
      state.mainBorderColor = action.payload;
    },
    updateMainSelectedBorderColor: (state, action) => {
      state.mainSelectedBorderColor = action.payload;
    },
    updateExtraCoinsAmountBackgroundColor: (state, action) => {
      state.extraCoinsAmountBackgroundColor = action.payload;
    },
    updateExtraCoinsAmountTextColor: (state, action) => {
      state.extraCoinsAmountTextColor = action.payload;
    },
    updateDiscountPercentageBackgroundColor: (state, action) => {
      state.discountPercentageBackgroundColor = action.payload;
    },
    updateDiscountPercentageTextColor: (state, action) => {
      state.discountPercentageTextColor = action.payload;
    },
    updateCoinsAmountTextColor: (state, action) => {
      state.coinsAmountTextColor = action.payload;
    },
    updatePayAmountTextColor: (state, action) => {
      state.payAmountTextColor = action.payload;
    },
    updateDiscountPayAmountTextColor: (state, action) => {
      state.discountPayAmountTextColor = action.payload;
    },
    updateBottomFloatingTextBackgroundColor: (state, action) => {
      state.bottomFloatingTextBackgroundColor = action.payload;
    },
    updateBottomFloatingTextTextColor: (state, action) => {
      state.bottomFloatingTextTextColor = action.payload;
    },

    // Utility Functions
    resetCoinsPackageDesignerStore: () => {
      return { ...initialState };
    },
  },
});

export const {
  updateTopLeftSticker,
  updateCoinsAmount,
  updateExtraCoinsAmount,
  updateDiscountPercentage,
  updatePayAmount,
  updateDiscountPayAmount,
  updateBottomFloatingText,

  // Styling Functions
  updateMainBackgroundColor,
  updateMainSelectedBackgroundColor,
  updateMainBorderColor,
  updateMainSelectedBorderColor,
  updateExtraCoinsAmountBackgroundColor,
  updateExtraCoinsAmountTextColor,
  updateDiscountPercentageBackgroundColor,
  updateDiscountPercentageTextColor,
  updateCoinsAmountTextColor,
  updatePayAmountTextColor,
  updateDiscountPayAmountTextColor,
  updateBottomFloatingTextBackgroundColor,
  updateBottomFloatingTextTextColor,

  // Utility Functions
  resetCoinsPackageDesignerStore,
} = coinsPackageDesignerSlice.actions;

export default coinsPackageDesignerSlice.reducer;
