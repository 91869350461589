const routeConst = {
  // General
  landing: { name: "Landing", path: "/" },
  aboutUs: { name: "About Us", path: "/about-us" },
  // Login
  login: { name: "Login", path: "/login" },
  // Dashboard
  dashboard: { name: "Dashboard", path: "/dashboard" },
  // User
  user: {
    name: "User",
    path: "/user",
    profilePhotoBorder: {
      name: "Profile Photo Border",
      path: "/user/profile-border",
    },
    userCard: {
      name: "User Card Border",
      path: "/user/user-card",
    },
    userCardV2: {
      name: "User Card Border V2",
      path: "/user/user-card-v2",
    },
    coAnchorCard: {
      name: "Co Anchor Card",
      path: "/user/co-anchor-card",
    },
  },
  // Livestream
  livestream: {
    name: "Livestream",
    path: "/livestream",
    simulateLivestream: {
      name: "Simulate Livestream",
      path: "/livestream/simulate-livestream",
    },
    kingOfTheMonth: {
      name: "King of the Month",
      path: "/livestream/king-of-the-month",
    },
    giftAnimation: {
      name: "Gift Animation",
      path: "/livestream/gift-animation",
    },
  },
  // Banners
  banner: {
    name: "Banner",
    path: "/banner",
    bannerTester: {
      name: "Banner Tester",
      path: "/banner/banner-tester",
    },
  },
  // Coins
  coins: {
    name: "Coins",
    path: "/coins",
    coinPackageDesigner: {
      name: "Coins Package Designer",
      path: "/coins/coins-package-designer",
    },
  },
};

export default routeConst;
