// General
import "./custom-avatar.scss";
// Static Data
import userConst from "../../../../const/userConst";
// Redux
import { useSelector } from "react-redux";
// Material UI
import { Avatar, Badge } from "@mui/material";
import styled from "@emotion/styled";
// Custom Hooks
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

const CustomAvatar = (props) => {
  const {
    className,
    src,
    role,
    isOnline = false,
    isBlur = false,
    isLock = false,
    profileBorder = null,
    scale = false,
    onClick,
  } = props;

  // Redux variables
  const profileBorderEnabled = useSelector(
    (state) => state.profileBorder.profileBorderEnabled
  );

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Event Handlers | Button
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  // Utility Functions
  const getFallbackImage = () => {
    switch (role?.toLowerCase()) {
      case userConst.userRole.sugardaddy.toLowerCase():
        return getIcon("avatarFallbackMan", "fallback-image");
      case userConst.userRole.maleSugarbaby.toLowerCase():
        return getIcon("avatarFallbackMan", "fallback-image");
      case userConst.userRole.sugarmommy.toLowerCase():
        return getIcon("avatarFallbackWoman", "fallback-image");
      case userConst.userRole.sugarbaby.toLowerCase():
        return getIcon("avatarFallbackWoman", "fallback-image");
      default:
        return getIcon("avatarFallbackNeutral", "fallback-image");
    }
  };

  // Utility Style
  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        // top: 0,
        // left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));

  if (isOnline) {
    return (
      <StyledBadge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        variant="dot"
      >
        <div
          id="custom-avatar-subcomponent"
          className={`custom-avatar ${className}`}
        >
          <Avatar
            // id="custom-avatar-subcomponent"
            className={`custom-avatar ${className} ${
              isBlur ? "blur-avatar" : ""
            } ${
              profileBorderEnabled && profileBorder
                ? "reduce-profile-photo"
                : ""
            } ${scale ? "scale-avatar" : ""}`}
            src={src}
            alt="avatar"
            onClick={handleClick}
          >
            {getFallbackImage()}
          </Avatar>

          {profileBorderEnabled && profileBorder && (
            <div className="profile-border-container">
              <img
                className="profile-border"
                src={profileBorder}
                alt="profile-border"
              />
            </div>
          )}

          {isLock && (
            <div className="lock-icon-container">
              {getIcon("lockWhiteIcon", "lock-icon")}
            </div>
          )}
        </div>
      </StyledBadge>
    );
  } else {
    return (
      <div
        id="custom-avatar-subcomponent"
        className={`custom-avatar ${className}`}
      >
        <Avatar
          // id="custom-avatar-subcomponent"
          className={`custom-avatar ${className} ${
            isBlur ? "blur-avatar" : ""
          } ${
            profileBorderEnabled && profileBorder ? "reduce-profile-photo" : ""
          } ${scale ? "scale-avatar" : ""}`}
          src={src}
          alt="avatar"
          onClick={handleClick}
        >
          {getFallbackImage()}
        </Avatar>

        {profileBorderEnabled && profileBorder && (
          <div className="profile-border-container">
            <img
              className="profile-border"
              src={profileBorder}
              alt="profile-border"
            />
          </div>
        )}

        {isLock && (
          <div className="lock-icon-container">
            {getIcon("lockWhiteIcon", "lock-icon")}
          </div>
        )}
      </div>
    );
  }
};

export default CustomAvatar;
